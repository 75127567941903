import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getAreaWeightages = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/areaweightage' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getAreaWeightage = async (areaweightageId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/areaweightage/' + areaweightageId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

// const getProfile = async () => {
//     try {

//         let headers = getRequestHeaders();

//         let response;
//         try {
//             response = await axios.get( config.SERVER_BASE_URL + '/license/profile', { headers: headers });
//         } catch (error) {
//             return error.response.data;
//         }
//         return response.data;
//     } catch (error) {
//         return { error: error.message };
//     }
// }

const addAreaWeightage = async (payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/areaweightage', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editAreaWeightage = async (areaweightageId, payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.patch( config.SERVER_BASE_URL + '/areaweightage/' + areaweightageId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}


export {
    getAreaWeightages,
    getAreaWeightage,
    addAreaWeightage,
    editAreaWeightage,
}