/**Read this
 * Backend connection successful
 * Bug the company name and status id not being displayed
 */

import { Autocomplete, Button, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getLicense as getLicenseService, addLicense as addLicenseService, editLicense as editLicenseService } from "src/services/license.service";
import { getCompanies as getCompaniesService } from "src/services/company.service";
import Swal from "sweetalert2";
import { getModulesForSelection } from "src/services/common.service";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

class AddEditLicense extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.license_id ? 'edit' : 'add',
            license_id: props.params?.license_id,
            license_status: 'Active',
            modules: [],
            module: null,
            license_start_date: null,
            license_end_date: null,
            number_of_users: '',
            companies: [],
            edit_message: '',
            error: {},
            loading: false,
        }
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {

        await this.setState({
            modules: getModulesForSelection()
        })

        if (this.state.mode === 'edit') {
            await this.getLicense();
        }

        if (this.state.mode === 'add') {
            await this.setCompanies();
        }
    }

    /**Fetches the companie from the bakck end
     * @returns List of Companies
     */
    async setCompanies() {
        let response = await getCompaniesService({
            minimal: true
        });

        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (response && response.companies) {
            let companies = response.companies;
            companies = companies.map(company => {
                return {
                    id: company.id,
                    label: company.name,
                    companyStatus: company.status
                }
            })
            await this.setState({
                companies: companies,
            })
        }
    }

    async getLicense() {
        let response = await getLicenseService(this.state.license_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.license) {
            let license = response.license;

            await this.setState({
                license_status: license.license_status || 'Active',
                module: license?.module ? { id: license.module, label: license.module } : null,
                license_start_date: license?.license_start_date ? moment(license.license_start_date) : null,
                license_end_date: license?.license_end_date ? moment(license.license_end_date) : null,
                number_of_users: license?.number_of_users ? license.number_of_users : '',
                edit_message: license?.edit_message ? license.edit_message : ''
            })
        }
    }

    /**Function to save license
     * @returns Saves the license to the backend
     */
    async saveLicense() {

        this.setState({
            loading: true
        });

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            });
            return;
        }

        let payload = {
            license_status: this.state.license_status,
            module: this.state.module?.id ? this.state.module.id : null,
            license_start_date: this.state.license_start_date,
            license_end_date: this.state.license_end_date,
            number_of_users: this.state.number_of_users,
            edit_message: this.state.edit_message
        };

        if (this.state.mode === 'add') {
            let response = await addLicenseService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.license) {
                this.setState({
                    loading: false
                })
                this.props.navigate('/' + localStorage.getItem('role') + '/licenses');
            }
        } else {
            let response = await editLicenseService(this.state.license_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.license) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('license_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getLicense();
            }
        }

        // console.log(this.state.company);
        // console.log(this.state.module);
        // console.log(this.state.license_start_date);
        // console.log(this.state.license_end_date);

    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] !== '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field = null) {

        let error = this.state.error;

        // company validation
        if (field === null || field === 'company' && this.state.mode === "add") {
            if (!this.state.company || this.state.company === '') {
                error.company = i18n.t('this_field_is_required');
            } else {
                error.company = '';
            }
        }

        // license_status validation
        if (field === null || field === 'license_status') {
            if (!this.state.license_status || this.state.license_status === '') {
                error.license_status = i18n.t('this_field_is_required');
            } else {
                error.license_status = '';
            }
        }

        // module validatio===
        if (field === null || field === 'module') {
            if (!this.state.module || this.state.module === '') {
                error.module = i18n.t('this_field_is_required');
            } else {
                error.module = '';
            }
        }

        // license start date validation
        if (field === null || field === 'license_start_date') {
            if (!this.state.license_start_date || this.state.license_start_date === '') {
                error.license_start_date = i18n.t('this_field_is_required');
            } else {
                error.license_start_date = '';
            }
        }

        // license start date validation
        if (field === null || field === 'license_end_date') {
            if (!this.state.license_end_date || this.state.license_end_date === '') {
                error.license_end_date = i18n.t('this_field_is_required');
            } else {
                error.license_end_date = '';
            }
        }

        // number_of_users date validation
        if (field === null || field === 'number_of_users') {
            if (!this.state.number_of_users || this.state.number_of_users === '') {
                error.number_of_users = i18n.t('this_field_is_required');
            } else {
                error.number_of_users = '';
            }
        }

        // edit_message date validation
        if (this.state.mode === "edit" && field === null || field === 'edit_message') {
            if (!this.state.edit_message || this.state.edit_message === '') {
                error.edit_message = i18n.t('this_field_is_required');
            } else {
                error.edit_message = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode === 'add' ? i18n.t('add_license') : i18n.t('edit_license')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode === 'add' ? i18n.t('add_license') : i18n.t('edit_license')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.company ? this.state.company : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            company: newValue,
                                        })
                                        this.validateField('company');
                                    }}
                                    options={this.state.companies}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('company')} error={!!this.state?.error?.company} />}
                                    getOptionLabel={(option) => {
                                        if (option?.label)
                                            return option.label;
                                        if (this.state.mode === "edit")
                                            return option;
                                    }}
                                    disabled={(this.state.mode === 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.company}>{this.state?.error?.company}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.license_status ? this.state.license_status : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            license_status: newValue
                                        })
                                        this.validateField('license_status');
                                    }}
                                    options={["Active", "Inactive"]}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('license_status')} error={!!this.state?.error?.license_status} />}
                                // disabled={(this.state.mode == 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.license_status}>{this.state?.error?.license_status}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.module ? this.state.module : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            module: newValue
                                        })
                                        this.validateField('module');
                                    }}
                                    options={this.state.modules}
                                    renderInput={(params) => <TextField {...params} label={i18n.t("module")} error={!!this.state?.error?.module} />}
                                    getOptionLabel={option => {
                                        if (option?.label)
                                            return option.label
                                        else
                                            return '';
                                    }}
                                    disabled={(this.state.mode === 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.module}>{this.state?.error?.module}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('license_start_date')}
                                    value={this.state.license_start_date ? this.state.license_start_date : null}
                                    fullWidth
                                    onChange={async (newValue) => {
                                        await this.setState({ license_start_date: newValue })
                                        this.validateField('license_start_date');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.license_start_date,
                                            error: !!this.state?.error?.license_start_date,
                                            fullWidth: true
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('license_end_date')}
                                    value={this.state.license_end_date ? this.state.license_end_date : null}
                                    fullWidth
                                    onChange={async (newValue) => {
                                        await this.setState({ license_end_date: newValue })
                                        this.validateField('license_end_date');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.license_end_date,
                                            error: !!this.state?.error?.license_end_date,
                                            fullWidth: true
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                fullWidth
                                type="number"
                                name="number_of_users"
                                label={i18n.t('number_of_users')}
                                value={this.state.number_of_users}
                                onChange={async (e) => {
                                    await this.setState({
                                        number_of_users: e.target.value
                                    })
                                    this.validateField('number_of_users');
                                }}
                                error={!!this.state?.error?.number_of_users}
                                helperText={this.state?.error?.number_of_users}>
                            </TextField>
                        </Grid>
                    </Grid>

                    {/* Edit message will Render only in the edit page */}
                    {
                        this.state.mode === "edit" &&
                        <Grid container spacing={1} sx={{ mt: 3 }}>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    fullWidth
                                    name="edit_message"
                                    label={i18n.t('edit_message')}
                                    value={this.state.edit_message}
                                    onChange={async (e) => {
                                        await this.setState({
                                            edit_message: e.target.value
                                        })
                                        this.validateField('edit_message');
                                    }}
                                    error={!!this.state?.error?.edit_message}
                                    helperText={this.state?.error?.edit_message}>
                                </TextField>
                            </Grid>
                        </Grid>
                    }

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveLicense();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <AddEditLicense {...props} navigate={navigate} params={params} />;
}
