import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Chip, Container, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getProposals as getProposalsService } from "src/services/proposal.service";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getWorkFinancialYears, getWorkIndentNos } from "src/services/work.service";
import { getEventsByTypeOfWork } from "src/services/typeOfWork.service";
import { upsertWorkSchedule, getWorkSchedule } from "src/services/workSchedule.service";


class WorkSchedule extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            financial_years: [],
            indent_nos: [],
            events: [],
            financial_year: '',
            indent_no: '',
            proposal: null,
            event_data: [],
            error: {},
            loading: false
        }
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        this.setFinancialYears();
    }

    async setFinancialYears() {
        let financial_years = await getWorkFinancialYears();
        if (financial_years && financial_years.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: financial_years.error_code ? convertMessageCodeToMessage(financial_years.error_code) : financial_years.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (financial_years && financial_years.financial_year) {
            await this.setState({
                financial_years: financial_years.financial_year
            })
        }
    }

    async setIndentNos() {
        let indent_nos = await getWorkIndentNos();
        if (indent_nos && indent_nos.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: indent_nos.error_code ? convertMessageCodeToMessage(indent_nos.error_code) : indent_nos.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (indent_nos && indent_nos.indent_no) {
            await this.setState({
                indent_nos: indent_nos.indent_no
            })
        }
    }

    async setProposal() {

        if (!this.state.financial_year || !this.state.indent_no) {
            this.promisedSetState({
                proposal: null
            })
            return;
        }

        let proposal = await getProposalsService({financial_year: this.state.financial_year, indent_no: this.state.indent_no});

        if (proposal && proposal.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: proposal.error_code ? convertMessageCodeToMessage(proposal.error_code) : proposal.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (proposal && proposal.proposals && proposal.proposals.length > 0) {
            await this.promisedSetState({
                proposal: proposal.proposals[0]
            })
        } else {
            await this.promisedSetState({
                proposal: null
            })
        }

    }

    async setEvents() {

        if (!this.state?.proposal?.type_of_work) {
            this.setState({
                events: []
            })
            return;
        }

        let type_of_work = this.state?.proposal?.type_of_work;

        let events = await getEventsByTypeOfWork(type_of_work);

        if (events && events.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: events.error_code ? convertMessageCodeToMessage(events.error_code) : events.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (events && events.events) {
            await this.promisedSetState({
                events: events.events
            })
        }

    }

    async setWorkSchedule() {
        if (!this.state.financial_year || !this.state.indent_no) {
            this.promisedSetState({
                event_data: []
            })
            return;
        }

        let work_schedule = await getWorkSchedule({financial_year: this.state.financial_year, indent_no: this.state.indent_no});
        if (work_schedule && work_schedule.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: work_schedule.error_code ? convertMessageCodeToMessage(work_schedule.error_code) : work_schedule.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        let event_data = [];
        if (work_schedule && work_schedule.work_schedule) {
            let event_dat = work_schedule.work_schedule?.event_data ? work_schedule.work_schedule?.event_data : [];
            for(let i = 0; i < event_dat.length; i++) {
                event_data.push({
                    event: event_dat[i]?.event,
                    start_date: event_dat[i]?.start_date ? moment(event_dat[i].start_date) : null,
                    end_date: event_dat[i]?.end_date ? moment(event_dat[i].end_date) : null
                })
            }
        }

        await this.promisedSetState({
            event_data: event_data
        })
    }




    async saveWorkSchedule() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }


        let payload = {};


        payload.financial_year = this.state.financial_year;
        payload.indent_no = this.state.indent_no;

        payload.event_data = this.state.event_data;

        let response = await upsertWorkSchedule(payload);

        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            this.setState({
                loading: false
            })
            return;
        }

        if (response && response.work_schedule) {
            Swal.fire({
                icon: 'success',
                title: i18n.t('success'),
                text: i18n.t('work_schedule') + ' ' + i18n.t('saved_successfully'),
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            this.setState({
                loading: false
            })
        }
    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'financial_year') {
            if (!this.state.financial_year || this.state.financial_year == '') {
                error.financial_year = i18n.t('this_field_is_required');
            } else {
                error.financial_year = '';
            }
        }

        if (field == null || field == 'indent_no') {
            if (!this.state.indent_no || this.state.indent_no == '') {
                error.indent_no = i18n.t('this_field_is_required');
            } else {
                error.indent_no = '';
            }
        }

        if (field == null || field == 'event_data') {
            if (!this.state.event_data || this.state.event_data.length == 0) {
                error.event_data = '';
            } else {
                let event_data = this.state.event_data;
                let is_valid = true;
                for (let i = 0; i < event_data.length; i++) {
                    if (!event_data[i].event || event_data[i].event == '') {
                        is_valid = false;
                        break;
                    }
                    if (!event_data[i].start_date || event_data[i].start_date == '') {
                        is_valid = false;
                        break;
                    }
                    if (!event_data[i].end_date || event_data[i].end_date == '') {
                        is_valid = false;
                        break;
                    }
                }
                if (!is_valid) {
                    error.event_data = i18n.t('form_is_not_valid');
                } else {
                    error.event_data = '';
                }
            }
        }
        
        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {i18n.t('work_schedule')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {i18n.t('work_schedule')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.financial_year ? this.state.financial_year : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            financial_year: newValue,
                                            indent_no: ''
                                        })
                                        this.validateField('financial_year');
                                        await this.setIndentNos();
                                        await this.setProposal();
                                        await this.setEvents();
                                    }}
                                    options={this.state.financial_years}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('financial_year')} error={!!this.state?.error?.financial_year} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.financial_year}>{this.state?.error?.financial_year}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.indent_no ? this.state.indent_no : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            indent_no: newValue
                                        })
                                        this.validateField('indent_no');
                                        await this.setProposal();
                                        await this.setEvents();
                                        await this.setWorkSchedule();
                                    }}
                                    options={this.state.indent_nos}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('indent_no')} error={!!this.state?.error?.indent_no} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={((this.state.financial_year && this.state.financial_year != '') ? false : true)}
                                />
                                <FormHelperText error={!!this.state?.error?.indent_no}>{this.state?.error?.indent_no}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {(this.state.proposal) ? (
                            <Grid item xs={12} md={12} lg={12} sx={{mt: 2, mb: 2}}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h5">{this.state.proposal?.name_of_work}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('proposal_id')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.proposal_id}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('name_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.name_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('type_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.type_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('state')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.state}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('zone')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.zone}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('circle')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.circle}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('division')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.division}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('district')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.district}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('taluk')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.taluk}</TableCell>
                                                    </TableRow>
                                                    {(this.state.proposal?.type_of_work == 'Road Construction' || this.state.proposal?.type_of_work == 'Road Maintenance') ? (
                                                        <>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_type')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_type}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_number')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_number}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_name')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_length')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_length}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('chainage')}</TableCell>
                                                                <TableCell align="right">
                                                                    {(this.state.proposal?.chainage?.length) ? (
                                                                        this.state.proposal?.chainage.map((chainage, index) => {
                                                                            return (
                                                                                <Chip key={index} label={chainage?.chainage_from + ' - ' + chainage?.chainage_to} />
                                                                            )
                                                                        })
                                                                    ) : null}
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    ) : null}
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('head_of_account')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.head_of_account}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('admin_approval')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.admin_approval}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('technical_sanction')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.technical_sanction}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('estimated_cost')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.estimated_cost}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ) : null}

                        
                        {(this.state.financial_year && this.state.indent_no) ? (

                            <>

                                <Grid item xs={12} md={12} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{i18n.t('event')}</TableCell>
                                                    <TableCell align="right">{i18n.t('start_date')}</TableCell>
                                                    <TableCell align="right">{i18n.t('end_date')}</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.event_data.map((event_dat, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>
                                                            <Select
                                                                fullWidth
                                                                value={event_dat.event}
                                                                onChange={async (e) => {
                                                                    let event_data = this.state.event_data;
                                                                    event_data[index].event = e.target.value;
                                                                    await this.promisedSetState({
                                                                        event_data: event_data
                                                                    })
                                                                }}
                                                                style={{width: '100%'}}
                                                            >
                                                                {(this.state?.events?.map((eve, index) => {
                                                                    return (
                                                                        <MenuItem key={index} value={eve}>{eve}</MenuItem>
                                                                    )
                                                                }))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    value={event_dat.start_date}
                                                                    onChange={async (newValue) => {
                                                                        let event_data = this.state.event_data;
                                                                        event_data[index].start_date = newValue;
                                                                        if (event_dat.end_date && event_dat.end_date < newValue) {
                                                                            event_data[index].end_date = null;
                                                                        }
                                                                        await this.promisedSetState({
                                                                            event_data: event_data
                                                                        })
                                                                    }}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    value={event_dat.end_date}
                                                                    onChange={async (newValue) => {
                                                                        let event_data = this.state.event_data;
                                                                        event_data[index].end_date = newValue;
                                                                        await this.promisedSetState({
                                                                            event_data: event_data
                                                                        })
                                                                    }}
                                                                    minDate={event_dat?.start_date ? event_dat?.start_date : null}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    disabled={event_dat?.start_date ? false : true}
                                                                />
                                                            </LocalizationProvider>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Button
                                                                variant="contained"
                                                                onClick={async () => {
                                                                    let event_data = this.state.event_data;
                                                                    event_data.splice(index, 1);
                                                                    await this.promisedSetState({
                                                                        event_data: event_data
                                                                    })
                                                                }}
                                                            >
                                                                <DeleteForeverIcon />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                {this.state.error.event_data ? (
                                    <Grid container spacing={1} sx={{mt: 3}}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="h6" color="error">{this.state.error.event_data}</Typography>
                                        </Grid>
                                    </Grid>
                                ) : null}

                                <Grid item xs={12} md={12} lg={12} sx={{mt: 2}}>
                                    <Button
                                        variant="contained"
                                        onClick={async () => {
                                            let event_data = this.state.event_data;
                                            event_data.push({
                                                event: '',
                                                start_date: null,
                                                end_date: null
                                            })
                                            await this.promisedSetState({
                                                event_data: event_data
                                            })
                                        }}
                                    >
                                        {i18n.t('add_event')}
                                    </Button>
                                </Grid>

                                <Grid container spacing={1} sx={{mt: 3}}>
                                    <Grid item xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                                        <Button
                                            variant="contained"
                                            onClick={async () => {
                                                await this.saveWorkSchedule();
                                            }}
                                            disabled={this.state.loading}
                                        >
                                            {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                                        </Button>
                                    </Grid>
                                </Grid>

                            </>

                        ) : null}
                        
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
    const params = useParams();
  
    return <WorkSchedule {...props} navigate={navigate} params={params} />;
}
