/**Read this
 * here sr_no must be auto genrated
 * Backend connection needed for saving the data
 */

import { Autocomplete, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getRoyalty as getRoyaltyService, addRoyalty as addRoyaltyService, editRoyalty as editRoyaltyServies } from "src/services/royalty.service";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import moment from "moment";
// mui
import { teal } from '@mui/material/colors';
import { Label } from "@mui/icons-material";

class AddEditRoyalty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.params?.royalty_id ? 'edit' : 'add',
      royalty_id: props.params?.royalty_id,
      name: '',
      description: '',
      units: '',
      royalty_rate: '',
      error: {},
      loading: false,
    }
  }

  async componentDidMount() {

    if (this.state.mode == 'edit') {
      await this.getRoyalty();
    }
  }

  async getRoyalty() {
    let response = await getRoyaltyService(this.state.royalty_id);
    if (response && response.error) {
      Swal.fire({
        icon: 'error',
        title: i18n.t('error'),
        text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
        confirmButtonText: i18n.t('ok'),
        confirmButtonColor: config.primaryColor
      })
      return;
    }
    if (response && response.royalty) {
      let royalty = response.royalty;

      await this.setState({
        name: royalty?.name ? royalty.name : '',
        description: royalty?.description ? royalty.description : '',
        units: royalty?.units ? royalty.units : '',
        royalty_rate: royalty?.royalty_rate ? royalty.royalty_rate : '',
      })
    }
  }

  /**Function to save royalties
   * @returns Saves the royalty to the backend
   */
  async saveRoyalties() {

    this.setState({
      loading: true
    })

    let valid = await this.isFormValid();
    if (!valid) {
      this.setState({
        loading: false
      })
      return;
    }

    let payload = {
      name: this.state.name,
      description: this.state.description,
      units: this.state.units,
      royalty_rate: this.state.royalty_rate,
    }

    if (this.state.mode == 'add') {
      let response = await addRoyaltyService(payload);
      if (response && response.error) {
        Swal.fire({
          icon: 'error',
          title: i18n.t('error'),
          text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
          confirmButtonText: i18n.t('ok'),
          confirmButtonColor: config.primaryColor
        })
        this.setState({
          loading: false
        })
        return;
      }
      if (response && response.royalty) {
        this.setState({
          loading: false
        })
        this.props.navigate('/' + localStorage.getItem('role') + '/mastertables/royalty');
      }
    } else {
      let response = await editRoyaltyServies(this.state.royalty_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: 'error',
          title: i18n.t('error'),
          text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
          confirmButtonText: i18n.t('ok'),
          confirmButtonColor: config.primaryColor
        })
        this.setState({
          loading: false
        })
        return;
      }

      if (response && response.units) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: 'bottom-end',
          icon: 'success',
          title: i18n.t('success'),
          text: i18n.t('royalty_updated_successfully'),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true
        })
        this.setState({
          loading: false
        })
        this.getRoyalty();
      }
    }
  }

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != '') {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }

  async validateField(field = null) {

    let error = this.state.error;

    // name validation
    if (field == null || field == 'name') {
      if (!this.state.name || this.state.name == '') {
        error.name = i18n.t('this_field_is_required');
      } else {
        error.name = '';
      }
    }

    // description validation
    if (field == null || field == 'description') {
      if (!this.state.description || this.state.description == '') {
        error.description = i18n.t('this_field_is_required');
      } else {
        error.description = '';
      }
    }

    // units validation
    if (field == null || field == 'units') {
      if (!this.state.units || this.state.units == '') {
        error.units = i18n.t('this_field_is_required');
      } else {
        error.units = '';
      }
    }

    // royalty_rate validation
    if (field == null || field == 'royalty_rate') {
      if (!this.state.royalty_rate || this.state.royalty_rate == '') {
        error.royalty_rate = i18n.t('this_field_is_required');
      } else {
        error.royalty_rate = '';
      }
    }

    this.setState({
      error: error
    })

    return error;
  }

  render() {
    return (
      <>

        <Helmet>
          <title> {this.state.mode == 'add' ? i18n.t('add_royalty') : i18n.t('edit_royalty')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <Container maxWidth="100%">

          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {this.state.mode == 'add' ? i18n.t('add_royalty') : i18n.t('edit_royalty')}
            </Typography>
          </Stack>

          {/* <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="sr_no"
                                label={i18n.t('sr_no')}
                                fullWidth
                                value={this.state.sr_no}
                                onChange={async (e) => {
                                    await this.setState({
                                        sr_no: e.target.value
                                    })
                                    this.validateField('sr_no');
                                }}
                                error={!!this.state?.error?.sr_no}
                                helperText={this.state?.error?.sr_no}
                                disabled={(this.state.mode == 'edit') ? true : false}
                            />
                        </Grid>
                    </Grid> */}

          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                name="name"
                label={i18n.t('name')}
                fullWidth
                value={this.state.name}
                onChange={async (e) => {
                  await this.setState({
                    name: e.target.value
                  })
                  this.validateField('name');
                }}
                error={!!this.state?.error?.name}
                helperText={this.state?.error?.name}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                name="description"
                label={i18n.t('description')}
                fullWidth
                value={this.state.description}
                onChange={async (e) => {
                  await this.setState({
                    description: e.target.value
                  })
                  this.validateField('description');
                }}
                error={!!this.state?.error?.description}
                helperText={this.state?.error?.description}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth>
                <Autocomplete
                  value={this.state.units ? this.state.units : null}
                  onChange={async (e, newValue) => {
                    await this.setState({
                      units: newValue
                    })
                    this.validateField('units');
                  }}
                  options={["units 1", "units 2", "units 3"]}
                  renderInput={(params) => <TextField {...params} label={i18n.t("units")} error={!!this.state?.error?.units} />}
                // disabled={(this.state.mode == 'edit') ? true : false}
                />
                <FormHelperText error={!!this.state?.error?.units}>{this.state?.error?.units}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                name="royalty_rate"
                label={i18n.t('royalty_rate')}
                fullWidth
                value={this.state.royalty_rate}
                onChange={async (e) => {
                  await this.setState({
                    royalty_rate: e.target.value
                  })
                  this.validateField('royalty_rate');
                }}
                error={!!this.state?.error?.royalty_rate}
                helperText={this.state?.error?.royalty_rate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 3 }}>
            <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={async () => {
                  await this.saveRoyalties();
                }}
                disabled={this.state.loading}
              >
                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
              </Button>
            </Grid>
          </Grid>

        </Container>
      </>
    );
  }

}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AddEditRoyalty {...props} navigate={navigate} params={params} />;
}
