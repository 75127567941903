const isIfscValidByRegex = (ifsc) => {
    try {
        let regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        return regex.test(ifsc);
    } catch (error) {
        return false;
    }
}

const isPanValidByRegex = (pan) => {
    try {
        let regex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
        return regex.test(pan);
    } catch (error) {
        return false;
    }
}

const isGstValidByRegex = (gst) => {
    try {
        let regex = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}/;
        return regex.test(gst);
    } catch (error) {
        return false;
    }
}

export {
    isIfscValidByRegex,
    isPanValidByRegex,
    isGstValidByRegex
}
