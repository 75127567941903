import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

// const role = localStorage.getItem("role");

const getHeadOfAccounts = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            // response = await axios.get( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/head_of_accounts" : '/head_of_accounts'}` + queryString, { headers: headers });
            response = await axios.get( config.SERVER_BASE_URL + '/head_of_accounts' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getHeadOfAccount = async (headofaccountId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.get( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/head_of_accounts" : '/head_of_accounts/'}` + headofaccountId, { headers: headers });
            response = await axios.get( config.SERVER_BASE_URL + '/head_of_accounts/' + headofaccountId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addHeadOfAccount = async (payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.post( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/head_of_accounts" : '/head_of_accounts'}`, payload, { headers: headers });
            response = await axios.post( config.SERVER_BASE_URL + '/head_of_accounts', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editHeadOfAccount = async (headofaccountId, payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.patch( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/head_of_accounts/" : '/head_of_accounts/' }` + headofaccountId, payload, { headers: headers });
            response = await axios.patch( config.SERVER_BASE_URL + '/head_of_accounts/' + headofaccountId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}


export {
    getHeadOfAccounts,
    getHeadOfAccount,
    addHeadOfAccount,
    editHeadOfAccount,
}