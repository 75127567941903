/**Read this
 * Check the Forgot Password function
 */
import axios from 'axios'
import config from './../config';

const login = async (payload) => {
    try {
        const { username, password } = payload;
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/login', { username, password });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const forgot = async (payload) => {
    try {
        // const { username } = payload;
        let response;
        try {
            // response = await axios.post( config.SERVER_BASE_URL + '/login', { username });
            response = await axios.post( config.SERVER_BASE_URL + '/login');
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    login,
    forgot
}