// component
import i18n from 'src/i18n';

import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import BadgeIcon from '@mui/icons-material/Badge';
import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';
import RateReviewSharpIcon from '@mui/icons-material/RateReviewSharp';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: i18n.t('dashboard'),
    path: '/superadmin/dashboard',
    icon: <BarChartIcon />,
  },
  {
    title: i18n.t('companies'),
    path: '/superadmin/companies',
    icon: <BusinessIcon />,
  },
  {
    title: i18n.t('company_admins'),
    path: '/superadmin/users',
    icon: <PeopleIcon />,
  },
  {
    title: i18n.t('license'),
    path: '/superadmin/licenses',
    icon: <BadgeIcon />,
  },
  {
    title: i18n.t('master_tables'),
    icon: <TableChartSharpIcon />,
    children: [
      {
        title: i18n.t('schedule_of_rates'),
        path: '/superadmin/mastertables/scheduleofrates',
        icon: <TrendingUpIcon />
      },
      {
        title: i18n.t('schedule_year'),
        path: '/superadmin/mastertables/scheduleyear',
        icon: <DateRangeIcon />
      },
      {
        title: i18n.t('locations'),
        path: '/superadmin/mastertables/locations',
        icon: <LocationOnOutlinedIcon />
      },
      {
        title: i18n.t('units'),
        path: '/superadmin/mastertables/units',
        icon: <SquareFootIcon />
      },
      {
        title: i18n.t('area_weightage'),
        path: '/superadmin/mastertables/areaweightage',
        icon: <DonutSmallIcon />
      }
    ]
  },
  {
    title: i18n.t('template_estimates'),
    path: '/superadmin/templateestimates',
    icon: <RateReviewSharpIcon />,
  }
];

export default navConfig;
