import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getWorks = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/works' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getWork = async (workId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/works/' + workId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addWork = async (payload) => {
    try {
        let headers = getRequestHeaders();
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/works', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editWork = async (workId, payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.patch( config.SERVER_BASE_URL + '/works/' + workId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getWorkFinancialYears = async (query=null) => {
    try {
        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/works/dropdowns/financial_year' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getWorkIndentNos = async (query=null) => {
    try {
        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/works/dropdowns/indent_no' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getWorks,
    getWork,
    addWork,
    editWork,
    getWorkFinancialYears,
    getWorkIndentNos
}