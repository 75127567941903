import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Chip, Container, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getProposals as getProposalsService } from "src/services/proposal.service";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { upsertFinancialProgress, getFinancialProgress } from "src/services/financialProgress.service";
import { getEventsOfWorkSchedule, getWorkScheduleFinancialYears, getWorkScheduleIndentNos } from "src/services/workSchedule.service";


class FinancialProgress extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            financial_years: [],
            indent_nos: [],
            bill_types: config.BILL_TYPES,
            financial_year: '',
            indent_no: '',
            proposal: null,
            financial_progress_data: [],
            error: {},
            loading: false
        }
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        this.setFinancialYears();
    }

    async setFinancialYears() {
        let financial_years = await getWorkScheduleFinancialYears();
        if (financial_years && financial_years.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: financial_years.error_code ? convertMessageCodeToMessage(financial_years.error_code) : financial_years.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (financial_years && financial_years.financial_year) {
            await this.setState({
                financial_years: financial_years.financial_year
            })
        }
    }

    async setIndentNos() {
        let indent_nos = await getWorkScheduleIndentNos();
        if (indent_nos && indent_nos.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: indent_nos.error_code ? convertMessageCodeToMessage(indent_nos.error_code) : indent_nos.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (indent_nos && indent_nos.indent_no) {
            await this.setState({
                indent_nos: indent_nos.indent_no
            })
        }
    }

    async setProposal() {

        if (!this.state.financial_year || !this.state.indent_no) {
            this.promisedSetState({
                proposal: null
            })
            return;
        }

        let proposal = await getProposalsService({financial_year: this.state.financial_year, indent_no: this.state.indent_no});

        if (proposal && proposal.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: proposal.error_code ? convertMessageCodeToMessage(proposal.error_code) : proposal.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (proposal && proposal.proposals && proposal.proposals.length > 0) {
            await this.promisedSetState({
                proposal: proposal.proposals[0]
            })
        } else {
            await this.promisedSetState({
                proposal: null
            })
        }

    }

    async setFinancialProgress() {
        if (!this.state.financial_year || !this.state.indent_no) {
            this.promisedSetState({
                financial_progress_data: []
            })
            return;
        }

        let financial_progress = await getFinancialProgress({financial_year: this.state.financial_year, indent_no: this.state.indent_no});
        if (financial_progress && financial_progress.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: financial_progress.error_code ? convertMessageCodeToMessage(financial_progress.error_code) : financial_progress.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        let financial_progress_data = [];
        if (financial_progress && financial_progress.financial_progress) {
            financial_progress_data = financial_progress.financial_progress?.financial_progress_data ? financial_progress.financial_progress?.financial_progress_data : [];
        }

        await this.promisedSetState({
            financial_progress_data: financial_progress_data
        })
    }




    async saveFinancialProgress() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }


        let payload = {};


        payload.financial_year = this.state.financial_year;
        payload.indent_no = this.state.indent_no;

        payload.financial_progress_data = this.state.financial_progress_data;

        // Remove new key from financial_progress_data
        payload.financial_progress_data = payload.financial_progress_data.map((fin_progress) => {
            delete fin_progress.new;
            return fin_progress;
        })

        let response = await upsertFinancialProgress(payload);

        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            this.setState({
                loading: false
            })
            return;
        }

        if (response && response.financial_progress) {
            Swal.fire({
                icon: 'success',
                title: i18n.t('success'),
                text: i18n.t('financial_progress') + ' ' + i18n.t('saved_successfully'),
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            this.setState({
                loading: false
            })
        }
    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'financial_year') {
            if (!this.state.financial_year || this.state.financial_year == '') {
                error.financial_year = i18n.t('this_field_is_required');
            } else {
                error.financial_year = '';
            }
        }

        if (field == null || field == 'indent_no') {
            if (!this.state.indent_no || this.state.indent_no == '') {
                error.indent_no = i18n.t('this_field_is_required');
            } else {
                error.indent_no = '';
            }
        }
        
        this.setState({
            error: error
        })

        return error;
    }

    async computeRowFields(key, index) {
        let financial_progress_data = this.state.financial_progress_data;
        let fin_progress = financial_progress_data[index];

        // cumulative_bill_amount = cumulative_bill_amount ( upto last month ) + bill_amount_this_month
        fin_progress.cumulative_bill_amount = ((index == 0) ? 0 : (parseFloat(this.state.financial_progress_data[index - 1].cumulative_bill_amount))) + parseFloat(fin_progress.bill_amount_this_month);

        // total_paid_cumulative = total_paid_till_last_month + paid_during_this_month_by_cheque
        fin_progress.total_paid_cumulative = parseFloat(fin_progress.total_paid_till_last_month) + parseFloat(fin_progress.paid_during_this_month_by_cheque);

        // pending_payment = cumulative_bill_amount - total_paid_cumulative
        fin_progress.pending_payment = parseFloat(fin_progress.cumulative_bill_amount) - parseFloat(fin_progress.total_paid_cumulative);

        this.promisedSetState({
            financial_progress_data: financial_progress_data
        })

    }



    render() {
        return (
            <>

                <Helmet>
                    <title> {i18n.t('financial_progress')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {i18n.t('financial_progress')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.financial_year ? this.state.financial_year : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            financial_year: newValue,
                                            indent_no: ''
                                        })
                                        this.validateField('financial_year');
                                        await this.setIndentNos();
                                        await this.setProposal();
                                    }}
                                    options={this.state.financial_years}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('financial_year')} error={!!this.state?.error?.financial_year} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.financial_year}>{this.state?.error?.financial_year}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.indent_no ? this.state.indent_no : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            indent_no: newValue
                                        })
                                        this.validateField('indent_no');
                                        await this.setProposal();
                                        await this.setFinancialProgress();
                                    }}
                                    options={this.state.indent_nos}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('indent_no')} error={!!this.state?.error?.indent_no} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={((this.state.financial_year && this.state.financial_year != '') ? false : true)}
                                />
                                <FormHelperText error={!!this.state?.error?.indent_no}>{this.state?.error?.indent_no}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {(this.state.proposal) ? (
                            <Grid item xs={12} md={12} lg={12} sx={{mt: 2, mb: 2}}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h5">{this.state.proposal?.name_of_work}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('proposal_id')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.proposal_id}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('name_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.name_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('type_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.type_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('state')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.state}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('zone')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.zone}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('circle')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.circle}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('division')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.division}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('district')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.district}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('taluk')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.taluk}</TableCell>
                                                    </TableRow>
                                                    {(this.state.proposal?.type_of_work == 'Road Construction' || this.state.proposal?.type_of_work == 'Road Maintenance') ? (
                                                        <>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_type')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_type}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_number')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_number}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_name')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_length')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_length}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('chainage')}</TableCell>
                                                                <TableCell align="right">
                                                                    {(this.state.proposal?.chainage?.length) ? (
                                                                        this.state.proposal?.chainage.map((chainage, index) => {
                                                                            return (
                                                                                <Chip key={index} label={chainage?.chainage_from + ' - ' + chainage?.chainage_to} />
                                                                            )
                                                                        })
                                                                    ) : null}
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    ) : null}
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('head_of_account')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.head_of_account}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('admin_approval')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.admin_approval}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('technical_sanction')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.technical_sanction}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('estimated_cost')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.estimated_cost}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ) : null}

                        
                        {(this.state.financial_year && this.state.indent_no) ? (

                            <>

                                <Grid item xs={12} md={12} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650, width: 'maxContent', tableLayout: 'fixed', overflowX: 'scroll'}} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" style={{width: 150}}>{i18n.t('month')}</TableCell>
                                                    <TableCell align="center" style={{width: 150}}>{i18n.t('year')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('contract_amount')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('br_no')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('br_date')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('bill_type')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('mb_no')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('mb_page')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('total_paid_till_last_month')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('bill_amount') + ' ( ' + i18n.t('this_month') + ' )'}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('cumulative_bill_amount')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('paid_till_last_month')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('paid_during_this_month') + ' ( ' + i18n.t('by_cheque') + ' )'}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('cheque_no')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('bank_name')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('cheque_date')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('total_paid')}</TableCell>
                                                    <TableCell align="center" style={{width: 200}}>{i18n.t('pending_payment')}</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.financial_progress_data.map((fin_progress, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="right">
                                                            <Typography variant="body1">{fin_progress.month}</Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="body1">{fin_progress.year}</Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="body1">{fin_progress.contract_amount}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                label={i18n.t('br_no')}
                                                                value={fin_progress.br_no}
                                                                onChange={async (e) => {
                                                                    let financial_progress_data = this.state.financial_progress_data;
                                                                    financial_progress_data[index].br_no = e.target.value;
                                                                    await this.promisedSetState({
                                                                        financial_progress_data: financial_progress_data
                                                                    })
                                                                }}
                                                                disabled={!fin_progress.new}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    label={i18n.t('br_date')}
                                                                    value={fin_progress.br_date ? moment(fin_progress.br_date) : null}
                                                                    onChange={async (newValue) => {
                                                                        let financial_progress_data = this.state.financial_progress_data;
                                                                        financial_progress_data[index].br_date = newValue ? newValue : '';
                                                                        await this.promisedSetState({
                                                                            financial_progress_data: financial_progress_data
                                                                        })
                                                                    }}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    disabled={!fin_progress.new}
                                                                />
                                                            </LocalizationProvider>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    value={fin_progress.bill_type}
                                                                    onChange={async (e) => {
                                                                        let financial_progress_data = this.state.financial_progress_data;
                                                                        financial_progress_data[index].bill_type = e.target.value;
                                                                        await this.promisedSetState({
                                                                            financial_progress_data: financial_progress_data
                                                                        })
                                                                    }}
                                                                    error={!!this.state?.error?.bill_type}
                                                                    disabled={!fin_progress.new}
                                                                >
                                                                    {this.state.bill_types.map((bill_type, index) => (
                                                                        <MenuItem key={index} value={bill_type}>{bill_type}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                label={i18n.t('mb_no')}
                                                                value={fin_progress.mb_no}
                                                                onChange={async (e) => {
                                                                    let financial_progress_data = this.state.financial_progress_data;
                                                                    financial_progress_data[index].mb_no = e.target.value;
                                                                    await this.promisedSetState({
                                                                        financial_progress_data: financial_progress_data
                                                                    })
                                                                }}
                                                                disabled={!fin_progress.new}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                label={i18n.t('mb_page')}
                                                                value={fin_progress.mb_page}
                                                                onChange={async (e) => {
                                                                    let financial_progress_data = this.state.financial_progress_data;
                                                                    financial_progress_data[index].mb_page = e.target.value;
                                                                    await this.promisedSetState({
                                                                        financial_progress_data: financial_progress_data
                                                                    })
                                                                }}
                                                                disabled={!fin_progress.new}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">{fin_progress.total_paid_till_last_month}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                label={i18n.t('bill_amount')}
                                                                value={fin_progress.bill_amount_this_month}
                                                                onChange={async (e) => {
                                                                    let financial_progress_data = this.state.financial_progress_data;
                                                                    financial_progress_data[index].bill_amount_this_month = e.target.value;
                                                                    await this.promisedSetState({
                                                                        financial_progress_data: financial_progress_data
                                                                    })
                                                                    this.computeRowFields('bill_amount_this_month', index);
                                                                }}
                                                                disabled={!fin_progress.new}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">{fin_progress.cumulative_bill_amount}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">{fin_progress.paid_till_last_month}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                label={i18n.t('paid_during_this_month')}
                                                                value={fin_progress.paid_during_this_month_by_cheque}
                                                                onChange={async (e) => {
                                                                    let financial_progress_data = this.state.financial_progress_data;
                                                                    financial_progress_data[index].paid_during_this_month_by_cheque = e.target.value;
                                                                    await this.promisedSetState({
                                                                        financial_progress_data: financial_progress_data
                                                                    })
                                                                    this.computeRowFields('paid_during_this_month_by_cheque', index);
                                                                }}
                                                                disabled={!fin_progress.new}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                label={i18n.t('cheque_no')}
                                                                value={fin_progress.cheque_no}
                                                                onChange={async (e) => {
                                                                    let financial_progress_data = this.state.financial_progress_data;
                                                                    financial_progress_data[index].cheque_no = e.target.value;
                                                                    await this.promisedSetState({
                                                                        financial_progress_data: financial_progress_data
                                                                    })
                                                                }}
                                                                disabled={!fin_progress.new}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                label={i18n.t('bank_name')}
                                                                value={fin_progress.bank_name}
                                                                onChange={async (e) => {
                                                                    let financial_progress_data = this.state.financial_progress_data;
                                                                    financial_progress_data[index].bank_name = e.target.value;
                                                                    await this.promisedSetState({
                                                                        financial_progress_data: financial_progress_data
                                                                    })
                                                                }}
                                                                disabled={!fin_progress.new}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    label={i18n.t('cheque_date')}
                                                                    value={fin_progress.cheque_date ? moment(fin_progress.cheque_date) : null}
                                                                    onChange={async (newValue) => {
                                                                        let financial_progress_data = this.state.financial_progress_data;
                                                                        financial_progress_data[index].cheque_date = newValue ? newValue : '';
                                                                        await this.promisedSetState({
                                                                            financial_progress_data: financial_progress_data
                                                                        })
                                                                    }}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    disabled={!fin_progress.new}
                                                                />
                                                            </LocalizationProvider>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">{fin_progress.total_paid_cumulative}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">{fin_progress.pending_payment}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                {this.state.error.financial_progress_data ? (
                                    <Grid container spacing={1} sx={{mt: 3}}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="h6" color="error">{this.state.error.financial_progress_data}</Typography>
                                        </Grid>
                                    </Grid>
                                ) : null}

                                <Grid item xs={12} md={12} lg={12} sx={{mt: 2}}>
                                    <Button
                                        variant="contained"
                                        onClick={async () => {
                                            let financial_progress_data = this.state.financial_progress_data;

                                            // check if there is anything new, if yes, dont add new row
                                            for (let i = 0; i < financial_progress_data.length; i++) {
                                                if (financial_progress_data[i].new) {
                                                    Swal.fire({
                                                        icon: 'error',
                                                        title: i18n.t('error'),
                                                        text: i18n.t('please_save_the_new_row_before_adding_another'),
                                                        confirmButtonText: i18n.t('ok'),
                                                        confirmButtonColor: config.primaryColor
                                                    })
                                                    return;
                                                }
                                            }


                                            let push_obj = {
                                                contract_amount: this.state.proposal?.estimated_cost,
                                                br_no: '',
                                                br_date: '',
                                                bill_type: '',
                                                mb_no: '',
                                                mb_page: '',
                                                total_paid_till_last_month: '',
                                                bill_amount_this_month: '',
                                                cumulative_bill_amount: '',
                                                paid_till_last_month: '',
                                                paid_during_this_month_by_cheque: '',
                                                cheque_no: '',
                                                bank_name: '',
                                                cheque_date: '',
                                                total_paid_cumulative: '',
                                                pending_payment: '',
                                                new: true
                                            };
                                            
                                            if (!financial_progress_data || financial_progress_data.length == 0) {
                                                push_obj['month'] = moment().format('MMMM')
                                                push_obj['year'] = moment().format('YYYY')
                                                push_obj['total_paid_till_last_month'] = 0
                                                push_obj['paid_till_last_month'] = 0
                                            } else {
                                                // push the next month of the prevous record and the year only if the month is december
                                                push_obj['month'] = moment(financial_progress_data[financial_progress_data.length - 1].month, 'MMMM').add(1, 'months').format('MMMM')
                                                if (push_obj['month'] == 'January') {
                                                    push_obj['year'] = moment(financial_progress_data[financial_progress_data.length - 1].year, 'YYYY').add(1, 'years').format('YYYY')
                                                } else {
                                                    push_obj['year'] = financial_progress_data[financial_progress_data.length - 1].year
                                                }

                                                push_obj['total_paid_till_last_month'] = financial_progress_data[financial_progress_data.length - 1].total_paid_cumulative
                                                push_obj['paid_till_last_month'] = financial_progress_data[financial_progress_data.length - 1].total_paid_cumulative
                                            }

                                            if(!financial_progress_data) {
                                                financial_progress_data = []
                                            }
                                            financial_progress_data.push(push_obj)
                                            await this.promisedSetState({
                                                financial_progress_data: financial_progress_data
                                            })

                                            this.computeRowFields('new_row', financial_progress_data.length - 1);
                                        }}
                                    >
                                        {i18n.t('add_data')}
                                    </Button>
                                </Grid>

                                <Grid container spacing={1} sx={{mt: 3}}>
                                    <Grid item xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                                        <Button
                                            variant="contained"
                                            onClick={async () => {
                                                await this.saveFinancialProgress();
                                            }}
                                            disabled={this.state.loading}
                                        >
                                            {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                                        </Button>
                                    </Grid>
                                </Grid>

                            </>

                        ) : null}
                        
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
    const params = useParams();
  
    return <FinancialProgress {...props} navigate={navigate} params={params} />;
}
