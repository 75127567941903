import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getQualityControl = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/quality_control' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addQualityControl = async (formData) => {
    try {
        let headers = getRequestHeaders();
        headers['Content-Type'] = 'multipart/form-data';
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/quality_control', formData, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteQualityControl = async (qualityControlId) => {
    try {
        let headers = getRequestHeaders();
        let response;
        try {
            response = await axios.delete( config.SERVER_BASE_URL + '/quality_control/' + qualityControlId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getQualityControl,
    addQualityControl,
    deleteQualityControl
}