/**Read this
 * Role Based Route
 * Now user role module based route is static and is for PMS
 */
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack, TextField, InputAdornment, IconButton, Hidden } from '@mui/material';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login as authLogin } from 'src/services/auth.service';
// localization
import i18n from '../i18n';
import config from 'src/config';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from 'src/utils/messageCodeToMessage';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      showPassword: false,
      loading: false,
      error: {}
    }
  }


  login = async () => {

    this.setState({
      loading: true
    });

    if (!(await this.isFormValid())) {
      this.setState({
        loading: false
      });
      return;
    }

    const payload = {
      username: this.state.username,
      password: this.state.password
    }

    let login = await authLogin(payload);
    if (login.error) {
      Swal.fire({
        icon: 'error',
        title: i18n.t('error'),
        text: login.error_code ? convertMessageCodeToMessage(login.error_code) : login.error,
        confirmButtonText: i18n.t('ok'),
        confirmButtonColor: config.primaryColor
      });
      this.setState({
        loading: false
      });
      return;
    }


    localStorage.setItem('username', login.username);
    localStorage.setItem('role', login.role);
    localStorage.setItem('token', login.token);

    // this.props.navigate('/' + login.role + '/dashboard', { replace: true });
    this.props.navigate('/' + login.role + `${login.role === "user" ? "/pms/dashboard" : "/dashboard"}`, { replace: true });
  };

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != '') {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }

  async validateField(field = null) {

    let error = this.state.error;

    if (field == null || field == 'username') {
      if (!this.state.username || this.state.username == '') {
        error.username = i18n.t('this_field_is_required');
      } else {
        error.username = '';
      }
    }

    if (field == null || field == 'password') {
      if (!this.state.password || this.state.password == '') {
        error.password = i18n.t('this_field_is_required');
      } else {
        error.password = '';
      }
    }

    this.setState({
      error: error
    })

    return error;
  }

  render() {

    return (
      <>
        <Helmet>
          <title> {i18n.t('login')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <StyledRoot>
          <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />

          <Hidden mdDown>
            <StyledSection>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                {i18n.t('hi')}, {i18n.t('welcome_back')}
              </Typography>
              <img src="/assets/illustrations/illustration_login.png" alt="login" />
            </StyledSection>
          </Hidden>

          <Container maxWidth="100%">
            <StyledContent>
              <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
                {i18n.t('sign_in_to')} {config.APPLICATION_NAME}
              </Typography>

              <Stack spacing={3}>
                <TextField
                  name="username"
                  label={i18n.t('username')}
                  onChange={async (e) => {
                    await this.setState({
                      username: e.target.value
                    })
                    await this.validateField('username');
                  }}
                  error={!!this.state?.error?.username}
                  helperText={this.state?.error?.username}
                />

                <TextField
                  name="password"
                  label={i18n.t('password')}
                  type={this.state.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }} edge="end">
                          <Iconify icon={this.state.showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={async (e) => {
                    await this.setState({
                      password: e.target.value
                    })
                    await this.validateField('password');
                  }}
                  // on enter key, call login function
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && this.state.username && this.state.password) {
                      this.login();
                    }
                  }}
                  error={!!this.state?.error?.password}
                  helperText={this.state?.error?.password}
                />
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                {/* <Checkbox name="remember" label="Remember me" /> */}
                <Link to={"/forgotpassword"} underline="hover">
                  Forgot password?
                </Link>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={this.login}
                sx={{ mt: 3 }}
                disabled={this.state.loading}
              >
                {this.state.loading ? i18n.t('logging_in') : i18n.t('login')}
              </LoadingButton>



            </StyledContent>
          </Container>
        </StyledRoot>
      </>
    );

  }


}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();

  return <Login {...props} navigate={navigate} />;
}