import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getLicenses = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/licenses' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getLicense = async (licenseId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/licenses/' + licenseId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

// const getProfile = async () => {
//     try {

//         let headers = getRequestHeaders();

//         let response;
//         try {
//             response = await axios.get( config.SERVER_BASE_URL + '/license/profile', { headers: headers });
//         } catch (error) {
//             return error.response.data;
//         }
//         return response.data;
//     } catch (error) {
//         return { error: error.message };
//     }
// }

const addLicense = async (payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/licenses', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editLicense = async (licenseId, payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.patch( config.SERVER_BASE_URL + '/licenses/' + licenseId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteLicense = async (licenseId) => {
    try {
        let headers = getRequestHeaders();
        let response;
        try {
            response = await axios.delete(config.SERVER_BASE_URL + '/licenses/' + licenseId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

// const editProfile = async (payload) => {
//     try {

//         let headers = getRequestHeaders();

//         let response;
//         try {
//             response = await axios.patch( config.SERVER_BASE_URL + '/licenses/profile', payload, { headers: headers });
//         } catch (error) {
//             return error.response.data;
//         }
//         return response.data;
//     } catch (error) {
//         return { error: error.message };
//     }
// }

export {
    getLicenses,
    getLicense,
    // getProfile,
    addLicense,
    editLicense,
    deleteLicense
    // editProfile
}