/**Read this
 * Backend connection needed for the new fields
 * Data is being stored tested by adding a new company named [test company name]
 */

import { Autocomplete, Button, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getCompany as getCompanyService, addCompany as addCompanyService, editCompany as editCompanyService } from 'src/services/company.service';
import Swal from "sweetalert2";
import { getRolesForSelection } from "src/services/common.service";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

class AddEditCompany extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.company_id ? 'edit' : 'add',
            company_id: props.params?.company_id,
            name: '',

            //new fileds
            salutation: '',
            contact_first_name: '',
            contact_last_name: '',
            contact_email: '',
            contact_phone_number: '',
            company_gst_treatment: '',
            company_gst_number: '',
            pan_number: '',
            address: '',

            // license_start_date: null,
            // license_end_date: null,
            status: 'active',
            error: {},
            loading: false
        }
    }

    async componentDidMount() {

        if (this.state.mode == 'edit') {
            await this.getCompany();
        }
    }

    async getCompany() {
        let response = await getCompanyService(this.state.company_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.company) {
            let company = response.company;

            await this.setState({
                name: company?.name ? company.name : '',

                //new fields
                salutation: company?.salutation ? company.salutation : '',
                contact_first_name: company?.contact_first_name ? company.contact_first_name : '',
                contact_last_name: company?.contact_last_name ? company.contact_last_name : '',
                contact_email: company?.contact_email ? company.contact_email : '',
                contact_phone_number: company?.contact_phone_number ? company.contact_phone_number : '',
                company_gst_treatment: company?.company_gst_treatment ? company.company_gst_treatment : '',
                company_gst_number: company?.company_gst_number ? company.company_gst_number : '',
                pan_number: company?.pan_number ? company.pan_number : '',

                address: company?.address ? company.address : '',
                status: company?.status ? company.status : '',
                // license_start_date: company?.license_start_date ? moment(company.license_start_date) : null,
                // license_end_date: company?.license_end_date ? moment(company.license_end_date) : null
            })
        }
    }

    async saveCompany() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {
            name: this.state.name,
            salutation: this.state.salutation,
            contact_first_name: this.state.contact_first_name,
            contact_last_name: this.state.contact_last_name,
            contact_email: this.state.contact_email,
            contact_phone_number: this.state.contact_phone_number,
            company_gst_treatment: this.state.company_gst_treatment,
            company_gst_number: this.state.company_gst_number,
            pan_number: this.state.pan_number,
            address: this.state.address,
            // license_start_date: this.state.license_start_date,
            // license_end_date: this.state.license_end_date,
            status: this.state.status
        }

        if (this.state.mode == 'add') {
            let response = await addCompanyService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.company) {
                this.setState({
                    loading: false
                })
                this.props.navigate('/' + localStorage.getItem('role') + '/companies');
            }
        } else {
            let response = await editCompanyService(this.state.company_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.company) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('company_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getCompany();
            }
        }

    }

    async isFormValid() {
        let error = await this.validateField();
        console.log("Error", error)
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field = null) {

        let error = this.state.error;

        // name validation
        if (field == null || field == 'name') {
            if (!this.state.name || this.state.name == '') {
                error.name = i18n.t('this_field_is_required');
            } else {
                error.name = '';
            }
        }

        // salutation validation
        if (field == null || field == 'salutation') {
            if (!this.state.salutation || this.state.salutation == '') {
                error.salutation = i18n.t('this_field_is_required');
            } else {
                error.salutation = '';
            }
        }

        // contact frist name validation
        if (field == null || field == 'contact_first_name') {
            if (!this.state.contact_first_name || this.state.contact_first_name == '') {
                error.contact_first_name = i18n.t('this_field_is_required');
            } else {
                error.contact_first_name = '';
            }
        }

        // contact last name validation
        if (field == null || field == 'contact_last_name') {
            if (!this.state.contact_last_name || this.state.contact_last_name == '') {
                error.contact_last_name = i18n.t('this_field_is_required');
            } else {
                error.contact_last_name = '';
            }
        }

        // contact email validation
        if (field == null || field == 'contact_email') {
            if (!this.state.contact_email || this.state.contact_email == '') {
                error.contact_email = i18n.t('this_field_is_required');
            } else {
                error.contact_email = '';
            }
        }


        // contact phone number validation
        if (field == null || field == 'contact_phone_number') {
            if (!this.state.contact_phone_number || this.state.contact_phone_number == '') {
                error.contact_phone_number = i18n.t('this_field_is_required');
            } else {
                error.contact_phone_number = '';
            }
        }

        // company gst treatment validation
        if (this.state.mode === 'add' && field == null || field == 'company_gst_treatment') {
            if (!this.state.company_gst_treatment || this.state.company_gst_treatment == '') {
                error.company_gst_treatment = i18n.t('this_field_is_required');
            } else {
                error.company_gst_treatment = '';
            }
        }

        // company gst number validation
        if (this.state.mode === 'add' && this.state.company_gst_treatment === "GST Register" && field == null || field == 'company_gst_number') {
            if (!this.state.company_gst_number || this.state.company_gst_number == '') {
                error.company_gst_number = i18n.t('this_field_is_required');
            } else {
                error.company_gst_number = '';
            }
        }

        // pan number validation
        if (field == null || field == 'pan_number') {
            if (!this.state.pan_number || this.state.pan_number == '') {
                error.pan_number = i18n.t('this_field_is_required');
            } else {
                error.pan_number = '';
            }
        }


        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_company') : i18n.t('edit_company')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_company') : i18n.t('edit_company')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="name"
                                label={i18n.t('name')}
                                fullWidth
                                value={this.state.name}
                                onChange={async (e) => {
                                    await this.setState({
                                        name: e.target.value
                                    })
                                    this.validateField('name');
                                }}
                                error={!!this.state?.error?.name}
                                helperText={this.state?.error?.name}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={2}>
                            <Autocomplete
                                value={this.state.salutation ? this.state.salutation : null}
                                onChange={async (e, newValue) => {
                                    await this.setState({
                                        salutation: newValue
                                    })
                                    this.validateField('salutation');
                                }}
                                options={["Mr.", "Mrs.", "Dr."]}
                                renderInput={(params) => <TextField {...params} label={"Salutation"} error={!!this.state?.error?.salutation} />}
                                // disabled={(this.state.mode == 'edit') ? true : false}
                            />
                            <FormHelperText error={!!this.state?.error?.salutation}>{this.state?.error?.salutation}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                fullWidth
                                name="contact_first_name"
                                label={"Contact First Name"}
                                value={this.state.contact_first_name}
                                onChange={async (e) => {
                                    await this.setState({
                                        contact_first_name: e.target.value
                                    })
                                    this.validateField('contact_first_name');
                                }}
                                error={!!this.state?.error?.contact_first_name}
                                helperText={this.state?.error?.contact_first_name}>
                            </TextField>

                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                fullWidth
                                name="contact_last_name"
                                label={"Contact Last Name"}
                                value={this.state.contact_last_name}
                                onChange={async (e) => {
                                    await this.setState({
                                        contact_last_name: e.target.value
                                    })
                                    this.validateField('contact_last_name');
                                }}
                                error={!!this.state?.error?.contact_last_name}
                                helperText={this.state?.error?.contact_last_name}>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                type="email"
                                name="contact_email"
                                label={i18n.t('email')}
                                fullWidth
                                value={this.state.contact_email}
                                onChange={async (e) => {
                                    await this.setState({
                                        contact_email: e.target.value
                                    })
                                    this.validateField('contact_email');
                                }}
                                error={!!this.state?.error?.contact_email}
                                helperText={this.state?.error?.contact_email}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="contact_phone_number"
                                label={i18n.t('primary_contact_number')}
                                fullWidth
                                value={this.state.contact_phone_number}
                                onChange={async (e) => {
                                    await this.setState({
                                        contact_phone_number: e.target.value
                                    })
                                    this.validateField('contact_phone_number');
                                }}
                                error={!!this.state?.error?.contact_phone_number}
                                helperText={this.state?.error?.contact_phone_number}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>

                        <Grid item xs={12} md={6} lg={3}>

                        <Autocomplete
                                value={this.state.company_gst_treatment ? this.state.company_gst_treatment : null}
                                onChange={async (e, newValue) => {
                                    await this.setState({
                                        company_gst_treatment: newValue
                                    })
                                    this.validateField('company_gst_treatment');
                                }}
                                options={["Unregistered Business","GST Register"]}
                                renderInput={(params) => <TextField {...params} label={"Company GST Treatment"} error={!!this.state?.error?.company_gst_treatment} />}
                                // disabled={(this.state.mode == 'edit') ? true : false}
                            />
                            <FormHelperText error={!!this.state?.error?.company_gst_treatment}>{this.state?.error?.company_gst_treatment}</FormHelperText>
                        </Grid>

                        {/* display only if user selects GST Register else disabled TextField */}

                        {
                            this.state.company_gst_treatment === "GST Register" ? (
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField
                                        fullWidth
                                        name="company_gst_number"
                                        label={"GST Number"}
    
                                        value={this.state.company_gst_number}
                                        onChange={async (e) => {
                                            await this.setState({
                                                company_gst_number: e.target.value
                                            })
                                            this.validateField('company_gst_number');
                                        }}
                                        error={!!this.state?.error?.company_gst_number}
                                        helperText={this.state?.error?.company_gst_number}
                                    >
                                    </TextField>

                                </Grid>
                            ) : (<Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    fullWidth
                                    name="company_gst_number"
                                    label={"GST Number"}

                                    value={this.state.company_gst_number}
                                    onChange={async (e) => {
                                        await this.setState({
                                            company_gst_number: e.target.value
                                        })
                                        this.validateField('company_gst_number');
                                    }}
                                    error={!!this.state?.error?.company_gst_number}
                                    helperText={this.state?.error?.company_gst_number}
                                    disabled
                                >
                                </TextField>

                            </Grid>)
                        }

                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                fullWidth
                                name="pan_number"
                                label={i18n.t('pan_number')}
                                value={this.state.pan_number}
                                onChange={async (e) => {
                                    await this.setState({
                                        pan_number: e.target.value
                                    })
                                    this.validateField('pan_number');
                                }}
                                error={!!this.state?.error?.pan_number}
                                helperText={this.state?.error?.pan_number}>
                            </TextField>

                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                name="address"
                                label={i18n.t('address')}
                                fullWidth
                                rows={4}
                                multiline
                                value={this.state.address}
                                onChange={async (e) => {
                                    await this.setState({
                                        address: e.target.value
                                    })
                                    this.validateField('address');
                                }}
                                error={!!this.state?.error?.address}
                                helperText={this.state?.error?.address}
                            />
                        </Grid>
                    </Grid>

                    {/* <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('license_start_date')}
                                    value={this.state.license_start_date ? this.state.license_start_date : null}
                                    fullWidth
                                    onChange={async (newValue) => {
                                        await this.setState({license_start_date: newValue})
                                        this.validateField('license_start_date');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.license_start_date,
                                            error: !!this.state?.error?.license_start_date,
                                            fullWidth: true
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        
                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('license_end_date')}
                                    value={this.state.license_end_date ? this.state.license_end_date : null}
                                    fullWidth
                                    onChange={async (newValue) => {
                                        await this.setState({license_end_date: newValue})
                                        this.validateField('license_end_date');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.license_end_date,
                                            error: !!this.state?.error?.license_end_date,
                                            fullWidth: true
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>{i18n.t('inactive')}</Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={(this.state.status == 'active') ? true : false}
                                            label={i18n.t('status')}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    status: e.target.checked ? 'active' : 'inactive'
                                                })
                                            }}
                                        />
                                    }
                                    label={i18n.t('status')}
                                    labelPlacement="bottom"
                                />
                                <Typography>{i18n.t('active')}</Typography>
                            </Stack>
                        </Grid>
                    </Grid> */}

                    {/* Company Status */}
                    
                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>{i18n.t('inactive')}</Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={(this.state.status == 'active') ? true : false}
                                            label={i18n.t('status')}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    status: e.target.checked ? 'active' : 'inactive'
                                                })
                                            }}
                                        />
                                    }
                                    label={i18n.t('status')}
                                    labelPlacement="bottom"
                                />
                                <Typography>{i18n.t('active')}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveCompany();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <AddEditCompany {...props} navigate={navigate} params={params} />;
}
