// component
import i18n from 'src/i18n';

import BarChartIcon from '@mui/icons-material/BarChart';
// import PeopleIcon from '@mui/icons-material/People';
// import ConstructionIcon from '@mui/icons-material/Construction';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import BusinessIcon from '@mui/icons-material/Business';
import EditNoteIcon from '@mui/icons-material/EditNote';
import GavelIcon from '@mui/icons-material/Gavel';
import BusinessCenterSharpIcon from '@mui/icons-material/BusinessCenterSharp';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BalanceIcon from '@mui/icons-material/Balance';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: i18n.t('dashboard'),
    path: '/user/pms/dashboard',
    icon: <BarChartIcon  />,
  },
  // {
  //   title: i18n.t('users'),
  //   path: '/user/pms/users',
  //   icon: <PeopleIcon />,
  // },
  // {
  //   title: i18n.t('contractors'),
  //   path: '/user/pms/contractors',
  //   icon: <ConstructionIcon />,
  // },
  {
    title: i18n.t('proposals'),
    path: '/user/pms/proposals',
    icon: <EditNoteIcon />,
  },
  {
    title: i18n.t('tenders'),
    path: '/user/pms/tenders',
    icon: <GavelIcon />,
  },
  {
    title: i18n.t('works'),
    path: '/user/pms/works',
    icon: <BusinessCenterSharpIcon />,
  },
  {
    title: i18n.t('work_schedule'),
    path: '/user/pms/work_schedule',
    icon: <WorkHistoryIcon />,
  },
  {
    title: i18n.t('physical_progress'),
    path: '/user/pms/physical_progress',
    icon: <AlignHorizontalLeftIcon />,
  },
  {
    title: i18n.t('financial_progress'),
    path: '/user/pms/financial_progress',
    icon: <TrendingUpIcon />,
  },
  {
    title: i18n.t('quality_control'),
    path: '/user/pms/quality_control',
    icon: <BalanceIcon />,
  },
];

export default navConfig;
