/**Read this
 * here sr_no must be auto genrated
 * Backend connection needed for saving the data
 */

import { Autocomplete, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getHeadOfAccount as getHeadOfAccountService, addHeadOfAccount as addHeadOfAccountService, editHeadOfAccount as editHeadOfAccountServies } from "src/services/headofaccounts.service";
import Swal from "sweetalert2";
// import { getRolesForSelection } from "src/services/common.service";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import moment from "moment";
// mui
import { teal } from '@mui/material/colors';
import { Label } from "@mui/icons-material";

class AddEditHeadOfAccounts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.head_of_accounts_id ? 'edit' : 'add',
            head_of_accounts_id: props.params?.head_of_accounts_id,
            work_name: '',
            description: '',
            head_of_accounts: '',
            head_of_account_code: '',
            error: {},
            loading: false,
        }
    }

    async componentDidMount() {

        if (this.state.mode == 'edit') {
            await this.getHeadOfAccount();
        }
    }

    async getHeadOfAccount() {
        let response = await getHeadOfAccountService(this.state.head_of_accounts_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.headofaccount) {
            let headofaccount = response.headofaccount;

            await this.setState({
                work_name: headofaccount?.work_name ? headofaccount.work_name : '',
                description: headofaccount?.description ? headofaccount.description : '',
                head_of_accounts: headofaccount?.head_of_accounts ? headofaccount.head_of_accounts : '',
                head_of_account_code: headofaccount?.head_of_account_code ? headofaccount.head_of_account_code : '',
            })
        }
    }

    /**Function to save headofaccount
     * @returns Saves the headofaccount to the backend
     */
    async saveHeadOfAccounts() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {
            work_name: this.state.work_name,
            description: this.state.description,
            head_of_accounts: this.state.head_of_accounts,
            head_of_account_code: this.state.head_of_account_code
        }

        if (this.state.mode == 'add') {
            let response = await addHeadOfAccountService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.headofaccount) {
                this.setState({
                    loading: false
                })
                this.props.navigate('/' + localStorage.getItem('role') + '/mastertables/head_of_accounts');
            }
        } else {
            let response = await editHeadOfAccountServies(this.state.head_of_accounts_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.units) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('headofaccounts_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getHeadOfAccount();
            }
        }
    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field = null) {

        let error = this.state.error;

        // work_name validation
        if (field == null || field == 'work_name') {
            if (!this.state.work_name || this.state.work_name == '') {
                error.work_name = i18n.t('this_field_is_required');
            } else {
                error.work_name = '';
            }
        }

        // description validation
        if (field == null || field == 'description') {
            if (!this.state.description || this.state.description == '') {
                error.description = i18n.t('this_field_is_required');
            } else {
                error.description = '';
            }
        }

        // head_of_accounts validation
        if (field == null || field == 'head_of_accounts') {
            if (!this.state.head_of_accounts || this.state.head_of_accounts == '') {
                error.head_of_accounts = i18n.t('this_field_is_required');
            } else {
                error.head_of_accounts = '';
            }
        }

        // head_of_account_code validation
        if (field == null || field == 'head_of_account_code') {
            if (!this.state.head_of_account_code || this.state.head_of_account_code == '') {
                error.head_of_account_code = i18n.t('this_field_is_required');
            } else {
                error.head_of_account_code = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_head_of_accounts') : i18n.t('edit_head_of_accounts')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_head_of_accounts') : i18n.t('edit_head_of_accounts')}
                        </Typography>
                    </Stack>

                    {/* <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="sr_no"
                                label={i18n.t('sr_no')}
                                fullWidth
                                value={this.state.sr_no}
                                onChange={async (e) => {
                                    await this.setState({
                                        sr_no: e.target.value
                                    })
                                    this.validateField('sr_no');
                                }}
                                error={!!this.state?.error?.sr_no}
                                helperText={this.state?.error?.sr_no}
                                disabled={(this.state.mode == 'edit') ? true : false}
                            />
                        </Grid>
                    </Grid> */}

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="work_name"
                                label={i18n.t('work_name')}
                                fullWidth
                                value={this.state.work_name}
                                onChange={async (e) => {
                                    await this.setState({
                                        work_name: e.target.value
                                    })
                                    this.validateField('work_name');
                                }}
                                error={!!this.state?.error?.work_name}
                                helperText={this.state?.error?.work_name}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="description"
                                label={i18n.t('description')}
                                fullWidth
                                value={this.state.description}
                                onChange={async (e) => {
                                    await this.setState({
                                        description: e.target.value
                                    })
                                    this.validateField('description');
                                }}
                                error={!!this.state?.error?.description}
                                helperText={this.state?.error?.description}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="head_of_accounts"
                                label={i18n.t('head_of_accounts')}
                                fullWidth
                                value={this.state.head_of_accounts}
                                onChange={async (e) => {
                                    await this.setState({
                                        head_of_accounts: e.target.value
                                    })
                                    this.validateField('head_of_accounts');
                                }}
                                error={!!this.state?.error?.head_of_accounts}
                                helperText={this.state?.error?.head_of_accounts}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="head_of_account_code"
                                label={i18n.t('head_of_account_code')}
                                fullWidth
                                value={this.state.head_of_account_code}
                                onChange={async (e) => {
                                    await this.setState({
                                        head_of_account_code: e.target.value
                                    })
                                    this.validateField('head_of_account_code');
                                }}
                                error={!!this.state?.error?.head_of_account_code}
                                helperText={this.state?.error?.head_of_account_code}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveHeadOfAccounts();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <AddEditHeadOfAccounts {...props} navigate={navigate} params={params} />;
}
