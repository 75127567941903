/**Read this
 * UI completed
 * Reset function not working
 * The error message is unable_to_login_user
 * Backend connection needed
 */
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack, TextField, InputAdornment, IconButton, Hidden, Button } from '@mui/material';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { forgot as authForgot } from 'src/services/auth.service';
// localization
import i18n from '../i18n';
import config from 'src/config';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from 'src/utils/messageCodeToMessage';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            otp: '',
            password: '',
            retype_password: '',
            loading: false,
            error: {}
        }
    }


    forgot = async () => {

        this.setState({
            loading: true
        });

        if (!(await this.isFormValid())) {
            this.setState({
                loading: false
            });
            return;
        }

        const payload = {
            username: this.state.username,
            password: this.state.password
        }

        let forgot = await authForgot(payload);
        if (forgot.error) {
            console.log(forgot.error);
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: forgot.error_code ? convertMessageCodeToMessage(forgot.error_code) : forgot.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            });
            this.setState({
                loading: false
            });
            return;
        }

        this.props.navigate('/');
    };

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field = null) {

        let error = this.state.error;

        if (field == null || field == 'username') {
            if (!this.state.username || this.state.username == '') {
                error.username = i18n.t('this_field_is_required');
            } else {
                error.username = '';
            }
        }

        if (field == null || field == 'otp') {
            if (!this.state.otp || this.state.otp == '') {
                error.otp = i18n.t('this_field_is_required');
            } else {
                error.otp = '';
            }
        }

        if (field == null || field == 'password') {
            if (!this.state.password || this.state.password == '') {
                error.password = i18n.t('this_field_is_required');
            } else {
                error.password = '';
            }
        }

        if (field == null || field == 'retype_password') {
            if (!this.state.retype_password || this.state.retype_password == '') {
                error.retype_password = i18n.t('this_field_is_required');
            } else {
                error.retype_password = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    resetForm = () => {
        this.setState({
            username: "",
            otp: "",
            password: "",
            retype_password: "",
            loading: false,
            error: {},
        });
    }


    render() {

        return (
            <>
                <Helmet>
                    <title> {i18n.t('forgot_password')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <StyledRoot>
                    <Logo
                        sx={{
                            position: 'fixed',
                            top: { xs: 16, sm: 24, md: 40 },
                            left: { xs: 16, sm: 24, md: 40 },
                        }}
                    />

                    <Hidden mdDown>
                        <StyledSection>
                            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                                {i18n.t('forgot_password')}
                            </Typography>
                            <img src="/assets/illustrations/illustrstion_forgotpassword.png" alt="forgot" style={{ mixBlendMode: "multiply" }} />
                        </StyledSection>
                    </Hidden>

                    <Container maxWidth="100%">
                        <StyledContent>
                            <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
                                {i18n.t('forgot_password')}
                            </Typography>

                            <Stack spacing={3}>
                                <TextField
                                    name="username"
                                    label={i18n.t('username')}
                                    onChange={async (e) => {
                                        await this.setState({
                                            username: e.target.value
                                        })
                                        await this.validateField('username');
                                    }}
                                    error={!!this.state?.error?.username}
                                    helperText={this.state?.error?.username}
                                />

                                <TextField
                                    name="otp"
                                    label={i18n.t('otp')}
                                    onChange={async (e) => {
                                        await this.setState({
                                            otp: e.target.value
                                        })
                                        await this.validateField('otp');
                                    }}
                                    error={!!this.state?.error?.otp}
                                    helperText={this.state?.error?.otp}
                                />

                                <TextField
                                    name="password"
                                    label={i18n.t('password')}
                                    type="text"
                                    onChange={async (e) => {
                                        await this.setState({
                                            password: e.target.value
                                        })
                                        await this.validateField('password');
                                    }}
                                    error={!!this.state?.error?.password}
                                    helperText={this.state?.error?.password}
                                />

                                <TextField
                                    name="retype_password"
                                    label={i18n.t('retype_password')}
                                    type="text"
                                    onChange={async (e) => {
                                        await this.setState({
                                            retype_password: e.target.value
                                        })
                                        await this.validateField('retype_password');
                                    }}
                                    // on enter key, call forgot function
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && this.state.username && this.state.otp && this.state.password && this.state.retype_password) {
                                            this.forgot();
                                        }
                                    }}
                                    error={!!this.state?.error?.retype_password}
                                    helperText={this.state?.error?.retype_password}
                                />
                            </Stack>

                            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                                {/* <Checkbox name="remember" label="Remember me" /> */}
                                <Link to={"/"} underline="hover">
                                    Sign In
                                </Link>
                            </Stack>

                            <Stack direction="row" spacing={3} alignItems={'center'} justifyContent={'space-between'}
                                sx={{ mt: 3 }}
                            >
                                <Button size="large"
                                    variant="contained"
                                    color="error"
                                    style={{ width: 200 }}
                                    onClick={this.resetForm}
                                >
                                    Reset
                                </Button>
                                <LoadingButton
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={this.forgot}
                                    disabled={this.state.loading}
                                    style={{ width: 200 }}
                                    sx={{ mt: 3 }}
                                >
                                    {this.state.loading ? i18n.t('submitting') : i18n.t('submit')}
                                </LoadingButton>
                            </Stack>
                        </StyledContent>
                    </Container>
                </StyledRoot>
            </>
        );

    }


}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();

    return <ForgotPassword {...props} navigate={navigate} />;
}