import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Chip, Container, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getTender as getTenderService, addTender as addTenderService, editTender as editTenderService } from 'src/services/tender.service';
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import { getDropdownValues } from "src/services/dropdown.service";
import {
    getStates as getStatesService,
    getZones as getZonesService,
    getCircles as getCirclesService,
    getDivisions as getDivisionsService,
    getDistricts as getDistrictsService,
    getTaluks as getTaluksService
} from "src/services/accessInfo.service";
import { getRoadTypes as getRoadTypesService, getRoads as getRoadsService } from "src/services/road.service";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getProposalFinancialYears, getProposalIndentNos, getProposals as getProposalsService } from "src/services/proposal.service";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getContractorClassesForSelection } from "src/services/common.service";
import moment from "moment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class AddEditTender extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.tend_id ? 'edit' : 'add',
            tend_id: props.params?.tend_id,
            financial_years: [],
            indent_nos: [],
            contractor_classes: config.CONTRACTOR_CLASSES,
            inviting_authorities: [],
            financial_year: '',
            indent_no: '',
            proposal: null,
            emd: '',
            publication_of_tender_bulletin: '',
            tender_bulletin: null,
            publication_of_e_proc: '',
            amount_put_to_tender: '',
            last_date_for_submission: '',
            date_of_opening_technical_bid: '',
            class_of_contractor: '',
            inviting_authority: '',
            cover: 'single',
            error: {},
            loading: false
        }
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {

        this.setInvitingAuthorities();
        
        if (this.state.mode == 'add') {

            this.setFinancialYears();
            this.setIndentNos();
        }

        if (this.state.mode == 'edit') {
            await this.getTender();
        }
    }

    async setInvitingAuthorities() {
        let inviting_authorities = await getDropdownValues('inviting_authority');
        if (inviting_authorities && inviting_authorities.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: inviting_authorities.error_code ? convertMessageCodeToMessage(inviting_authorities.error_code) : inviting_authorities.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (inviting_authorities && inviting_authorities.inviting_authority) {
            await this.setState({
                inviting_authorities: inviting_authorities.inviting_authority
            })
        }
    }

    async setFinancialYears() {
        let financial_years = await getProposalFinancialYears();
        if (financial_years && financial_years.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: financial_years.error_code ? convertMessageCodeToMessage(financial_years.error_code) : financial_years.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (financial_years && financial_years.financial_year) {
            await this.setState({
                financial_years: financial_years.financial_year
            })
        }
    }

    async setIndentNos() {

        let financial_year = this.state.financial_year;
        if (!financial_year || financial_year == '') {
            return;
        }

        let indent_nos = await getProposalIndentNos({financial_year: financial_year});
        if (indent_nos && indent_nos.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: indent_nos.error_code ? convertMessageCodeToMessage(indent_nos.error_code) : indent_nos.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (indent_nos && indent_nos.indent_no) {
            await this.setState({
                indent_nos: indent_nos.indent_no
            })
        }
    }

    async setProposal() {

        if (!this.state.financial_year || !this.state.indent_no) {
            this.setState({
                proposal: null
            })
            return;
        }

        let proposal = await getProposalsService({financial_year: this.state.financial_year, indent_no: this.state.indent_no});

        if (proposal && proposal.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: proposal.error_code ? convertMessageCodeToMessage(proposal.error_code) : proposal.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (proposal && proposal.proposals && proposal.proposals.length > 0) {
            await this.setState({
                proposal: proposal.proposals[0]
            })
        } else {
            await this.setState({
                proposal: null
            })
        }

    }


    async setHeadOfAccounts() {
        let head_of_accounts = await getDropdownValues('head_of_account');
        if (head_of_accounts && head_of_accounts.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: head_of_accounts.error_code ? convertMessageCodeToMessage(head_of_accounts.error_code) : head_of_accounts.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (head_of_accounts && head_of_accounts.head_of_account) {
            await this.setState({
                head_of_accounts: head_of_accounts.head_of_account
            })
        }
    }

    async setAdminApprovals() {
        let admin_approvals = await getDropdownValues('admin_approval');
        if (admin_approvals && admin_approvals.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: admin_approvals.error_code ? convertMessageCodeToMessage(admin_approvals.error_code) : admin_approvals.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (admin_approvals && admin_approvals.admin_approval) {
            await this.setState({
                admin_approvals: admin_approvals.admin_approval
            })
        }
    }

    async setTechnicalSanctions() {
        let technical_sanctions = await getDropdownValues('technical_sanction');
        if (technical_sanctions && technical_sanctions.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: technical_sanctions.error_code ? convertMessageCodeToMessage(technical_sanctions.error_code) : technical_sanctions.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (technical_sanctions && technical_sanctions.technical_sanction) {
            await this.setState({
                technical_sanctions: technical_sanctions.technical_sanction
            })
        }
    }

    async getTender() {
        let response = await getTenderService(this.state.tend_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.tender) {
            let tender = response.tender;

            await this.promisedSetState({
                financial_year: tender.financial_year,
                indent_no: tender.indent_no,
                emd: tender.emd,
                publication_of_tender_bulletin: moment(tender.publication_of_tender_bulletin),
                tender_bulletin: tender.tender_bulletin,
                publication_of_e_proc: moment(tender.publication_of_e_proc),
                amount_put_to_tender: tender.amount_put_to_tender,
                last_date_for_submission: moment(tender.last_date_for_submission),
                date_of_opening_technical_bid: moment(tender.date_of_opening_technical_bid),
                class_of_contractor: tender.class_of_contractor,
                inviting_authority: tender.inviting_authority,
                cover: tender.cover
            })

            this.setProposal();
        }
    }

    async saveTender() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let formData = new FormData();
        formData.append('emd', this.state.emd);
        formData.append('publication_of_tender_bulletin', this.state.publication_of_tender_bulletin);
        formData.append('tender_bulletin', this.state.tender_bulletin);
        formData.append('publication_of_e_proc', this.state.publication_of_e_proc);
        formData.append('amount_put_to_tender', this.state.amount_put_to_tender);
        formData.append('last_date_for_submission', this.state.last_date_for_submission);
        formData.append('date_of_opening_technical_bid', this.state.date_of_opening_technical_bid);
        formData.append('class_of_contractor', this.state.class_of_contractor);
        formData.append('inviting_authority', this.state.inviting_authority);
        formData.append('cover', this.state.cover);


        // let payload = {};


        // payload.financial_year = this.state.financial_year;
        // payload.indent_no = this.state.indent_no;
        // payload.emd = this.state.emd;
        // payload.publication_of_tender_bulletin = this.state.publication_of_tender_bulletin;
        // payload.tender_bulletin = this.state.tender_bulletin;
        // payload.publication_of_e_proc = this.state.publication_of_e_proc;
        // payload.amount_put_to_tender = this.state.amount_put_to_tender;
        // payload.last_date_for_submission = this.state.last_date_for_submission;
        // payload.date_of_opening_technical_bid = this.state.date_of_opening_technical_bid;
        // payload.class_of_contractor = this.state.class_of_contractor;
        // payload.inviting_authority = this.state.inviting_authority;
        // payload.cover = this.state.cover;

        // console.log(payload);

        if (this.state.mode == 'add') {

            formData.append('financial_year', this.state.financial_year);
            formData.append('indent_no', this.state.indent_no);

            let response = await addTenderService(formData);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.tender) {
                this.setState({
                    loading: false
                })
                this.props.navigate( '/' + localStorage.getItem('role') + '/tenders');
            }
        } else {

            let response = await editTenderService(this.state.tend_id, formData);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.tender) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('tender_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getTender();
            }
        }

        this.setState({
            loading: false
        })

    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'financial_year') {
            if (!this.state.financial_year || this.state.financial_year == '') {
                error.financial_year = i18n.t('this_field_is_required');
            } else {
                error.financial_year = '';
            }
        }

        if (field == null || field == 'indent_no') {
            if (!this.state.indent_no || this.state.indent_no == '') {
                error.indent_no = i18n.t('this_field_is_required');
            } else {
                error.indent_no = '';
            }
        }

        if (field == null || field == 'emd') {
            if (!this.state.emd || this.state.emd == '') {
                error.emd = i18n.t('this_field_is_required');
            } else {
                error.emd = '';
            }
        }

        if (field == null || field == 'publication_of_tender_bulletin') {
            if (!this.state.publication_of_tender_bulletin || this.state.publication_of_tender_bulletin == '') {
                error.publication_of_tender_bulletin = i18n.t('this_field_is_required');
            } else {
                error.publication_of_tender_bulletin = '';
            }
        }

        if (this.state.mode == 'add' && (field == null || field == 'tender_bulletin')) {
            if (!this.state.tender_bulletin || this.state.tender_bulletin == '') {
                error.tender_bulletin = i18n.t('this_field_is_required');
            } else {
                error.tender_bulletin = '';
            }
        }
        
        if (field == null || field == 'publication_of_e_proc') {
            if (!this.state.publication_of_e_proc || this.state.publication_of_e_proc == '') {
                error.publication_of_e_proc = i18n.t('this_field_is_required');
            } else {
                error.publication_of_e_proc = '';
            }
        }
        
        if (field == null || field == 'amount_put_to_tender') {
            if (!this.state.amount_put_to_tender || this.state.amount_put_to_tender == '') {
                error.amount_put_to_tender = i18n.t('this_field_is_required');
            } else {
                error.amount_put_to_tender = '';
            }
        }
        
        if (field == null || field == 'last_date_for_submission') {
            if (!this.state.last_date_for_submission || this.state.last_date_for_submission == '') {
                error.last_date_for_submission = i18n.t('this_field_is_required');
            } else {
                error.last_date_for_submission = '';
            }
        }
        
        if (field == null || field == 'date_of_opening_technical_bid') {
            if (!this.state.date_of_opening_technical_bid || this.state.date_of_opening_technical_bid == '') {
                error.date_of_opening_technical_bid = i18n.t('this_field_is_required');
            } else {
                error.date_of_opening_technical_bid = '';
            }
        }

        if (field == null || field == 'class_of_contractor') {
            if (!this.state.class_of_contractor || this.state.class_of_contractor == '') {
                error.class_of_contractor = i18n.t('this_field_is_required');
            } else {
                error.class_of_contractor = '';
            }
        }

        if (field == null || field == 'inviting_authority') {
            if (!this.state.inviting_authority || this.state.inviting_authority == '') {
                error.inviting_authority = i18n.t('this_field_is_required');
            } else {
                error.inviting_authority = '';
            }
        }
        
        if (field == null || field == 'cover') {
            if (!this.state.cover || this.state.cover == '') {
                error.cover = i18n.t('this_field_is_required');
            } else {
                error.cover = '';
            }
        }
        
        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_tender') : i18n.t('edit_tender')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_tender') : i18n.t('edit_tender')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.financial_year ? this.state.financial_year : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            financial_year: newValue,
                                            indent_no: ''
                                        })
                                        this.validateField('financial_year');
                                        await this.setIndentNos();
                                        await this.setProposal();
                                    }}
                                    options={this.state.financial_years}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('financial_year')} error={!!this.state?.error?.financial_year} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.financial_year}>{this.state?.error?.financial_year}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.indent_no ? this.state.indent_no : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            indent_no: newValue
                                        })
                                        this.validateField('indent_no');
                                        await this.setProposal();
                                    }}
                                    options={this.state.indent_nos}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('indent_no')} error={!!this.state?.error?.indent_no} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : ((this.state.financial_year && this.state.financial_year != '') ? false : true)}
                                />
                                <FormHelperText error={!!this.state?.error?.indent_no}>{this.state?.error?.indent_no}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {(this.state.proposal) ? (
                            <Grid item xs={12} md={12} lg={12} sx={{mt: 2, mb: 2}}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h5">{this.state.proposal?.name_of_work}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('proposal_id')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.proposal_id}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('name_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.name_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('type_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.type_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('state')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.state}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('zone')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.zone}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('circle')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.circle}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('division')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.division}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('district')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.district}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('taluk')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.taluk}</TableCell>
                                                    </TableRow>
                                                    {(this.state.proposal?.type_of_work == 'Road Construction' || this.state.proposal?.type_of_work == 'Road Maintenance') ? (
                                                        <>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_type')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_type}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_number')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_number}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_name')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_length')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_length}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('chainage')}</TableCell>
                                                                <TableCell align="right">
                                                                    {(this.state.proposal?.chainage?.length) ? (
                                                                        this.state.proposal?.chainage.map((chainage, index) => {
                                                                            return (
                                                                                <Chip key={index} label={chainage?.chainage_from + ' - ' + chainage?.chainage_to} />
                                                                            )
                                                                        })
                                                                    ) : null}
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    ) : null}
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('head_of_account')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.head_of_account}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('admin_approval')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.admin_approval}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('technical_sanction')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.technical_sanction}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('estimated_cost')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.estimated_cost}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ) : null}

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="emd"
                                label={i18n.t('emd') + ' ( ' + i18n.t('in') + ' ' + config.currencySymbol + ' )'}
                                fullWidth
                                value={this.state.emd}
                                onChange={async (e) => {
                                    await this.setState({
                                        emd: e.target.value
                                    })
                                    this.validateField('emd');
                                }}
                                error={!!this.state?.error?.emd}
                                helperText={this.state?.error?.emd}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('publication_of_tender_bulletin')}
                                    value={this.state.publication_of_tender_bulletin ? this.state.publication_of_tender_bulletin : null}
                                    onChange={async (newValue) => {
                                        await this.setState({
                                            publication_of_tender_bulletin: newValue
                                        })
                                        if (this.state.publication_of_e_proc && this.state.publication_of_e_proc < newValue) {
                                            await this.setState({
                                                publication_of_e_proc: null,
                                                last_date_for_submission: null,
                                                date_of_opening_technical_bid: null
                                            })
                                        }
                                        this.validateField('publication_of_tender_bulletin');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.publication_of_tender_bulletin,
                                            error: !!this.state?.error?.publication_of_tender_bulletin,
                                            fullWidth: true
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        
                        <Grid item xs={12} md={6} lg={4}>
                            <Input
                                type="file"
                                name="tender_bulletin"
                                label={i18n.t('tender_bulletin')}
                                fullWidth
                                onChange={async (e) => {
                                    await this.setState({
                                        tender_bulletin: e.target.files[0]
                                    })
                                    this.validateField('tender_bulletin');
                                }}
                                error={!!this.state?.error?.tender_bulletin}
                                helperText={this.state?.error?.tender_bulletin}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('publication_of_e_proc')}
                                    // It should be greater than publication_of_tender_bulletin
                                    minDate={this.state.publication_of_tender_bulletin ? this.state.publication_of_tender_bulletin : null}
                                    value={this.state.publication_of_e_proc ? this.state.publication_of_e_proc : null}
                                    onChange={async (newValue) => {
                                        await this.setState({
                                            publication_of_e_proc: newValue
                                        })
                                        if (this.state.last_date_for_submission && this.state.last_date_for_submission < newValue) {
                                            await this.setState({
                                                last_date_for_submission: null,
                                                date_of_opening_technical_bid: null
                                            })
                                        }
                                        this.validateField('publication_of_e_proc');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.publication_of_e_proc,
                                            error: !!this.state?.error?.publication_of_e_proc,
                                            fullWidth: true
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="amount_put_to_tender"
                                label={i18n.t('amount_put_to_tender') + ' ( ' + i18n.t('in') + ' ' + config.currencySymbol + ' )'}
                                fullWidth
                                value={this.state.amount_put_to_tender}
                                onChange={async (e) => {
                                    await this.setState({
                                        amount_put_to_tender: e.target.value
                                    })
                                    this.validateField('amount_put_to_tender');
                                }}
                                error={!!this.state?.error?.amount_put_to_tender}
                                helperText={this.state?.error?.amount_put_to_tender}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('last_date_for_submission')}
                                    // It should be greater than publication_of_e_proc
                                    minDate={this.state.publication_of_e_proc ? this.state.publication_of_e_proc : null}
                                    value={this.state.last_date_for_submission ? this.state.last_date_for_submission : null}
                                    onChange={async (newValue) => {
                                        await this.setState({
                                            last_date_for_submission: newValue
                                        })
                                        if (this.state.date_of_opening_technical_bid && this.state.date_of_opening_technical_bid < newValue) {
                                            await this.setState({
                                                date_of_opening_technical_bid: null
                                            })
                                        }
                                        this.validateField('last_date_for_submission');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.last_date_for_submission,
                                            error: !!this.state?.error?.last_date_for_submission,
                                            fullWidth: true
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('date_of_opening_technical_bid')}
                                    // It should be greater than last_date_for_submission
                                    minDate={this.state.last_date_for_submission ? this.state.last_date_for_submission : null}
                                    value={this.state.date_of_opening_technical_bid ? this.state.date_of_opening_technical_bid : null}
                                    onChange={async (newValue) => {
                                        await this.setState({
                                            date_of_opening_technical_bid: newValue
                                        })
                                        this.validateField('date_of_opening_technical_bid');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.date_of_opening_technical_bid,
                                            error: !!this.state?.error?.date_of_opening_technical_bid,
                                            fullWidth: true
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.class_of_contractor ? this.state.class_of_contractor : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            class_of_contractor: newValue
                                        })
                                        this.validateField('class_of_contractor');
                                    }}
                                    options={this.state.contractor_classes}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('class_of_contractor')} error={!!this.state?.error?.class_of_contractor} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.class_of_contractor}>{this.state?.error?.class_of_contractor}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.inviting_authority ? this.state.inviting_authority : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            inviting_authority: newValue
                                        })
                                        this.validateField('inviting_authority');
                                    }}
                                    options={this.state.inviting_authorities}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('inviting_authority')} error={!!this.state?.error?.inviting_authority} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.inviting_authority}>{this.state?.error?.inviting_authority}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">{i18n.t('cover')}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="single"
                                    name="radio-buttons-group"
                                    row
                                    onChange={async (e) => {
                                        await this.setState({
                                            cover: e.target.value
                                        })
                                        this.validateField('cover');
                                    }}
                                    value={this.state.cover}
                                >
                                    <FormControlLabel value="single" control={<Radio />} label={i18n.t('single')} />
                                    <FormControlLabel value="two" control={<Radio />} label={i18n.t('two')} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        
                    </Grid>

                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveTender();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
    const params = useParams();
  
    return <AddEditTender {...props} navigate={navigate} params={params} />;
}
