/**Read this
 * Only UI complated
 * Backend Connection needed
 * Bug:
 * - File is not being Previewed
 */

import { Autocomplete, Button, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getLicense as getLicenseService, addLicense as addLicenseService, editLicense as editLicenseService } from "src/services/license.service";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import Iconify from "src/components/iconify";
import styled from "@emotion/styled";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


class AddScheculeRateItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            schedulerate_id: props.params?.schedulerate_id,
            selectedFile: null,
            selectedFileContent: null,
            error: {},
            loading: false,
            companies: []
        }
    }

    // async getLicense() {
    //     let response = await getLicenseService(this.state.schedulerate_id);
    //     if (response && response.error) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: i18n.t('error'),
    //             text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
    //             confirmButtonText: i18n.t('ok'),
    //             confirmButtonColor: config.primaryColor
    //         })
    //         return;
    //     }
    //     if (response && response.schedulerate) {
    //         let license = response.license;

    //         await this.setState({
    //             name: license?.company ? license.company : '',
    //             status: license?.status ? license.status : '',
    //             module: license?.module ? license.module : '',
    //             license_start_date: license?.license_start_date ? moment(license.license_start_date) : null,
    //             license_end_date: license?.license_end_date ? moment(license.license_end_date) : null
    //         })
    //     }
    // }

    /**Function to save license
     * @returns Saves the license to the backend
     */
    async saveLicense() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {

            status: this.state.status,
            license_start_date: this.state.license_start_date,
            license_end_date: this.state.license_end_date,
        }

        if (this.state.mode == 'add') {
            let response = await addLicenseService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.company) {
                this.setState({
                    loading: false
                })
                this.props.navigate('/' + localStorage.getItem('role') + '/licenses');
            }
        } else {
            let response = await editLicenseService(this.state.schedulerate_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.license) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('license_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getLicense();
            }
        }
    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field = null) {

        let error = this.state.error;

        // selectedFile validation
        if (field == null || field == 'selectedFile') {
            if (!this.state.selectedFile || this.state.selectedFile === '') {
                error.selectedFile = i18n.t('this_field_is_required');
            } else {
                error.selectedFile = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    onFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ selectedFile: file, selectedFileContent: e.target.result });
            };
            reader.readAsText(file);
        }
    };

    render() {
        return (
            <>

                <Helmet>
                    <title> {i18n.t('add_schedule_item')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {i18n.t('add_schedule_item')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: 'purple' }}
                                startIcon={<Iconify icon="eva:download-fill" />}
                                onClick={() => console.log("Download Templet")}
                            >
                                {i18n.t('download_templet')}
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Button
                                component="label"
                                role={undefined}
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<Iconify icon="eva:upload-fill" />}
                                onClick={this.onFileChange}
                            >
                                Choose File
                                <VisuallyHiddenInput type="file" />
                            </Button>
                        </Grid>
                        <FormHelperText error={this.state?.error?.selectedFile}>{this.state?.error?.selectedFile}</FormHelperText>
                    </Grid>

                    {this.state.selectedFile && (
                        <Grid container spacing={1} sx={{ mt: 3 }}>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                                    {this.state.selectedFile.name} {/* Display the name of the selected file */}
                                </Typography>
                                <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                                    {this.state.selectedFileContent} {/* Display the content of the selected file */}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12}>
                            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                                {
                                    this.state.selectedFile && this.state.selectedFile
                                }
                                <img src={this.state.selectedFile} />
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Stack direction="row" alignItems="center" justifyContent={{ xs: "center" }} flexWrap="wrap" gap={{ xs: 1, sm: 2, md: 5 }} >
                                <Button
                                    variant="contained"
                                    onClick={async () => {
                                        await this.saveLicense();
                                    }}
                                    disabled={this.state.loading}
                                >
                                    {this.state.loading ? i18n.t('submitting') : i18n.t('submit')}
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        this.props.navigate(-1);
                                    }}
                                >
                                    {i18n.t('back')}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }

}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <AddScheculeRateItem {...props} navigate={navigate} params={params} />;
}
