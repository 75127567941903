/**Read this
 * Backend connection needed for saving the data
 */

import { Autocomplete, Button, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
// import { getCompany as getCompanyService, addCompany as addCompanyService, editCompany as editCompanyService } from 'src/services/company.service';
import { getLicense as getLicenseService, addLicense as addLicenseService, editLicense as editLicenseService } from "src/services/license.service";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import Iconify from "src/components/iconify";

class DownloadSr extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.params?.license_id ? 'edit' : 'add',
      license_id: props.params?.license_id,
      state: '',
      department: '',
      year: '',
      category: '',
      license_start_date: null,
      license_end_date: null,
      error: {},
      loading: false,
      companies: []
    }
  }

  async componentDidMount() {

    if (this.state.mode == 'edit') {
      await this.getLicense();
    }
  }

  async getLicense() {
    let response = await getLicenseService(this.state.license_id);
    if (response && response.error) {
      Swal.fire({
        icon: 'error',
        title: i18n.t('error'),
        text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
        confirmButtonText: i18n.t('ok'),
        confirmButtonColor: config.primaryColor
      })
      return;
    }
    if (response && response.license) {
      let license = response.license;

      await this.setState({
        name: license?.company ? license.company : '',
        status: license?.status ? license.status : '',
        module: license?.module ? license.module : '',
        license_start_date: license?.license_start_date ? moment(license.license_start_date) : null,
        license_end_date: license?.license_end_date ? moment(license.license_end_date) : null
      })
    }
  }

  /**Function to save license
   * @returns Saves the license to the backend
   */
  async saveLicense() {

    this.setState({
      loading: true
    })

    let valid = await this.isFormValid();
    if (!valid) {
      this.setState({
        loading: false
      })
      return;
    }

    let payload = {

      status: this.state.status,
      license_start_date: this.state.license_start_date,
      license_end_date: this.state.license_end_date,
    }

    if (this.state.mode == 'add') {
      let response = await addLicenseService(payload);
      if (response && response.error) {
        Swal.fire({
          icon: 'error',
          title: i18n.t('error'),
          text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
          confirmButtonText: i18n.t('ok'),
          confirmButtonColor: config.primaryColor
        })
        this.setState({
          loading: false
        })
        return;
      }
      if (response && response.company) {
        this.setState({
          loading: false
        })
        this.props.navigate('/' + localStorage.getItem('role') + '/licenses');
      }
    } else {
      let response = await editLicenseService(this.state.license_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: 'error',
          title: i18n.t('error'),
          text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
          confirmButtonText: i18n.t('ok'),
          confirmButtonColor: config.primaryColor
        })
        this.setState({
          loading: false
        })
        return;
      }

      if (response && response.license) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: 'bottom-end',
          icon: 'success',
          title: i18n.t('success'),
          text: i18n.t('license_updated_successfully'),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true
        })
        this.setState({
          loading: false
        })
        this.getLicense();
      }
    }

  }

  async isFormValid() {
    let error = await this.validateField();
    console.log("Error", error)
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != '') {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }

  async validateField(field = null) {

    let error = this.state.error;

    // company validation
    if (field == null || field == 'state') {
      if (!this.state.state || this.state.state === '') {
        error.state = i18n.t('this_field_is_required');
      } else {
        error.state = '';
      }
    }

    // department validation
    if (field == null || field == 'department') {
      if (!this.state.department || this.state.department == '') {
        error.department = i18n.t('this_field_is_required');
      } else {
        error.department = '';
      }
    }

    // year validation
    if (field == null || field == 'year') {
      if (!this.state.year || this.state.year == '') {
        error.year = i18n.t('this_field_is_required');
      } else {
        error.year = '';
      }
    }

    // category validation
    if (field == null || field == 'category') {
      if (!this.state.category || this.state.category == '') {
        error.category = i18n.t('this_field_is_required');
      } else {
        error.category = '';
      }
    }

    this.setState({
      error: error
    })

    return error;
  }

  render() {
    return (
      <>

        <Helmet>
          <title> {i18n.t('download_sr')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <Container maxWidth="100%">

          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {this.state.mode == 'add' ? i18n.t('download_sr') : i18n.t('edit_license')}
            </Typography>
          </Stack>

          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth>
                <Autocomplete
                  value={this.state.state ? this.state.state : null}
                  onChange={async (e, newValue) => {
                    await this.setState({
                      state: newValue
                    })
                    this.validateField('state');
                  }}
                  options={["State 1", "State 2", "State 3"]}
                  renderInput={(params) => <TextField {...params} label={i18n.t('state')} error={!!this.state?.error?.state} />}
                // getOptionLabel={(option) => {
                //     if (option?.label)
                //         return option.label;
                // }}
                // disabled={(this.state.mode == 'edit') ? true : false}
                />
                <FormHelperText error={!!this.state?.error?.state}>{this.state?.error?.state}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth>
                <Autocomplete
                  value={this.state.department ? this.state.department : null}
                  onChange={async (e, newValue) => {
                    await this.setState({
                      department: newValue
                    })
                    this.validateField('department');
                  }}
                  options={["Department 1", "Department 2", "Department 3"]}
                  renderInput={(params) => <TextField {...params} label={i18n.t('department')} error={!!this.state?.error?.department} />}
                // getOptionLabel={(option) => {
                //     if (option?.label)
                //         return option.label;
                // }}
                // disabled={(this.state.mode == 'edit') ? true : false}
                />
                <FormHelperText error={!!this.state?.error?.department}>{this.state?.error?.department}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>


          <Grid container spacing={1} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth>

                <Autocomplete
                  value={this.state.year ? this.state.year : null}
                  onChange={async (e, newValue) => {
                    await this.setState({
                      year: newValue
                    })
                    this.validateField('year');
                  }}
                  options={["Year 1", "Year 2", "Year 3"]}
                  renderInput={(params) => <TextField {...params} label={i18n.t('year')} error={!!this.state?.error?.year} />}
                // getOptionLabel={(option) => {
                //   if (option?.label)
                //     return option.label;
                // }}
                // disabled={(this.state.mode == 'edit') ? true : false}
                />
                <FormHelperText error={!!this.state?.error?.year}>{this.state?.error?.year}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth>

                <Autocomplete
                  value={this.state.category ? this.state.category : null}
                  onChange={async (e, newValue) => {
                    await this.setState({
                      category: newValue
                    })
                    this.validateField('category');
                  }}
                  options={["Category 1", "Category 2", "Category 3"]}
                  renderInput={(params) => <TextField {...params} label={i18n.t('category')} error={!!this.state?.error?.category} />}
                // getOptionLabel={(option) => {
                //   if (option?.label)
                //     return option.label;
                // }}
                // disabled={(this.state.mode == 'edit') ? true : false}
                />
                <FormHelperText error={!!this.state?.error?.category}>{this.state?.error?.category}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={1} sx={{ mt: 3 }}>
            <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
            <Stack direction="row" alignItems="center" justifyContent={{ xs: "center" }} flexWrap="wrap" gap={{ xs: 1, sm: 2, md: 5 }} >
              <Button
                variant="contained"
                color='secondary'
                startIcon={<Iconify icon="eva:download-fill" />}
                onClick={() => {
                  console.log("Clicked Download SR")
                }}
              >
                {this.state.loading ? i18n.t("downloading") : i18n.t("download_sr")}
              </Button>

              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  this.props.navigate(-1);
                }}
              >
                {i18n.t('back')}
              </Button>
            </Stack>
            </Grid>
          </Grid>

        </Container>
      </>
    );
  }

}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <DownloadSr {...props} navigate={navigate} params={params} />;
}
