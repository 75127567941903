import axios from 'axios'
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getContractors = async (query=null) => {
    try {
        let headers = getRequestHeaders();
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/contractors' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getContractor = async (contractorId) => {
    try {
        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/contractors/' + contractorId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addContractor = async (payload) => {
    try {
        let headers = getRequestHeaders();
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/contractors', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editContractor = async (contractorId, payload) => {
    try {
        let headers = getRequestHeaders();
        let response;
        try {
            response = await axios.patch( config.SERVER_BASE_URL + '/contractors/' + contractorId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteContractor = async (contractorId) => {
    try {
        let headers = getRequestHeaders();
        let response;
        try {
            response = await axios.delete(config.SERVER_BASE_URL + '/contractors/' + contractorId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getContractors,
    getContractor,
    addContractor,
    editContractor,
    deleteContractor
}