/**Read this
 * Backend connection needed
 * Check the navigation to edit areaweightage page
 * Ui warning <tr> as a child because of extra table row is used in the table
 */

import { Helmet } from 'react-helmet-async';
import React from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Box,
  Chip,
  InputAdornment,
  Pagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import i18n from 'src/i18n';
import config from 'src/config';
import { getAreaWeightages } from 'src/services/areaweightage.service';
import { StyledSearch } from 'src/components/searchbar/styles';
import Searchbar from 'src/components/searchbar/Searchbar';
import Swal from 'sweetalert2';
import Label from '../components/label';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { convertMessageCodeToMessage } from 'src/utils/messageCodeToMessage';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sr_no', label: i18n.t('sr_no'), alignRight: false, sortable: true },
  { id: 'area_weightage_category', label: i18n.t('area_weightage_category'), alignRight: false, sortable: true },
  { id: 'area_weightage', label: i18n.t('area_weightage'), alignRight: false, sortable: true },
  { id: '' },
];

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

class AreaWeightage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      areaweightages: [],
      open: null,
      page: 0,
      selected: [],
      searchValue: '',
      limit: 10,
      skip: 0,
      total_areaweightage: 0,
      sortOrder: 'asc',
      sortKey: 'sr_no'
    };
  }

  async componentDidMount() {
    await this.setAreaWeightage();
  }

  async setAreaWeightage() {

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
    };

    if (this.state.searchValue) {
      query.searchKey = 'username,full_name';
      query.searchValue = this.state.searchValue;
    }

    if (this.state.sortKey) {
      query.sortKey = this.state.sortKey;
      query.sortOrder = this.state.sortOrder ? this.state.sortOrder : 'asc';
    }

    let area_weightage = await getAreaWeightages(query);
    if (area_weightage.error) {
      Swal.fire({
        icon: 'error',
        title: i18n.t('error'),
        text: area_weightage.error_code ? convertMessageCodeToMessage(area_weightage.error_code) : area_weightage.error,
        confirmButtonText: i18n.t('ok'),
        confirmButtonColor: config.primaryColor
      });
      return;
    }

    this.setState({
      area_weightage: area_weightage?.area_weightage ? area_weightage.area_weightage : [],
      total_area_weightage: area_weightage?.count ? area_weightage.count : 0
    });
  }

  /**Action button open function
   * @returns Opens Popover with action buttons
   */
  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  /**Action button close function
   * @returns Closes Popover with action buttons
   */
  handleCloseMenu = () => {
    this.setState({ open: null });
  };

  handleRequestSort = async (event, property) => {
    const isAsc = this.state.sortKey === property && this.state.sortOrder === 'asc';
    await this.setState({ skip: 0, sortOrder: isAsc ? 'desc' : 'asc', sortKey: property });
    await this.setAreaWeightage();
  };

  // handleClick = (event, name) => {
  //   const selectedIndex = this.state.selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(this.state.selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(this.state.selected.slice(1));
  //   } else if (selectedIndex === this.state.selected.length - 1) {
  //     newSelected = newSelected.concat(this.state.selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex), this.state.selected.slice(selectedIndex + 1));
  //   }
  //   // setSelected(newSelected);
  //   this.setState({ selected: newSelected });
  // };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  render() {

    return (
      <>
        <Helmet>
          <title> {i18n.t('area_weightage')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <Container maxWidth="100%">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {i18n.t('area_weightage')}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => {
                this.props.navigate('add');
              }}
            >
              {i18n.t('add_area_weightage')}
            </Button>
          </Stack>

          <Card>

            <Searchbar
              placeholder={i18n.t('search_areaweightage')}
              style={{ margin: 20 }}
              // sx={{ mb: 2 }}
              onChange={async (event) => {
                await this.setState({
                  skip: 0,
                  searchValue: event.target.value
                });
                await this.setAreaWeightage();
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? 'right' : 'left'}
                          sortDirection={this.state.sortKey === headCell.id ? this.state.sortOrder : false}
                        >
                          {(headCell.sortable) ? (
                            <TableSortLabel
                              hideSortIcon={headCell.sortable ? false : true}
                              active={this.state.sortKey === headCell.id}
                              direction={this.state.sortKey === headCell.id ? this.state.sortOrder : 'asc'}
                              onClick={this.createSortHandler(headCell.id)}
                            >
                              {headCell.label}
                              {(this.state.sortKey === headCell.id && headCell.sortable) ? (
                                <Box sx={{ ...visuallyHidden }}>{this.state.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <Typography variant="subtitle2">{headCell.label}</Typography>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.areaweightages?.map((row) => {
                      const { id, sr_no, area_weightage_category, area_weightage } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>

                          <TableCell align="left">{sr_no}</TableCell>

                          <TableCell align="left">{area_weightage_category}</TableCell>

                          <TableCell align="left">{area_weightage}</TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit"
                              onClick={(e) => {
                                this.setState({
                                  currentUser: row
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  <TableRow hover tabIndex={-1}>

                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Stack direction="column" spacing={0} sx={{ typography: 'body2' }}>
                          <Typography variant="subtitle2">
                            ui test
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>

                    <TableCell align="left">ui test</TableCell>

                    <TableCell align="left">ui test</TableCell>

                    <TableCell align="right">
                      <IconButton size="large" color="inherit"
                        onClick={(e) => {
                          this.setState({
                            currentCompany: ""
                          });
                          this.handleOpenMenu(e);
                        }}
                      >
                        <Iconify icon={'eva:more-vertical-fill'} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Stack spacing={2} sx={{ alignItems: 'center' }}>
              <Pagination
                count={Math.ceil(this.state.total_areaweightage / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  await this.setState({
                    skip: (page - 1) * this.state.limit,
                  });
                  await this.setAreaWeightage();
                }}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Stack>

          </Card>
        </Container>

        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate('/' + localStorage.getItem('role') + '/areaweightage/' + this.state.currentUser.id);
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {i18n.t('edit')}
          </MenuItem>

          {/* <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}
        </Popover>

      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();

  return <AreaWeightage {...props} navigate={navigate} />;
}
