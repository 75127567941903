import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Collapse, List, ListItemText } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(false);

  const { pathname } = useLocation();
  const isActive = (path) => pathname.startsWith(path);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    // Close dropdown if the current route is not part of the child routes
    if (children && !children.some((child) => isActive(child.path))) {
      setOpen(false);
    }
  }, [pathname, children]);

  if (children) {

    const isParentActive = children.some((child) => isActive(child.path));

    return (
      <>
        <StyledNavItem
          component={RouterLink}
          onClick={handleToggle}
          sx={open || isParentActive && {
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {open || isParentActive ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </StyledNavItem>
        <Collapse in={open || isParentActive} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child) => (
              <StyledNavItem
                key={child.title}
                component={RouterLink}
                to={child.path}
                sx={{
                  pl: 2,
                  '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                  },
                  mx: 1,
                  my: 0.5
                }}
              >

                <StyledNavItemIcon>{child.icon && child.icon}</StyledNavItemIcon>

                <ListItemText disableTypography primary={child.title} />

              </StyledNavItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
