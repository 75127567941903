import { Autocomplete, Button, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getContractor as getContractorService, addContractor as addContractorService, editContractor as editContractorService } from 'src/services/contractor.service';
import Swal from "sweetalert2";
import { getContractorClassesForSelection } from "src/services/common.service";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import { getBankDetailsFromIFSC } from "src/services/razorpay.service";
import { isGstValidByRegex, isIfscValidByRegex, isPanValidByRegex } from "src/services/validators.service";

class AddEditContractor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.cont_id ? 'edit' : 'add',
            cont_id: props.params?.cont_id,
            contractor_classes: [],
            contractor_id: '',
            contractor_reg_no: '',
            class_of_contractor: null,
            full_name: '',
            email: '',
            phone_number: '',
            address: '',
            pan_number: '',
            gst_number: '',
            ifsc_code: '',
            bank_email: '',
            account_number: '',
            bank_name: '',
            bank_address: '',
            bank_std_code: '',
            bank_phone_number: '',
            status: 'active',
            error: {},
            loading: false
        }
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {

        await this.setState({
            contractor_classes: getContractorClassesForSelection()
        })

        if (this.state.mode == 'edit') {
            await this.getContractor();
        }
    }

    async getContractor() {
        let response = await getContractorService(this.state.cont_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.contractor) {
            let contractor = response.contractor;

            await this.setState({
                contractor_id: contractor?.contractor_id ? contractor.contractor_id : '',
                contractor_reg_no: contractor?.contractor_reg_no ? contractor.contractor_reg_no : '',
                class_of_contractor: contractor?.class_of_contractor ? { id: contractor.class_of_contractor, label: contractor.class_of_contractor } : null,
                full_name: contractor?.full_name ? contractor.full_name : '',
                email: contractor?.email ? contractor.email : '',
                phone_number: contractor?.phone_number ? contractor.phone_number : '',
                address: contractor?.address ? contractor.address : '',
                pan_number: contractor?.pan_number ? contractor.pan_number : '',
                gst_number: contractor?.gst_number ? contractor.gst_number : '',
                ifsc_code: contractor?.ifsc_code ? contractor.ifsc_code : '',
                bank_email: contractor?.bank_email ? contractor.bank_email : '',
                account_number: contractor?.account_number ? contractor.account_number : '',
                bank_name: contractor?.bank_name ? contractor.bank_name : '',
                bank_address: contractor?.bank_address ? contractor.bank_address : '',
                bank_std_code: contractor?.bank_std_code ? contractor.bank_std_code : '',
                bank_phone_number: contractor?.bank_phone_number ? contractor.bank_phone_number : '',
                status: contractor?.status ? contractor.status : ''
            })
        }
    }

    async saveContractor() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {};

        payload.contractor_id = this.state.contractor_id;
        payload.contractor_reg_no = this.state.contractor_reg_no;
        payload.class_of_contractor = this.state.class_of_contractor?.id ? this.state.class_of_contractor.id : null;
        payload.full_name = this.state.full_name;
        payload.email = this.state.email;
        payload.phone_number = this.state.phone_number;
        payload.address = this.state.address;
        payload.pan_number = this.state.pan_number;
        payload.gst_number = this.state.gst_number;
        payload.ifsc_code = this.state.ifsc_code;
        payload.bank_email = this.state.bank_email;
        payload.account_number = this.state.account_number;
        payload.bank_name = this.state.bank_name;
        payload.bank_address = this.state.bank_address;
        payload.bank_std_code = this.state.bank_std_code;
        payload.bank_phone_number = this.state.bank_phone_number;
        payload.status = this.state.status;

        if (this.state.mode == 'add') {

            let response = await addContractorService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.contractor) {
                this.setState({
                    loading: false
                })
                this.props.navigate( '/' + localStorage.getItem('role') + '/mastertables/contractors');
            }
        } else {

            let response = await editContractorService(this.state.cont_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.contractor) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('contractor_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getContractor();
            }
        }

    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'contractor_id') {
            if (!this.state.contractor_id || this.state.contractor_id == '') {
                error.contractor_id = i18n.t('this_field_is_required');
            } else {
                error.contractor_id = '';
            }
        }

        if (field == null || field == 'contractor_reg_no') {
            if (!this.state.contractor_reg_no || this.state.contractor_reg_no == '') {
                error.contractor_reg_no = i18n.t('this_field_is_required');
            } else {
                error.contractor_reg_no = '';
            }
        }

        if (field == null || field == 'class_of_contractor') {
            if (!this.state.class_of_contractor || this.state.class_of_contractor == '' || !this.state.class_of_contractor?.id) {
                error.class_of_contractor = i18n.t('this_field_is_required');
            } else {
                error.class_of_contractor = '';
            }
        }

        if (field == null || field == 'full_name') {
            if (!this.state.full_name || this.state.full_name == '') {
                error.full_name = i18n.t('this_field_is_required');
            } else {
                error.full_name = '';
            }
        }

        if (field == null || field == 'pan_number') {
            if (!this.state.pan_number || this.state.pan_number == '') {
                error.pan_number = i18n.t('this_field_is_required');
            } else {
                if (!isPanValidByRegex(this.state.pan_number)) {
                    error.pan_number = i18n.t('invalid_pan_number');
                } else {
                    error.pan_number = '';
                }
            }
        }

        if (field == null || field == 'gst_number') {
            if (!this.state.gst_number || this.state.gst_number == '') {
                error.gst_number = i18n.t('this_field_is_required');
            } else {
                if (!isGstValidByRegex(this.state.gst_number)) {
                    error.gst_number = i18n.t('invalid_gst_number');
                } else {
                    error.gst_number = '';
                }
            }
        }

        if (field == null || field == 'ifsc_code') {
            if (!this.state.ifsc_code || this.state.ifsc_code == '') {
                error.ifsc_code = i18n.t('this_field_is_required');
            } else {
                if (!isIfscValidByRegex(this.state.ifsc_code)) {
                    error.ifsc_code = i18n.t('invalid_ifsc_code');
                } else {
                    let response = await getBankDetailsFromIFSC(this.state.ifsc_code);
                    if (response == "Not Found") {
                        let error = this.state.error;
                        error.ifsc_code = i18n.t('invalid_ifsc_code');
                        this.setState({
                            error: error
                        })
                    } else {
                        error.ifsc_code = '';
                    }
                }
            }
        }

        if (field == null || field == 'account_number') {
            if (!this.state.account_number || this.state.account_number == '') {
                error.account_number = i18n.t('this_field_is_required');
            } else {
                error.account_number = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    setBankDetailsFromIfscCode = async (ifsc_code) => {
        if (isIfscValidByRegex(ifsc_code)) {
            let response = await getBankDetailsFromIFSC(ifsc_code);
            if (response == "Not Found") {
                let error = this.state.error;
                error.ifsc_code = i18n.t('invalid_ifsc_code');
                this.setState({
                    error: error
                })
            } else {
                let error = this.state.error;
                error.ifsc_code = '';
                this.setState({
                    bank_name: response?.BANK ? response.BANK : '',
                    bank_std_code: response?.STDCODE ? response.STDCODE : '',
                    bank_phone_number: response?.CONTACT ? response.CONTACT : '',
                    bank_address: response?.ADDRESS ? response.ADDRESS : ''
                })
            }
        }
    }


    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_contractor') : i18n.t('edit_contractor')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_contractor') : i18n.t('edit_contractor')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="contractor_id"
                                label={i18n.t('contractor_id')}
                                fullWidth
                                value={this.state.contractor_id}
                                onChange={async (e) => {
                                    await this.setState({
                                        contractor_id: e.target.value
                                    })
                                    this.validateField('contractor_id');
                                }}
                                error={!!this.state?.error?.contractor_id}
                                helperText={this.state?.error?.contractor_id}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="contractor_reg_no"
                                label={i18n.t('contractor_reg_no')}
                                fullWidth
                                value={this.state.contractor_reg_no}
                                onChange={async (e) => {
                                    await this.setState({
                                        contractor_reg_no: e.target.value
                                    })
                                    this.validateField('contractor_reg_no');
                                }}
                                error={!!this.state?.error?.contractor_reg_no}
                                helperText={this.state?.error?.contractor_reg_no}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.class_of_contractor ? this.state.class_of_contractor : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            class_of_contractor: newValue
                                        })
                                        this.validateField('class_of_contractor');
                                    }}
                                    options={this.state.contractor_classes}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('class_of_contractor')} error={!!this.state?.error?.class_of_contractor} />}
                                    getOptionLabel={(option) => {
                                        if (option?.label)
                                            return option.label;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.class_of_contractor}>{this.state?.error?.class_of_contractor}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="full_name"
                                label={i18n.t('full_name')}
                                fullWidth
                                value={this.state.full_name}
                                onChange={async (e) => {
                                    await this.setState({
                                        full_name: e.target.value
                                    })
                                    this.validateField('full_name');
                                }}
                                error={!!this.state?.error?.full_name}
                                helperText={this.state?.error?.full_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="email"
                                type="email"
                                label={i18n.t('email')}
                                fullWidth
                                value={this.state.email}
                                onChange={async (e) => {
                                    await this.setState({
                                        email: e.target.value
                                    })
                                    this.validateField('email');
                                }}
                                error={!!this.state?.error?.email}
                                helperText={this.state?.error?.email}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="phone_number"
                                label={i18n.t('phone_number')}
                                fullWidth
                                value={this.state.phone_number}
                                onChange={async (e) => {
                                    await this.setState({
                                        phone_number: e.target.value
                                    })
                                    this.validateField('phone_number');
                                }}
                                error={!!this.state?.error?.phone_number}
                                helperText={this.state?.error?.phone_number}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                name="address"
                                label={i18n.t('address')}
                                fullWidth
                                rows={4}
                                multiline
                                value={this.state.address}
                                onChange={async (e) => {
                                    await this.setState({
                                        address: e.target.value
                                    })
                                    this.validateField('address');
                                }}
                                error={!!this.state?.error?.address}
                                helperText={this.state?.error?.address}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                name="pan_number"
                                label={i18n.t('pan_number')}
                                fullWidth
                                value={this.state.pan_number}
                                onChange={async (e) => {
                                    await this.setState({
                                        pan_number: e.target.value?.toUpperCase()
                                    })
                                    this.validateField('pan_number');
                                }}
                                error={!!this.state?.error?.pan_number}
                                helperText={this.state?.error?.pan_number}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                name="gst_number"
                                label={i18n.t('gst_number')}
                                fullWidth
                                value={this.state.gst_number}
                                onChange={async (e) => {
                                    await this.setState({
                                        gst_number: e.target.value?.toUpperCase()
                                    })
                                    this.validateField('gst_number');
                                }}
                                error={!!this.state?.error?.gst_number}
                                helperText={this.state?.error?.gst_number}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h6" gutterBottom>
                                {i18n.t('bank_details')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="ifsc_code"
                                label={i18n.t('ifsc_code')}
                                fullWidth
                                value={this.state.ifsc_code}
                                onChange={async (e) => {
                                    await this.setState({
                                        ifsc_code: e.target.value?.toUpperCase()
                                    })
                                    let isIfscValid = await this.validateField('ifsc_code');
                                    if(!!isIfscValid) {
                                        await this.setBankDetailsFromIfscCode(e.target.value?.toUpperCase());
                                    }
                                }}
                                error={!!this.state?.error?.ifsc_code}
                                helperText={this.state?.error?.ifsc_code}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="bank_email"
                                label={i18n.t('bank_email')}
                                fullWidth
                                value={this.state.bank_email}
                                onChange={async (e) => {
                                    await this.setState({
                                        bank_email: e.target.value
                                    })
                                    this.validateField('bank_email');
                                }}
                                error={!!this.state?.error?.bank_email}
                                helperText={this.state?.error?.bank_email}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="account_number"
                                label={i18n.t('account_number')}
                                fullWidth
                                value={this.state.account_number}
                                onChange={async (e) => {
                                    await this.setState({
                                        account_number: e.target.value
                                    })
                                    this.validateField('account_number');
                                }}
                                error={!!this.state?.error?.account_number}
                                helperText={this.state?.error?.account_number}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="bank_name"
                                label={i18n.t('bank_name')}
                                fullWidth
                                value={this.state.bank_name}
                                onChange={async (e) => {
                                    await this.setState({
                                        bank_name: e.target.value
                                    })
                                    this.validateField('bank_name');
                                }}
                                error={!!this.state?.error?.bank_name}
                                helperText={this.state?.error?.bank_name}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="bank_std_code"
                                label={i18n.t('bank_std_code')}
                                fullWidth
                                value={this.state.bank_std_code}
                                onChange={async (e) => {
                                    await this.setState({
                                        bank_std_code: e.target.value
                                    })
                                    this.validateField('bank_std_code');
                                }}
                                error={!!this.state?.error?.bank_std_code}
                                helperText={this.state?.error?.bank_std_code}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="bank_phone_number"
                                label={i18n.t('bank_phone_number')}
                                fullWidth
                                value={this.state.bank_phone_number}
                                onChange={async (e) => {
                                    await this.setState({
                                        bank_phone_number: e.target.value
                                    })
                                    this.validateField('bank_phone_number');
                                }}
                                error={!!this.state?.error?.bank_phone_number}
                                helperText={this.state?.error?.bank_phone_number}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                name="bank_address"
                                label={i18n.t('bank_address')}
                                fullWidth
                                rows={4}
                                multiline
                                value={this.state.bank_address}
                                onChange={async (e) => {
                                    await this.setState({
                                        bank_address: e.target.value
                                    })
                                    this.validateField('bank_address');
                                }}
                                error={!!this.state?.error?.bank_address}
                                helperText={this.state?.error?.bank_address}
                                disabled
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>{i18n.t('inactive')}</Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={(this.state.status == 'active') ? true : false}
                                            label={i18n.t('status')}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    status: e.target.checked ? 'active' : 'inactive'
                                                })
                                            }}
                                        />
                                    }
                                    label={i18n.t('status')}
                                    labelPlacement="bottom"
                                />
                                <Typography>{i18n.t('active')}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveContractor();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
    const params = useParams();
  
    return <AddEditContractor {...props} navigate={navigate} params={params} />;
}
