/**Read this
 * Only UI is completed
 * Backend connection needed for saving the data
 * Check edit location page once its SUS
 */

import { Autocomplete, Button, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getLocation as getLocationService, addLocation as addLocationService, editLocation as editLocationService } from "src/services/location.service";
import Swal from "sweetalert2";
import { getRolesForSelection } from "src/services/common.service";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import Iconify from "src/components/iconify";
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';

class AddEditLocation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.location_id ? 'edit' : 'add',
            location_id: props.params?.location_id,
            location_type: 'Urban',
            state: '',
            district: '',
            sub_district: '',
            city: '',
            village: '',
            ward_name: '',
            ward_number: '',
            error: {},
            loading: false,
            companies: []
        }
    }

    async componentDidMount() {

        if (this.state.mode == 'edit') {
            await this.getLocation();
        }
    }

    async getLocation() {
        let response = await getLocationService(this.state.location_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.location) {
            let location = response.location;

            await this.setState({
                state: location?.state ? location.state : '',
                district: location?.district ? location.district : '',
                sub_district: location?.sub_district ? location.sub_district : '',
                city: location?.city ? location.city : '',
                village: location?.village ? location.village : '',
                ward_name: location?.ward_name ? location.village : '',
                ward_number: location?.ward_number ? location.village : '',
            })
        }
    }

    /**Function to save license
     * @returns Saves the locations to the backend
     */
    async saveLocation() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {
            state: this.state.this.state,
            district: this.state.district,
            sub_district: this.state.sub_district,
            city: this.state.city,
            village: this.state.village,
            ward_name: this.state.ward_name,
            ward_number: this.state.ward_number,
        }

        if (this.state.mode == 'add') {
            let response = await addLocationService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.company) {
                this.setState({
                    loading: false
                })
                this.props.navigate('/' + localStorage.getItem('role') + '/locations');
            }
        } else {
            let response = await editLocationService(this.state.location_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.license) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('location_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getLocation();
            }
        }

        // console.log(this.state.state);
        // console.log(this.state.district);
        // console.log(this.state.sub_district);
        // console.log(this.state.city);
        // console.log(this.state.village);
        // console.log(this.state.ward_name);
        // console.log(this.state.ward_number);
    }

    async isFormValid() {
        let error = await this.validateField();
        console.log("Error", error)
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field = null) {

        let error = this.state.error;

        // state validation
        if (field == null || field == 'state') {
            if (!this.state.state || this.state.state === '') {
                error.state = i18n.t('this_field_is_required');
            } else {
                error.state = '';
            }
        }

        // district validation
        if (field == null || field == 'district') {
            if (!this.state.district || this.state.district == '') {
                error.district = i18n.t('this_field_is_required');
            } else {
                error.district = '';
            }
        }

        // sub_district validation
        if (field == null || field == 'sub_district') {
            if (!this.state.sub_district || this.state.sub_district == '') {
                error.sub_district = i18n.t('this_field_is_required');
            } else {
                error.sub_district = '';
            }
        }

        // city validation
        if (field == null || field == 'city') {
            if (!this.state.city || this.state.city == '') {
                error.city = i18n.t('this_field_is_required');
            } else {
                error.city = '';
            }
        }

        // village validation
        if (field == null || field == 'village') {
            if (!this.state.village || this.state.village == '') {
                error.village = i18n.t('this_field_is_required');
            } else {
                error.village = '';
            }
        }

        // ward_name validation
        if (field == null || field == 'ward_name') {
            if (!this.state.ward_name || this.state.ward_name == '') {
                error.ward_name = i18n.t('this_field_is_required');
            } else {
                error.ward_name = '';
            }
        }

        // ward_number validation
        if (field == null || field == 'ward_number') {
            if (!this.state.ward_number || this.state.ward_number == '') {
                error.ward_number = i18n.t('this_field_is_required');
            } else {
                error.ward_number = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_location') : i18n.t('edit_location')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_location') : i18n.t('edit_location')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>

                        <Grid item xs={12} md={6} lg={2}>
                            <Autocomplete
                                value={this.state.location_type || "Urban"}
                                onChange={async (e, newValue) => {
                                    await this.setState({
                                        location_type: newValue
                                    })
                                }}
                                options={["Urban", "Rural"]}
                                renderInput={(params) => <TextField {...params} label="Location Type" />}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4} sx={{ mt: 3 }}>
                            <Autocomplete
                                fullWidth
                                value={this.state.state ? this.state.state : null}
                                onChange={async (e, newValue) => {
                                    await this.setState({
                                        state: newValue
                                    })
                                    this.validateField('state');
                                }}
                                options={["State 1", "State 2", "State 3"]}
                                renderInput={(params) => <TextField {...params} label={i18n.t('state')} error={!!this.state?.error?.state} />}
                            // disabled={(this.state.mode == 'edit') ? true : false}
                            />
                            <FormHelperText error={!!this.state?.error?.state}>{this.state?.error?.state}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4} sx={{ mt: 3 }}>
                            <Autocomplete
                                fullWidth
                                value={this.state.district ? this.state.district : null}
                                onChange={async (e, newValue) => {
                                    await this.setState({
                                        district: newValue
                                    })
                                    this.validateField('district');
                                }}
                                options={["District 1", "District 2", "District 3"]}
                                renderInput={(params) => <TextField {...params} label={i18n.t('district')} error={!!this.state?.error?.district} />}
                            // disabled={(this.state.mode == 'edit') ? true : false}
                            />
                            <FormHelperText error={!!this.state?.error?.district}>{this.state?.error?.district}</FormHelperText>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4} sx={{ mt: 3 }}>
                            <Autocomplete
                                fullWidth
                                value={this.state.sub_district ? this.state.sub_district : null}
                                onChange={async (e, newValue) => {
                                    await this.setState({
                                        sub_district: newValue
                                    })
                                    this.validateField('sub_district');
                                }}
                                options={["Sub District 1", "Sub District 2", "Sub District 3"]}
                                renderInput={(params) => <TextField {...params} label={i18n.t('sub_district')} error={!!this.state?.error?.sub_district} />}
                            // disabled={(this.state.mode == 'edit') ? true : false}
                            />
                            <FormHelperText error={!!this.state?.error?.sub_district}>{this.state?.error?.sub_district}</FormHelperText>
                        </Grid>

                        {
                            this.state.location_type === "Urban" ?
                                (
                                    <Grid item xs={12} md={6} lg={4} sx={{ mt: 3 }}>
                                        <Autocomplete
                                            fullWidth
                                            value={this.state.city ? this.state.city : null}
                                            onChange={async (e, newValue) => {
                                                await this.setState({
                                                    city: newValue
                                                })
                                                this.validateField('city');
                                            }}
                                            options={["City 1", "City 2", "City 3"]}
                                            renderInput={(params) => <TextField {...params} label={i18n.t('city')} error={!!this.state?.error?.city} />}
                                            disabled={(this.state.location_type === 'Urban') ? false : true}
                                        />
                                        <FormHelperText error={!!this.state?.error?.city}>{this.state?.error?.city}</FormHelperText>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} md={6} lg={4} sx={{ mt: 3 }}>
                                        <Autocomplete
                                            fullWidth
                                            value={this.state.village ? this.state.village : null}
                                            onChange={async (e, newValue) => {
                                                await this.setState({
                                                    village: newValue
                                                })
                                                this.validateField('village');
                                            }}
                                            options={["Village 1", "Village 2", "Village 3"]}
                                            renderInput={(params) => <TextField {...params} label={i18n.t('village')} error={!!this.state?.error?.village} />}
                                            disabled={(this.state.location_type !== 'Urban') ? false : true}
                                        />
                                        <FormHelperText error={!!this.state?.error?.village}>{this.state?.error?.village}</FormHelperText>
                                    </Grid>
                                )
                        }

                    </Grid>

                    {
                        this.state.location_type === "Urban" &&
                        (
                            <Grid container spacing={1}>

                                <Grid item xs={12} md={6} lg={4} sx={{ mt: 3 }}>
                                    <Autocomplete
                                        fullWidth
                                        value={this.state.ward_name ? this.state.ward_name : null}
                                        onChange={async (e, newValue) => {
                                            await this.setState({
                                                ward_name: newValue
                                            })
                                            this.validateField('ward_name');
                                        }}
                                        options={["Ward Name 1", "Ward Name 2", "Ward Name 3"]}
                                        renderInput={(params) => <TextField {...params} label={i18n.t('ward_name')} error={!!this.state?.error?.ward_name} />}
                                        disabled={(this.state.location_type !== 'Urban') ? true : false}

                                    />
                                    <FormHelperText error={!!this.state?.error?.ward_name}>{this.state?.error?.ward_name}</FormHelperText>
                                </Grid>

                                <Grid item xs={12} md={6} lg={4} sx={{ mt: 3 }}>
                                    <Autocomplete
                                        fullWidth
                                        value={this.state.ward_number ? this.state.ward_number : null}
                                        onChange={async (e, newValue) => {
                                            await this.setState({
                                                ward_number: newValue
                                            })
                                            this.validateField('ward_number');
                                        }}
                                        options={["WArd Number 1", "WArd Number 2", "WArd Number 3"]}
                                        renderInput={(params) => <TextField {...params} label={i18n.t('ward_number')} error={!!this.state?.error?.ward_number} />}
                                        disabled={(this.state.location_type !== 'Urban') ? true : false}

                                    />
                                    <FormHelperText error={!!this.state?.error?.ward_number}>{this.state?.error?.ward_number}</FormHelperText>
                                </Grid>
                            </Grid>
                        )
                    }



                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveLocation();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <AddEditLocation {...props} navigate={navigate} params={params} />;
}
