import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getWorkSchedule = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/work_schedule' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const upsertWorkSchedule = async (payload) => {
    try {
        let headers = getRequestHeaders();
        let response;
        try {
            response = await axios.put( config.SERVER_BASE_URL + '/work_schedule', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getEventsOfWorkSchedule = async (query=null) => {
    try {
        let headers = getRequestHeaders();
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/work_schedule/events' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getWorkScheduleFinancialYears = async (query=null) => {
    try {
        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/work_schedule/dropdowns/financial_year' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getWorkScheduleIndentNos = async (query=null) => {
    try {
        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/work_schedule/dropdowns/indent_no' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getWorkSchedule,
    upsertWorkSchedule,
    getEventsOfWorkSchedule,
    getWorkScheduleFinancialYears,
    getWorkScheduleIndentNos
}