/**Read this
 * here sr_no must be auto genrated
 * Backend connection needed for saving the data
 */

import { Autocomplete, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getRole as getRoleService, addRole as addRoleService, editRole as editRoleServies } from "src/services/roles.service";
import Swal from "sweetalert2";
// import { getRolesForSelection } from "src/services/common.service";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import moment from "moment";
// mui
import { teal } from '@mui/material/colors';
import { Label } from "@mui/icons-material";

class AddEditRoles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.role_id ? 'edit' : 'add',
            role_id: props.params?.role_id,
            users: '',
            amount_limit: '',
            remark: '',
            error: {},
            loading: false,
        }
    }

    async componentDidMount() {

        if (this.state.mode == 'edit') {
            await this.getRole();
        }
    }

    async getRole() {
        let response = await getRoleService(this.state.role_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.role) {
            let role = response.role;

            await this.setState({
                users: role?.users ? role.users : '',
                amount_limit: role?.amount_limit ? role.amount_limit : '',
                remark: role?.remark ? role.remark : '',
            })
        }
    }

    /**Function to save roles
     * @returns Saves the role to the backend
     */
    async saveRoles() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {
            users: this.state.users,
            amount_limit: this.state.amount_limit,
            remark: this.state.remark,
        }

        if (this.state.mode == 'add') {
            let response = await addRoleService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.company) {
                this.setState({
                    loading: false
                })
                this.props.navigate('/' + localStorage.getItem('role') + '/mastertables/roles');
            }
        } else {
            let response = await editRoleServies(this.state.role_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.units) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('units_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getRole();
            }
        }
    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field = null) {

        let error = this.state.error;

        // users validation
        if (field == null || field == 'users') {
            if (!this.state.users || this.state.users == '') {
                error.users = i18n.t('this_field_is_required');
            } else {
                error.users = '';
            }
        }

        // amount_limit validation
        if (field == null || field == 'amount_limit') {
            if (!this.state.amount_limit || this.state.amount_limit == '') {
                error.amount_limit = i18n.t('this_field_is_required');
            } else {
                error.amount_limit = '';
            }
        }

        // remark validation
        if (field == null || field == 'remark') {
            if (!this.state.remark || this.state.remark == '') {
                error.remark = i18n.t('this_field_is_required');
            } else {
                error.remark = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_roles') : i18n.t('edit_roles')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_roles') : i18n.t('edit_roles')}
                        </Typography>
                    </Stack>

                    {/* <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="sr_no"
                                label={i18n.t('sr_no')}
                                fullWidth
                                value={this.state.sr_no}
                                onChange={async (e) => {
                                    await this.setState({
                                        sr_no: e.target.value
                                    })
                                    this.validateField('sr_no');
                                }}
                                error={!!this.state?.error?.sr_no}
                                helperText={this.state?.error?.sr_no}
                                disabled={(this.state.mode == 'edit') ? true : false}
                            />
                        </Grid>
                    </Grid> */}

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.users ? this.state.users : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            users: newValue
                                        })
                                        this.validateField('users');
                                    }}
                                    options={["User 1", "User 2", "User 3"]}
                                    renderInput={(params) => <TextField {...params} label={i18n.t("users")} error={!!this.state?.error?.users} />}
                                // disabled={(this.state.mode == 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.users}>{this.state?.error?.users}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="amount_limit"
                                label={i18n.t('amount_limit')}
                                fullWidth
                                value={this.state.amount_limit}
                                onChange={async (e) => {
                                    await this.setState({
                                        amount_limit: e.target.value
                                    })
                                    this.validateField('amount_limit');
                                }}
                                error={!!this.state?.error?.amount_limit}
                                helperText={this.state?.error?.amount_limit}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="remark"
                                label={i18n.t('remark')}
                                fullWidth
                                value={this.state.remark}
                                onChange={async (e) => {
                                    await this.setState({
                                        remark: e.target.value
                                    })
                                    this.validateField('remark');
                                }}
                                error={!!this.state?.error?.remark}
                                helperText={this.state?.error?.remark}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveRoles();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <AddEditRoles {...props} navigate={navigate} params={params} />;
}
