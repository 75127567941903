import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

// const role = localStorage.getItem("role");

const getRoles = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            // response = await axios.get( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/roles" : '/roles'}` + queryString, { headers: headers });
            response = await axios.get( config.SERVER_BASE_URL + '/roles' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getRole = async (roleId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.get( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/roles/" : '/roles/'}` + roleId, { headers: headers });
            response = await axios.get( config.SERVER_BASE_URL + '/roles/' + roleId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addRole = async (payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.post( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/roles" : '/roles'}`, payload, { headers: headers });
            response = await axios.post( config.SERVER_BASE_URL + '/roles', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editRole = async (roleId, payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.patch( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/roles/" : '/roles/' }` + roleId, payload, { headers: headers });
            response = await axios.patch( config.SERVER_BASE_URL + '/roles/' + roleId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}


export {
    getRoles,
    getRole,
    addRole,
    editRole,
}