/**Read this
 * Backend connection needed
 * Check the navigation to edit location page
 * 
 * Bugs:
 * Defalt Table is city when user changes table to village and refreshes the page city table is visible of rural table we can over come that with local storage but is it REQUIRED
 * Ui warning <tr> as a child because of extra table row is used in the table
 */

import { Helmet } from 'react-helmet-async';
import React from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Box,
  Chip,
  InputAdornment,
  Pagination,
  Autocomplete,
  TextField,
  Grid,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import i18n from 'src/i18n';
import config from 'src/config';
import { getLocations } from 'src/services/location.service';
import { StyledSearch } from 'src/components/searchbar/styles';
import Searchbar from 'src/components/searchbar/Searchbar';
import Swal from 'sweetalert2';
import Label from '../components/label';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { convertMessageCodeToMessage } from 'src/utils/messageCodeToMessage';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sr_no', label: i18n.t('sr_no'), alignRight: false, sortable: true },
  { id: 'state', label: i18n.t('state'), alignRight: false, sortable: true },
  { id: 'district', label: i18n.t('district'), alignRight: false, sortable: true },
  { id: 'sub_district', label: i18n.t('sub_district'), alignRight: false, sortable: true },
  { id: 'village', label: i18n.t('village'), alignRight: false, sortable: true },
  { id: 'city', label: i18n.t('city'), alignRight: false, sortable: true },
  { id: 'ward_name', label: i18n.t('ward_name'), alignRight: false, sortable: true },
  { id: 'ward_number', label: i18n.t('ward_number'), alignRight: false, sortable: true },
  { id: '' },
];

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

class Location extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      locations: [],
      location_type: '',
      open: null,
      page: 0,
      selected: [],
      searchValue: '',
      limit: 10,
      skip: 0,
      total_locations: 0,
      sortOrder: 'asc',
      sortKey: 'sr_no'
    };
  }

  async componentDidMount() {
    await this.setUnit();
  }

  async setUnit() {

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
    };

    if (this.state.searchValue) {
      query.searchKey = 'sr_no, ward_name';
      query.searchValue = this.state.searchValue;
    }

    if (this.state.sortKey) {
      query.sortKey = this.state.sortKey;
      query.sortOrder = this.state.sortOrder ? this.state.sortOrder : 'asc';
    }

    let locations = await getLocations(query);
    if (locations.error) {
      Swal.fire({
        icon: 'error',
        title: i18n.t('error'),
        text: locations.error_code ? convertMessageCodeToMessage(locations.error_code) : locations.error,
        confirmButtonText: i18n.t('ok'),
        confirmButtonColor: config.primaryColor
      });
      return;
    }

    this.setState({
      locations: locations?.area_weightage ? locations.area_weightage : [],
      total_locations: locations?.count ? locations.count : 0
    });
  }

  /**Action button open function
   * @returns Opens Popover with action buttons
   */
  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  /**Action button close function
   * @returns Closes Popover with action buttons
   */
  handleCloseMenu = () => {
    this.setState({ open: null });
  };

  handleRequestSort = async (event, property) => {
    const isAsc = this.state.sortKey === property && this.state.sortOrder === 'asc';
    await this.setState({ skip: 0, sortOrder: isAsc ? 'desc' : 'asc', sortKey: property });
    await this.setUnit();
  };

  // handleClick = (event, name) => {
  //   const selectedIndex = this.state.selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(this.state.selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(this.state.selected.slice(1));
  //   } else if (selectedIndex === this.state.selected.length - 1) {
  //     newSelected = newSelected.concat(this.state.selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex), this.state.selected.slice(selectedIndex + 1));
  //   }
  //   // setSelected(newSelected);
  //   this.setState({ selected: newSelected });
  // };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  render() {

    return (
      <>
        <Helmet>
          <title> {i18n.t('location')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <Container maxWidth="100%">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {i18n.t('location')}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent={{xs: "flex-end", sm: "center"}} flexWrap="wrap" gap={{xs: 1, sm: 2, md: 5}} >
              <Button
                variant="contained"
                color="warning"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  this.props.navigate('addbulklocation');
                }}
              >
                {i18n.t('add_bulk_location')}
              </Button>

              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  this.props.navigate('add');
                }}
              >
                {i18n.t('add_location')}
              </Button>
            </Stack>
          </Stack>

          <Card>
            <Grid container spacing={1} sx={{ mt: 3 }} mb={4} justifyContent={'space-around'}>
              <Grid item xs={12} md={6} lg={2}>
                <Autocomplete
                  value={this.state.location_type || "Urban"}
                  onChange={async (e, newValue) => {
                    await this.setState({
                      location_type: newValue
                    })
                  }}
                  options={["Urban", "Rural"]}
                  renderInput={(params) => <TextField {...params} label="Location Type" />}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <Searchbar
                  placeholder={i18n.t('search_location')}
                  onChange={async (event) => {
                    await this.setState({
                      skip: 0,
                      searchValue: event.target.value
                    });
                    await this.setUnit();
                  }}
                />
              </Grid>

            </Grid>


            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.
                        filter(
                          headCell => this.state.location_type === 'Rural' ?    //conditional render for city and village columns
                            headCell.id !== 'city' && headCell.id !== 'ward_name' && headCell.id !== 'ward_number'    //check
                            : headCell.id !== 'village').map((headCell) => (
                              <TableCell
                                key={headCell.id}
                                align={headCell.alignRight ? 'right' : 'left'}
                                sortDirection={this.state.sortKey === headCell.id ? this.state.sortOrder : false}
                              >
                                {headCell.sortable ? (
                                  <TableSortLabel
                                    hideSortIcon={headCell.sortable ? false : true}
                                    active={this.state.sortKey === headCell.id}
                                    direction={this.state.sortKey === headCell.id ? this.state.sortOrder : 'asc'}
                                    onClick={this.createSortHandler(headCell.id)}
                                  >
                                    {headCell.label}
                                    {this.state.sortKey === headCell.id && headCell.sortable ? (
                                      <Box sx={{ ...visuallyHidden }}>{this.state.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                                    ) : null}
                                  </TableSortLabel>
                                ) : (
                                  <Typography variant="subtitle2">{headCell.label}</Typography>
                                )}
                              </TableCell>
                            ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.locations?.map((row) => {
                      const { id, sr_no, state, district, sub_district, village, city, ward_name, ward_number } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>

                          <TableCell align="left">{sr_no}</TableCell>

                          <TableCell align="left">{state}</TableCell>

                          <TableCell align="left">{district}</TableCell>

                          <TableCell align="left">{sub_district}</TableCell>

                          {this.state.location_type === 'Rural' ? <TableCell align="left">{village}</TableCell> : <TableCell align="left">{city}</TableCell>}

                          {this.state.location_type === 'Rural' && <TableCell align="left">{ward_name}</TableCell>}

                          {this.state.location_type === 'Rural' && <TableCell align="left">{ward_number}</TableCell>}

                          <TableCell align="right">
                            <IconButton size="large" color="inherit"
                              onClick={(e) => {
                                this.setState({
                                  currentUser: row
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  {/* logical table rendering for both the tables */}

                  <TableRow hover tabIndex={-1}>

                    {this.state.location_type === "Rural" ?

                      (<TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Stack direction="column" spacing={0} sx={{ typography: 'body2' }}>
                            <Typography variant="subtitle2">
                              Village 1
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>) : (
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Stack direction="column" spacing={0} sx={{ typography: 'body2' }}>
                              <Typography variant="subtitle2">
                                City 1
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>)
                    }

                    {this.state.location_type === "Rural" ? <TableCell align="left">Village State</TableCell> : <TableCell align="left">City State</TableCell>}

                    {this.state.location_type === "Rural" ? <TableCell align="left">Village District</TableCell> : <TableCell align="left">City  District</TableCell>}

                    {this.state.location_type === "Rural" ? <TableCell align="left">Village Sub District</TableCell> : <TableCell align="left">City  Sub District</TableCell>}

                    {this.state.location_type === "Rural" ? <TableCell align="left">Village</TableCell> : <TableCell align="left">City</TableCell>}

                    {this.state.location_type === "Rural" ? null : <TableCell align="left">City  Ward Name</TableCell>}

                    {this.state.location_type === "Rural" ? null : <TableCell align="left">City  Ward Number</TableCell>}

                    <TableCell align="right">
                      <IconButton size="large" color="inherit"
                        onClick={(e) => {
                          this.setState({
                            currentCompany: ""
                          });
                          this.handleOpenMenu(e);
                        }}
                      >
                        <Iconify icon={'eva:more-vertical-fill'} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Stack spacing={2} sx={{ alignItems: 'center' }}>
              <Pagination
                count={Math.ceil(this.state.total_locations / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  await this.setState({
                    skip: (page - 1) * this.state.limit,
                  });
                  await this.setUnit();
                }}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Stack>

          </Card>
        </Container>

        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate( '/' + localStorage.getItem('role') + '/locations/' + this.state.currentUser.id);
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {i18n.t('edit')}
          </MenuItem>

          {/* <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}
        </Popover>

      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();

  return <Location {...props} navigate={navigate} />;
}
