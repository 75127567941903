import { Autocomplete, Button, Checkbox, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getUser as getUserService, addUser as addUserService, editUser as editUserService } from 'src/services/user.service';
import { getCompanies as getCompaniesService } from 'src/services/company.service';
import {
    getStates as getStatesService,
    getZones as getZonesService,
    getCircles as getCirclesService,
    getDivisions as getDivisionsService,
    getDistricts as getDistrictsService,
    getTaluks as getTaluksService
} from "src/services/accessInfo.service";
import Swal from "sweetalert2";
import { getRoleValueFromRole, getRolesForSelection } from "src/services/common.service";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import { getContractors } from "src/services/contractor.service";

class AddEditUser extends React.Component {

    constructor(props) {
        super(props);

        this.role = localStorage.getItem('role');
        let role_keys = Object.keys(config.ROLES);
        this.role_value = config.ROLES[role_keys.find(key => config.ROLES[key].key == this.role)].value;

        this.state = {
            mode: props.params?.user_id ? 'edit' : 'add',
            user_id: props.params?.user_id,
            roles_for_selection: [],
            companies: [],
            states: [],
            zones: [],
            circles: [],
            divisions: [],
            districts: [],
            taluks: [],
            available_contractors: [],
            available_contractors_map: {},
            access_control_keys: ["contractors", "proposals", "tenders", "works", "work_schedule", "physical_progress", "financial_progress", "quality_control"],
            access_control_methods: ["GET", "POST", "PATCH", "DELETE"],
            username: '',
            full_name: '',
            email: '',
            phone_number: '',
            state: [],
            zone: [],
            circle: [],
            division: [],
            district: [],
            taluk: [],
            password: '',
            confirm_password: '',
            company: null,
            role: null,
            status: 'active',
            contractors_access_info: [],
            access_control: {},
            error: {},
            loading: false
        }
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {

        // let role_max_value;
        // let role_min_value;
        // if (this.role == config.ROLES.SUPERADMIN.key) {
        //     role_max_value = config.ROLES.SUPERADMIN.value;
        //     role_min_value = config.ROLES.ADMIN.value;
        // } else if (this.role == config.ROLES.ADMIN.key) {
        //     role_max_value = config.ROLES.ADMIN.value;
        //     role_min_value = config.ROLES.MANAGER.value;
        // } else if (this.role == config.ROLES.MANAGER.key) {
        //     role_max_value = config.ROLES.MANAGER.value;
        //     role_min_value = config.ROLES.USER.value;
        // } else if (this.role == config.ROLES.USER.key) {
        //     role_max_value = config.ROLES.USER.value;
        //     role_min_value = config.ROLES.USER.value;
        // }

        await this.setState({
            roles_for_selection: getRolesForSelection()
        })

        if (this.state.mode == 'add') {
            await this.setStates();
            await this.setCompanies();
        }

        if (this.state.mode == 'edit') {
            await this.getUser();
        }
    }

    async setCompanies() {
        let response = await getCompaniesService({
            minimal: true
        });

        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (response && response.companies) {
            let companies = response.companies;
            companies = companies.map(company => {
                return {
                    id: company.id,
                    label: company.name
                }
            })
            await this.setState({
                companies: companies
            })
        }
    }

    async setStates() {
        let response = await getStatesService();
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.states) {
            let states = response.states;
            states.unshift(i18n.t('all'))

            await this.promisedSetState({
                states: states,
                zones: [],
                circles: [],
                divisions: [],
                districts: [],
                taluks: [],
                state: [],
                zone: [],
                circle: [],
                division: [],
                district: [],
                taluk: []
            })
        }
    }

    async setZones() {

        if (!this.state.state || !this.state.state.length) {
            return;
        }

        let response = await getZonesService({
            state: this.state.state.join(',')
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.zones) {
            let zones = response.zones;
            zones.unshift(i18n.t('all'))

            await this.setState({
                zones: zones,
                circles: [],
                divisions: [],
                districts: [],
                taluks: [],
                zone: [],
                circle: [],
                division: [],
                district: [],
                taluk: []
            })
        }
    }

    async setCircles() {

        if (!this.state.state || !this.state.state.length || !this.state.zone || !this.state.zone.length) {
            this.setState({
                circles: [],
                divisions: [],
                districts: [],
                taluks: [],
                circle: [],
                division: [],
                district: [],
                taluk: []
            })
            return;
        }

        let response = await getCirclesService({
            state: this.state.state.join(','),
            zone: this.state.zone.join(',')
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.circles) {
            let circles = response.circles;
            circles.unshift(i18n.t('all'))

            await this.setState({
                circles: circles,
                divisions: [],
                districts: [],
                taluks: [],
                circle: [],
                division: [],
                district: [],
                taluk: []
            })
        }
    }

    async setDivisions() {

        if (!this.state.state || !this.state.state.length || !this.state.zone || !this.state.zone.length || !this.state.circle || !this.state.circle.length) {
            this.setState({
                divisions: [],
                districts: [],
                taluks: [],
                division: [],
                district: [],
                taluk: []
            })
            return;
        }

        let response = await getDivisionsService({
            state: this.state.state.join(','),
            zone: this.state.zone.join(','),
            circle: this.state.circle.join(',')
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.divisions) {
            let divisions = response.divisions;
            divisions.unshift(i18n.t('all'))

            await this.setState({
                divisions: divisions,
                districts: [],
                taluks: [],
                division: [],
                district: [],
                taluk: []
            })
        }
    }

    async setDistricts() {

        if (!this.state.state || !this.state.state.length || !this.state.zone || !this.state.zone.length || !this.state.circle || !this.state.circle.length || !this.state.division || !this.state.division.length) {
            this.setState({
                districts: [],
                taluks: [],
                district: [],
                taluk: []
            })
            return;
        }

        let response = await getDistrictsService({
            state: this.state.state.join(','),
            zone: this.state.zone.join(','),
            circle: this.state.circle.join(','),
            division: this.state.division.join(',')
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.districts) {
            let districts = response.districts;
            districts.unshift(i18n.t('all'))

            await this.setState({
                districts: districts,
                taluks: [],
                district: [],
                taluk: []
            })
        }
    }

    async setTaluks() {

        if (!this.state.state || !this.state.state.length || !this.state.zone || !this.state.zone.length || !this.state.circle || !this.state.circle.length || !this.state.division || !this.state.division.length || !this.state.district || !this.state.district.length) {
            this.setState({
                taluks: [],
                taluk: []
            })
            return;
        }

        let response = await getTaluksService({
            state: this.state.state.join(','),
            zone: this.state.zone.join(','),
            circle: this.state.circle.join(','),
            division: this.state.division.join(','),
            district: this.state.district.join(',')
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.taluks) {
            let taluks = response.taluks;
            taluks.unshift(i18n.t('all'))

            await this.setState({
                taluks: taluks,
                taluk: []
            })
        }
    }

    async setUserAccessInfo(user) {
        let state = user?.UserAccessInfo?.states ? user.UserAccessInfo.states : [];
        let zone = user?.UserAccessInfo?.zones ? user.UserAccessInfo.zones : [];
        let circle = user?.UserAccessInfo?.circles ? user.UserAccessInfo.circles : [];
        let division = user?.UserAccessInfo?.divisions ? user.UserAccessInfo.divisions : [];
        let district = user?.UserAccessInfo?.districts ? user.UserAccessInfo.districts : [];
        let taluk = user?.UserAccessInfo?.taluks ? user.UserAccessInfo.taluks : [];

        // await this.setStates();
        // await this.promisedSetState({ state: state });
        // await this.setZones();
        // await this.promisedSetState({ zone: zone });
        // await this.setCircles();
        // await this.promisedSetState({ circle: circle });
        // await this.setDivisions();
        // await this.promisedSetState({ division: division });
        // await this.setDistricts();
        // await this.promisedSetState({ district: district });
        // await this.setTaluks();
        // await this.promisedSetState({ taluk: taluk });

        this.setState({
            state: state,
            zone: zone,
            circle: circle,
            division: division,
            district: district,
            taluk: taluk
        })
    }

    async setAvailableContractors(user=null) {

        let contractors_access_info = user?.contractors_access_info ? user.contractors_access_info : [];
        this.setState({
            contractors_access_info: contractors_access_info
        })

        if (this.role == config.ROLES.ADMIN.key && this.state.role?.id == config.ROLES.MANAGER.key) {
            let response = await getContractors({
                minimal: true
            });
            if (response.error) {
                console.log(response.error);
                return;
            }
            if (response && response.contractors) {
                let available_contractors = response.contractors;
                contractors_access_info = contractors_access_info.map(contractor => {
                    let available_contractor = available_contractors.find(available_contractor => available_contractor.id == contractor);
                    if (available_contractor) {
                        return available_contractor.contractor_id + ' - ' + available_contractor.contractor_reg_no + ' - ' + available_contractor.full_name;
                    } else {
                        return contractor
                    }
                })
                let available_contractors_map = {};
                available_contractors = available_contractors.map(contractor => {
                    available_contractors_map[contractor.contractor_id + ' - ' + contractor.contractor_reg_no + ' - ' + contractor.full_name] = contractor.id;
                    return contractor.contractor_id + ' - ' + contractor.contractor_reg_no + ' - ' + contractor.full_name;
                })

                this.setState({available_contractors: available_contractors, contractors_access_info: contractors_access_info, available_contractors_map: available_contractors_map});
            }
            return;
        }
    }


    async getUser() {
        let response = await getUserService(this.state.user_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.user) {
            let user = response.user;

            user.role = this.state.roles_for_selection.find(role => role.id == user.role);

            await this.promisedSetState({
                username: user?.username ? user.username : '',
                full_name: user?.full_name ? user.full_name : '',
                email: user?.email ? user.email : '',
                phone_number: user?.phone_number ? user.phone_number : '',
                company: user?.Company ? { id: user.Company.id, label: user.Company.name } : null,
                role: user?.role ? user.role : null,
                status: user?.status ? user.status : '',
                access_control: user?.access_control ? user.access_control : {}
            })

            await this.setUserAccessInfo(user);
            await this.setAvailableContractors(user);
        }
    }

    async saveUser() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {};
        payload.full_name = this.state.full_name;
        payload.email = this.state.email;
        payload.phone_number = this.state.phone_number;
        payload.status = this.state.status;
        payload.access_control = this.state.access_control ? this.state.access_control : {};
        
        if (this.state.contractors_access_info?.length) {
            payload.contractors_access_info = this.state.contractors_access_info.map(contractor => this.state.available_contractors_map[contractor]);
        }

        if (this.state.password && this.state.password != '') {
            payload.password = this.state.password;
            payload.confirm_password = this.state.confirm_password;
        }

        if (this.state.mode == 'add') {

            payload.username = this.state.username;
            payload.company_id = this.state.company?.id ? this.state.company.id : null;
            payload.role = this.state.role?.id ? this.state.role.id : null;

            if (this.state?.role?.id == config.ROLES.MANAGER.key || this.state?.role?.id == config.ROLES.USER.key) {
                payload.states = this.state.state;
                payload.zones = this.state.zone;
                payload.circles = this.state.circle;
                payload.divisions = this.state.division;
                payload.districts = this.state.district;
                payload.taluks = this.state.taluk;
            }



            let response = await addUserService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.user) {
                this.setState({
                    loading: false
                })
                this.props.navigate( '/' + localStorage.getItem('role') + '/users');
            }
        } else {

            let response = await editUserService(this.state.user_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.user) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('user_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getUser();
            }
        }

    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'username') {
            if (!this.state.username || this.state.username == '') {
                error.username = i18n.t('this_field_is_required');
            } else {
                error.username = '';
            }
        }

        if (this.state.mode == 'add') {
            if (!this.state.password || this.state.password == '') {
                error.password = i18n.t('this_field_is_required');
            } else {
                error.password = '';
            }

            if (!this.state.confirm_password || this.state.confirm_password == '') {
                error.confirm_password = i18n.t('this_field_is_required');
            } else if (this.state.password?.length && this.state.password != this.state.confirm_password) {
                error.confirm_password = i18n.t('passwords_do_not_match');
            } else {
                error.confirm_password = '';
            }
        }

        if (this.state.mode == 'edit') {
            if (this.state.password != this.state.confirm_password) {
                error.confirm_password = i18n.t('passwords_do_not_match');
            } else {
                error.confirm_password = '';
            }
        }

        if (field == null || field == 'company') {
            if (!this.state.company || this.state.company == '' || !this.state.company?.id) {
                error.company = i18n.t('this_field_is_required');
            } else {
                error.company = '';
            }
        }

        if (field == null || field == 'role') {
            if (!this.state.role || this.state.role == '' || !this.state.role?.id) {
                error.role = i18n.t('this_field_is_required');
            } else {
                error.role = '';
            }
        }

        if (this.state?.role?.id == config.ROLES.MANAGER.key || this.state?.role?.id == config.ROLES.USER.key) {
            if (field == null || field == 'state') {
                if (!this.state.state || this.state.state == '' || !this.state.state?.length) {
                    error.state = i18n.t('this_field_is_required');
                } else {
                    error.state = '';
                }
            }

            if (field == null || field == 'zone') {
                if (!this.state.zone || this.state.zone == '' || !this.state.zone?.length) {
                    error.zone = i18n.t('this_field_is_required');
                } else {
                    error.zone = '';
                }
            }

            if (field == null || field == 'circle') {
                if (!this.state.circle || this.state.circle == '' || !this.state.circle?.length) {
                    error.circle = i18n.t('this_field_is_required');
                } else {
                    error.circle = '';
                }
            }

            if (field == null || field == 'division') {
                if (!this.state.division || this.state.division == '' || !this.state.division?.length) {
                    error.division = i18n.t('this_field_is_required');
                } else {
                    error.division = '';
                }
            }

            if (field == null || field == 'district') {
                if (!this.state.district || this.state.district == '' || !this.state.district?.length) {
                    error.district = i18n.t('this_field_is_required');
                } else {
                    error.district = '';
                }
            }

            if (field == null || field == 'taluk') {
                if (!this.state.taluk || this.state.taluk == '' || !this.state.taluk?.length) {
                    error.taluk = i18n.t('this_field_is_required');
                } else {
                    error.taluk = '';
                }
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_company_admins') : i18n.t('edit_company_admins')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_company_admins') : i18n.t('edit_company_admins')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="full_name"
                                label={i18n.t('full_name')}
                                fullWidth
                                value={this.state.full_name}
                                onChange={async (e) => {
                                    await this.setState({
                                        full_name: e.target.value
                                    })
                                    this.validateField('full_name');
                                }}
                                error={!!this.state?.error?.full_name}
                                helperText={this.state?.error?.full_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="email"
                                type="email"
                                label={i18n.t('email')}
                                fullWidth
                                value={this.state.email}
                                onChange={async (e) => {
                                    await this.setState({
                                        email: e.target.value
                                    })
                                    this.validateField('email');
                                }}
                                error={!!this.state?.error?.email}
                                helperText={this.state?.error?.email}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="phone_number"
                                label={i18n.t('phone_number')}
                                fullWidth
                                value={this.state.phone_number}
                                onChange={async (e) => {
                                    await this.setState({
                                        phone_number: e.target.value
                                    })
                                    this.validateField('phone_number');
                                }}
                                error={!!this.state?.error?.phone_number}
                                helperText={this.state?.error?.phone_number}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="username"
                                label={i18n.t('username')}
                                disabled={(this.state.mode == 'edit') ? true : false}
                                fullWidth
                                value={this.state.username}
                                onChange={async (e) => {
                                    await this.setState({
                                        username: e.target.value
                                    })
                                    this.validateField('username');
                                }}
                                error={!!this.state?.error?.username}
                                helperText={this.state?.error?.username}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="password"
                                type="password"
                                label={i18n.t('password')}
                                fullWidth
                                value={this.state.password}
                                onChange={async (e) => {
                                    await this.setState({
                                        password: e.target.value
                                    })
                                    this.validateField('password');
                                }}
                                error={!!this.state?.error?.password}
                                helperText={this.state?.error?.password}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="confirm_password"
                                type="password"
                                label={i18n.t('confirm_password')}
                                fullWidth
                                value={this.state.confirm_password}
                                onChange={async (e) => {
                                    await this.setState({
                                        confirm_password: e.target.value
                                    })
                                    this.validateField('confirm_password');
                                }}
                                error={!!this.state?.error?.confirm_password}
                                helperText={this.state?.error?.confirm_password}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.company ? this.state.company : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            company: newValue
                                        })
                                        this.validateField('company');
                                    }}
                                    options={this.state.companies}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('company')} error={!!this.state?.error?.company} />}
                                    getOptionLabel={(option) => {
                                        if (option?.label)
                                            return option.label;
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.company}>{this.state?.error?.company}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.role ? this.state.role : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            role: newValue
                                        })
                                        this.validateField('role');
                                        this.setAvailableContractors();
                                    }}
                                    options={this.state.roles_for_selection}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('role')} error={!!this.state?.error?.role} />}
                                    getOptionLabel={(option) => {
                                        if (option?.label)
                                            return i18n.t('ROLES.' + option.label);
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.role}>{this.state?.error?.role}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {(this.state?.role?.id == config.ROLES.MANAGER.key || this.state?.role?.id == config.ROLES.USER.key) ? (
                        <Grid container spacing={1} sx={{mt: 3}}>
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        multiple
                                        value={this.state.state ? this.state.state : []}
                                        onChange={async (e, newValue) => {

                                            if (newValue.find(state => state == i18n.t('all'))) {
                                                newValue = this.state.states.filter(state => state != i18n.t('all'));
                                            }
                                            
                                            await this.setState({
                                                state: newValue
                                            })
                                            this.validateField('state');
                                            this.setZones();
                                        }}
                                        options={this.state.states}
                                        renderInput={(params) => <TextField {...params} label={i18n.t('state')} error={!!this.state?.error?.state} />}
                                        getOptionLabel={(option) => {
                                            return option;
                                        }}
                                        disabled={(this.state.mode == 'edit') ? true : false}
                                    />
                                    <FormHelperText error={!!this.state?.error?.state}>{this.state?.error?.state}</FormHelperText>
                                </FormControl>
                            </Grid>

                            {(this.state?.state?.length) ? (
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            value={this.state.zone ? this.state.zone : []}
                                            onChange={async (e, newValue) => {

                                                if (newValue.find(zone => zone == i18n.t('all'))) {
                                                    newValue = this.state.zones.filter(zone => zone != i18n.t('all'));
                                                }

                                                await this.setState({
                                                    zone: newValue
                                                })
                                                this.validateField('zone');
                                                this.setCircles();
                                            }}
                                            options={this.state.zones}
                                            renderInput={(params) => <TextField {...params} label={i18n.t('zone')} error={!!this.state?.error?.zone} />}
                                            getOptionLabel={(option) => {
                                                return option;
                                            }}
                                            disabled={(this.state.mode == 'edit') ? true : false}
                                        />
                                        <FormHelperText error={!!this.state?.error?.zone}>{this.state?.error?.zone}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            ) : null}

                            {(this.state?.zone?.length) ? (
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            value={this.state.circle ? this.state.circle : []}
                                            onChange={async (e, newValue) => {

                                                if (newValue.find(circle => circle == i18n.t('all'))) {
                                                    newValue = this.state.circles.filter(circle => circle != i18n.t('all'));
                                                }

                                                await this.setState({
                                                    circle: newValue
                                                })
                                                this.validateField('circle');
                                                this.setDivisions();
                                            }}
                                            options={this.state.circles}
                                            renderInput={(params) => <TextField {...params} label={i18n.t('circle')} error={!!this.state?.error?.circle} />}
                                            getOptionLabel={(option) => {
                                                return option;
                                            }}
                                            disabled={(this.state.mode == 'edit') ? true : false}
                                        />
                                        <FormHelperText error={!!this.state?.error?.circle}>{this.state?.error?.circle}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            ) : null}

                            {(this.state?.circle?.length) ? (
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            value={this.state.division ? this.state.division : []}
                                            onChange={async (e, newValue) => {

                                                if (newValue.find(division => division == i18n.t('all'))) {
                                                    newValue = this.state.divisions.filter(division => division != i18n.t('all'));
                                                }

                                                await this.setState({
                                                    division: newValue
                                                })
                                                this.validateField('division');
                                                this.setDistricts();
                                            }}
                                            options={this.state.divisions}
                                            renderInput={(params) => <TextField {...params} label={i18n.t('division')} error={!!this.state?.error?.division} />}
                                            getOptionLabel={(option) => {
                                                return option;
                                            }}
                                            disabled={(this.state.mode == 'edit') ? true : false}
                                        />
                                        <FormHelperText error={!!this.state?.error?.division}>{this.state?.error?.division}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            ) : null}

                            {(this.state?.division?.length) ? (
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            value={this.state.district ? this.state.district : []}
                                            onChange={async (e, newValue) => {

                                                if (newValue.find(district => district == i18n.t('all'))) {
                                                    newValue = this.state.districts.filter(district => district != i18n.t('all'));
                                                }

                                                await this.setState({
                                                    district: newValue
                                                })
                                                this.validateField('district');
                                                this.setTaluks();
                                            }}
                                            options={this.state.districts}
                                            renderInput={(params) => <TextField {...params} label={i18n.t('district')} error={!!this.state?.error?.district} />}
                                            getOptionLabel={(option) => {
                                                return option;
                                            }}
                                            disabled={(this.state.mode == 'edit') ? true : false}
                                        />
                                        <FormHelperText error={!!this.state?.error?.district}>{this.state?.error?.district}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            ) : null}

                            {(this.state?.district?.length) ? (
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            value={this.state.taluk ? this.state.taluk : []}
                                            onChange={async (e, newValue) => {

                                                if (newValue.find(taluk => taluk == i18n.t('all'))) {
                                                    newValue = this.state.taluks.filter(taluk => taluk != i18n.t('all'));
                                                }

                                                await this.setState({
                                                    taluk: newValue
                                                })
                                                this.validateField('taluk');
                                            }}
                                            options={this.state.taluks}
                                            renderInput={(params) => <TextField {...params} label={i18n.t('taluk')} error={!!this.state?.error?.taluk} />}
                                            getOptionLabel={(option) => {
                                                return option;
                                            }}
                                            disabled={(this.state.mode == 'edit') ? true : false}
                                        />
                                        <FormHelperText error={!!this.state?.error?.taluk}>{this.state?.error?.taluk}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            ) : null}
                            </Grid>
                    ) : null}
                    
                    
                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>{i18n.t('inactive')}</Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={(this.state.status == 'active') ? true : false}
                                            label={i18n.t('status')}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    status: e.target.checked ? 'active' : 'inactive'
                                                })
                                            }}
                                        />
                                    }
                                    label={i18n.t('status')}
                                    labelPlacement="bottom"
                                />
                                <Typography>{i18n.t('active')}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                    {/* Contractors Access Info, only available to admin and for manager only */}
                    {(this.role == config.ROLES.ADMIN.key && this.state?.role?.id == config.ROLES.MANAGER.key) ? (
                        <Grid container spacing={2} sx={{mt: 2}}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {i18n.t('contractors_access_info')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        multiple
                                        value={this.state.contractors_access_info ? this.state.contractors_access_info : []}
                                        onChange={async (e, newValue) => {
                                            await this.setState({
                                                contractors_access_info: newValue
                                            })
                                            this.validateField('contractors_access_info');
                                        }}
                                        options={this.state.available_contractors ? this.state.available_contractors : []}
                                        renderInput={(params) => <TextField {...params} label={i18n.t('contractors')} error={!!this.state?.error?.contractors_access_info} />}
                                        getOptionLabel={(option) => {
                                            if (option)
                                                return option;
                                        }}
                                    />
                                    <FormHelperText error={!!this.state?.error?.contractors_access_info}>{this.state?.error?.contractors_access_info}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    ) : null}

                    {/* Access Control, only available to admin or manager and for user only */}
                    {((this.role == config.ROLES.ADMIN.key || this.role == config.ROLES.MANAGER.key) && this.state?.role?.id == config.ROLES.USER.key) ? (
                        <>
                            <Grid container spacing={2} sx={{mt: 2}}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        {i18n.t('access_control')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* Table */}
                            <Grid container spacing={2} sx={{mt: 2}}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{i18n.t('table')}</TableCell>
                                                    <TableCell align="center">{i18n.t('view')}</TableCell>
                                                    <TableCell align="center">{i18n.t('add')}</TableCell>
                                                    <TableCell align="center">{i18n.t('edit')}</TableCell>
                                                    <TableCell align="center">{i18n.t('delete')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state?.access_control_keys.map((access_control_key) => (
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={access_control_key}>
                                                        <TableCell component="th" scope="row">{i18n.t(access_control_key)}</TableCell>
                                                        {this.state.access_control_methods.map((access_control_method) => (
                                                            <TableCell align="center" key={access_control_key + "-" + access_control_method}>
                                                                <Checkbox
                                                                    checked={this.state?.access_control?.[access_control_key]?.[access_control_method] ? true : false}
                                                                    onChange={async (e) => {
                                                                        let access_control = this.state.access_control;
                                                                        if (!access_control[access_control_key]) {
                                                                            access_control[access_control_key] = {};
                                                                        }
                                                                        access_control[access_control_key][access_control_method] = e.target.checked;
                                                                        await this.setState({
                                                                            access_control: access_control
                                                                        })
                                                                    }}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </>
                    ) : null}

                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveUser();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
    const params = useParams();
  
    return <AddEditUser {...props} navigate={navigate} params={params} />;
}
