/**Read this
 * Backend connection successful
 * Check the navigation to edit license page ---> done
 * Changes:
 * Add, Edit, Delete license functionality ---> done
 * New field Edit Message added
 * Bugs:
 * Company name and status not being displayed
 * search and sort licenses
 */

import { Helmet } from 'react-helmet-async';
import React from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Box,
  Chip,
  InputAdornment,
  Pagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import i18n from 'src/i18n';
import config from 'src/config';
import { getLicenses, deleteLicense } from 'src/services/license.service';
import { StyledSearch } from 'src/components/searchbar/styles';
import Searchbar from 'src/components/searchbar/Searchbar';
import Swal from 'sweetalert2';
import Label from '../components/label';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { convertMessageCodeToMessage } from 'src/utils/messageCodeToMessage';
import axios from 'axios';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'company', label: i18n.t('company'), alignRight: false, sortable: true },
  { id: 'company_status', label: i18n.t('company_status'), alignRight: false, sortable: true },
  { id: 'license_status', label: i18n.t('license_status'), alignRight: false, sortable: true },
  { id: 'module', label: i18n.t('module'), alignRight: false, sortable: true },
  { id: 'license_start_date', label: i18n.t('license_start_date'), alignRight: false, sortable: true },
  { id: 'license_end_date', label: i18n.t('license_end_date'), alignRight: false, sortable: true },
  { id: 'number_of_users', label: i18n.t('number_of_users'), alignRight: false, sortable: true },
  { id: '' },
];

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

class Licenses extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      licenses: [],
      open: null,
      page: 0,
      selected: [],
      searchValue: '',
      limit: 10,
      skip: 0,
      total_licenses: 0,
      sortOrder: 'asc',
      sortKey: 'company'
    };
  }

  async componentDidMount() {
    await this.setLicenses();
  }

  async setLicenses() {

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
    };

    if (this.state.searchValue) {
      query.searchKey = 'company';
      query.searchValue = this.state.searchValue;
    }

    if (this.state.sortKey) {
      query.sortKey = this.state.sortKey;
      query.sortOrder = this.state.sortOrder ? this.state.sortOrder : 'asc';
    }

    console.log(query);

    let licenses = await getLicenses(query);
    if (licenses.error) {
      Swal.fire({
        icon: 'error',
        title: i18n.t('error'),
        text: licenses.error_code ? convertMessageCodeToMessage(licenses.error_code) : licenses.error,
        confirmButtonText: i18n.t('ok'),
        confirmButtonColor: config.primaryColor
      });
      return;
    }

    this.setState({
      licenses: licenses?.licenses ? licenses.licenses : [],
      total_licenses: licenses?.count ? licenses.count : 0
    });
  }

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };

  handleDeleteLicense = async (licenseId) => {
    this.setState({ open: null }); //Closes the popover once the delete button is clicked
    Swal.fire({
      title: i18n.t('delete_license_confirmation'),
      text: i18n.t('delete_license_confirmation_message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: config.primaryColor,
      cancelButtonColor: '#f44336', // Red color for cancel button
      confirmButtonText: i18n.t('yes'),
      cancelButtonText: i18n.t('no'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteLicense(licenseId);
          await this.setLicenses(); // Refresh the company table once a company is deleted
          Swal.fire({
            icon: 'success',
            title: i18n.t('license_delete'),
            text: i18n.t('license_delete_message'),
            confirmButtonColor: config.primaryColor,
          });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: i18n.t('error'),
            text: error.response?.data?.error || error.message,
            confirmButtonColor: config.primaryColor,
          });
        }
      }
    });
  }

  handleRequestSort = async (event, property) => {
    const isAsc = this.state.sortKey === property && this.state.sortOrder === 'asc';
    await this.setState({ skip: 0, sortOrder: isAsc ? 'desc' : 'asc', sortKey: property });
    await this.setLicenses();
  };

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex), this.state.selected.slice(selectedIndex + 1));
    }
    // setSelected(newSelected);
    this.setState({ selected: newSelected });
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  render() {

    return (
      <>
        <Helmet>
          <title> {i18n.t('license')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <Container maxWidth="100%">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {i18n.t('license')}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => {
                this.props.navigate('add');
              }}
            >
              {i18n.t('add_license')}
            </Button>
          </Stack>

          <Card>

            <Searchbar
              placeholder={i18n.t('search_licenses')}
              style={{ margin: 20 }}
              // sx={{ mb: 2 }}
              onChange={async (event) => {
                await this.setState({
                  skip: 0,
                  searchValue: event.target.value
                });
                await this.setLicenses();
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? 'right' : 'left'}
                          sortDirection={this.state.sortKey === headCell.id ? this.state.sortOrder : false}
                        >
                          {(headCell.sortable) ? (
                            <TableSortLabel
                              hideSortIcon={headCell.sortable ? false : true}
                              active={this.state.sortKey === headCell.id}
                              direction={this.state.sortKey === headCell.id ? this.state.sortOrder : 'asc'}
                              onClick={this.createSortHandler(headCell.id)}
                            >
                              {headCell.label}
                              {(this.state.sortKey === headCell.id && headCell.sortable) ? (
                                <Box sx={{ ...visuallyHidden }}>{this.state.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <Typography variant="subtitle2">{headCell.label}</Typography>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.licenses?.map((row) => {

                      const { id, company_name, company_status, license_status, is_license_expired, module, license_start_date, license_end_date, number_of_users } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>

                          <TableCell align="left">{company_name}</TableCell>

                          <TableCell align="left">
                            <Label color={(company_status === 'active') ? 'success' : 'error'}>{i18n.t("STATUS." + company_status)}</Label>
                          </TableCell>

                          <TableCell align="left">
                            {
                              !is_license_expired ? <Label color={(license_status === 'Active') ? 'success' : 'warning'}>{i18n.t(license_status)}</Label> : <Label color="error">{i18n.t("Expired")}</Label>
                            }

                          </TableCell>

                          <TableCell align="left">{module}</TableCell>

                          <TableCell align="left">{license_start_date}</TableCell>

                          <TableCell align="left">{license_end_date}</TableCell>

                          <TableCell align="left">{number_of_users}</TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit"
                              onClick={(e) => {
                                this.setState({
                                  currentLicense: row
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Stack spacing={2} sx={{ alignItems: 'center' }}>
              <Pagination
                count={Math.ceil(this.state.total_licenses / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  await this.setState({
                    skip: (page - 1) * this.state.limit,
                  });
                  await this.setLicenses();
                }}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Stack>

          </Card>
        </Container>

        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate('/' + localStorage.getItem('role') + '/licenses/' + this.state.currentLicense.id);
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {i18n.t('edit')}
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }}
            onClick={() => {
              this.handleDeleteLicense(this.state.currentLicense.id)
            }
            }
          >
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>

      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();

  return <Licenses {...props} navigate={navigate} />;
}
