import { Helmet } from 'react-helmet-async';
import React from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Box,
  Chip,
  InputAdornment,
  Pagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import i18n from 'src/i18n';
import config from 'src/config';
import { getCompanies } from 'src/services/company.service';
import { StyledSearch } from 'src/components/searchbar/styles';
import Searchbar from 'src/components/searchbar/Searchbar';
import Swal from 'sweetalert2';
import Label from '../components/label';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { convertMessageCodeToMessage } from 'src/utils/messageCodeToMessage';
import moment from 'moment';
import { getRequestHeaders } from 'src/services/common.service';
import axios from 'axios';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: i18n.t('name'), alignRight: false, sortable: true },
  { id: 'contact_first_name', label: i18n.t('primary_contact_name'), alignRight: false, sortable: true },
  { id: 'contact_phone_number', label: i18n.t('primary_contact_number'), alignRight: false, sortable: true },
  { id: 'address', label: i18n.t('address'), alignRight: false, sortable: true },
  // { id: 'license_start_date', label: i18n.t('license_start_date'), alignRight: false, sortable: true },
  // { id: 'license_end_date', label: i18n.t('license_end_date'), alignRight: false, sortable: true },
  // { id: 'license_status', label: i18n.t('license_status'), alignRight: false, sortable: true },
  { id: 'status', label: i18n.t('status'), alignRight: false, sortable: true },
  { id: '' },
];

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

class Companies extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      open: null,
      page: 0,
      selected: [],
      search_value: '',
      limit: 10,
      skip: 0,
      total_companies: 0,
      sort_order: 'asc',
      sort_key: 'name'
    };
  }

  async componentDidMount() {
    await this.setCompanies();
  }

  async setCompanies() {

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
    };

    if (this.state.search_value) {
      query.search_key = 'name';
      query.search_value = this.state.search_value;
    }

    if (this.state.sort_key) {
      query.sort_key = this.state.sort_key;
      query.sort_order = this.state.sort_order ? this.state.sort_order : 'asc';
    }

    let companies = await getCompanies(query);
    if (companies.error) {
      Swal.fire({
        icon: 'error',
        title: i18n.t('error'),
        text: companies.error_code ? convertMessageCodeToMessage(companies.error_code) : companies.error,
        confirmButtonText: i18n.t('ok'),
        confirmButtonColor: config.primaryColor
      });
      return;
    }

    this.setState({
      companies: companies.companies,
      total_companies: companies.count
    });
  }

  handleDeleteCompany = async (companyId) => {
    this.setState({ open: null }); // Close the Popover menu after deleting the company
    Swal.fire({
      title: i18n.t('delete_company_confirmation'),
      text: i18n.t('delete_company_confirmation_message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: config.primaryColor,
      cancelButtonColor: '#f44336', // Red color for cancel button
      confirmButtonText: i18n.t('yes'),
      cancelButtonText: i18n.t('no'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let headers = getRequestHeaders();
          await axios.delete(config.SERVER_BASE_URL + '/companies/' + companyId, { headers: headers });
          await this.setCompanies(); // Refresh the company table once a company is deleted
          Swal.fire({
            icon: 'success',
            title: i18n.t('company_deleted'),
            text: i18n.t('company_deleted_message'),
            confirmButtonColor: config.primaryColor,
          });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: i18n.t('error'),
            text: error.response?.data?.error || error.message,
            confirmButtonColor: config.primaryColor,
          });
        }
      }
    });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };

  handleRequestSort = async (event, property) => {
    const isAsc = this.state.sort_key === property && this.state.sort_order === 'asc';
    await this.setState({ skip: 0, sort_order: isAsc ? 'desc' : 'asc', sort_key: property });
    await this.setCompanies();
  };

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex), this.state.selected.slice(selectedIndex + 1));
    }
    // setSelected(newSelected);
    this.setState({ selected: newSelected });
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  render() {

    return (
      <>
        <Helmet>
          <title> {i18n.t('companies')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <Container maxWidth="100%">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {i18n.t('companies')}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => {
                this.props.navigate('add');
              }}
            >
              {i18n.t('add_company')}
            </Button>
          </Stack>

          <Card>

            <Searchbar
              placeholder={i18n.t('search_companies')}
              style={{ margin: 20 }}
              // sx={{ mb: 2 }}
              onChange={async (event) => {
                await this.setState({
                  skip: 0,
                  search_value: event.target.value
                });
                await this.setCompanies();
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? 'right' : 'left'}
                          sortDirection={this.state.sort_key === headCell.id ? this.state.sort_order : false}
                        >
                          {(headCell.sortable) ? (
                            <TableSortLabel
                              hideSortIcon={headCell.sortable ? false : true}
                              active={this.state.sort_key === headCell.id}
                              direction={this.state.sort_key === headCell.id ? this.state.sort_order : 'asc'}
                              onClick={this.createSortHandler(headCell.id)}
                            >
                              {headCell.label}
                              {(this.state.sort_key === headCell.id && headCell.sortable) ? (
                                <Box sx={{ ...visuallyHidden }}>{this.state.sort_order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <Typography variant="subtitle2">{headCell.label}</Typography>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.companies.map((row) => {
                      const { id, name, address, salutation, contact_first_name, contact_last_name, contact_phone_number, status } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>

                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Stack direction="column" spacing={0} sx={{ typography: 'body2' }}>
                                <Typography variant="subtitle2">
                                  {name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{salutation + ' ' + contact_first_name + ' ' + contact_last_name}</TableCell>

                          <TableCell align="left">{contact_phone_number}</TableCell>

                          <TableCell align="left">{address}</TableCell>

                          {/* <TableCell align="left">
                            <Chip label={moment(license_start_date).format(config.DATE_FORMAT.MEDIUM)} />
                          </TableCell>
                          
                          <TableCell align="left">
                            <Chip label={moment(license_end_date).format(config.DATE_FORMAT.MEDIUM)} />
                          </TableCell>

                          <TableCell align="left">
                            <Label color={(!is_license_expired) ? 'success' : 'error'}>{i18n.t('LICENSE_STATUS.' + (is_license_expired ? 'expired' : 'active'))}</Label>
                          </TableCell> */}

                          <TableCell align="left">
                            <Label color={(status === 'active') ? 'success' : 'error'}>{i18n.t('STATUS.' + status)}</Label>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit"
                              onClick={(e) => {
                                this.setState({
                                  currentCompany: row
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                </Table>
              </TableContainer>
            </Scrollbar>

            <Stack spacing={2} sx={{ alignItems: 'center' }}>
              <Pagination
                count={Math.ceil(this.state.total_companies / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  await this.setState({
                    skip: (page - 1) * this.state.limit,
                  });
                  await this.setCompanies();
                }}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Stack>

          </Card>
        </Container>

        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate('/' + localStorage.getItem('role') + '/companies/' + this.state.currentCompany.id);
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {i18n.t('edit')}
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }}
            onClick={() => this.handleDeleteCompany(this.state.currentCompany.id)}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>

      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();

  return <Companies {...props} navigate={navigate} />;
}
