import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

// const role = localStorage.getItem("role");

const getRoyalties = async (query = null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            // response = await axios.get( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/royalty" : '/royalty'}` + queryString, { headers: headers });
            response = await axios.get(config.SERVER_BASE_URL + '/royalty' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getRoyalty = async (royaltyId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.get( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/royalty/" : '/royalty/'}` + roleId, { headers: headers });
            response = await axios.get(config.SERVER_BASE_URL + '/royalty/' + royaltyId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addRoyalty = async (payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.post( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/royalty" : '/royalty'}`, payload, { headers: headers });
            response = await axios.post(config.SERVER_BASE_URL + '/royalty', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editRoyalty = async (royaltyId, payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.patch( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/royalty/" : '/royalty/' }` + roleId, payload, { headers: headers });
            response = await axios.patch(config.SERVER_BASE_URL + '/royalty/' + royaltyId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}


export {
    getRoyalties,
    getRoyalty,
    addRoyalty,
    editRoyalty,
}