import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

// const role = localStorage.getItem("role");

const getDeductions = async (query = null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            // response = await axios.get( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/deduction" : '/deduction'}` + queryString, { headers: headers });
            response = await axios.get(config.SERVER_BASE_URL + '/mastertables/deduction' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getDeduction = async (deductionId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.get( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/deduction/" : '/deduction/'}` + roleId, { headers: headers });
            response = await axios.get(config.SERVER_BASE_URL + '/mastertables/deduction/' + deductionId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addDeduction = async (payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.post( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/deduction" : '/deduction'}`, payload, { headers: headers });
            response = await axios.post(config.SERVER_BASE_URL + '/mastertables/deduction', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editDeduction = async (deductionId, payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            // response = await axios.patch( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/deduction/" : '/deduction/' }` + roleId, payload, { headers: headers });
            response = await axios.patch(config.SERVER_BASE_URL + '/mastertables/deduction/' + deductionId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteDeduction = async (deductionId) => {
    try {
        let headers = getRequestHeaders();
        let response;
        try {
            // response = await axios.patch( config.SERVER_BASE_URL + `${role === "admin" ? "/mastertables/deduction/" : '/deduction/' }` + deductionId, { headers: headers });
            response = await axios.delete(config.SERVER_BASE_URL + '/mastertables/deduction/' + deductionId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}


export {
    getDeductions,
    getDeduction,
    addDeduction,
    editDeduction,
    deleteDeduction
}