// component
import i18n from 'src/i18n';

import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';
import PermContactCalendarSharpIcon from '@mui/icons-material/PermContactCalendarSharp';
import ConstructionIcon from '@mui/icons-material/Construction';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import Person4Icon from '@mui/icons-material/Person4';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CalculateIcon from '@mui/icons-material/Calculate';
import EventNoteSharpIcon from '@mui/icons-material/EventNoteSharp';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: i18n.t('dashboard'),
    path: '/admin/dashboard',
    icon: <BarChartIcon  />,
  },
  {
    title: i18n.t('users'),
    path: '/admin/users',
    icon: <PeopleIcon />,
  },
  {
    title: i18n.t('roles'),
    path: '/admin/roles', 
    icon: <PermContactCalendarSharpIcon />,
  },
  {
    title: i18n.t('master_tables'),
    icon: <TableChartSharpIcon />,
    children: [
      {
        title: i18n.t('contractors'),
        path: '/admin/mastertables/contractors',
        icon: <ConstructionIcon />
      },
      {
        title: i18n.t('type_of_works'),
        path: '/admin/mastertables/type_of_works',
        icon: <CalendarViewWeekIcon />
      },
      {
        title: i18n.t('head_of_accounts'),
        path: '/admin/mastertables/head_of_accounts',
        icon: <Person4Icon />
      },
      {
        title: i18n.t('royalty'),
        path: '/admin/mastertables/royalty',
        icon: <CurrencyRupeeIcon />
      },
      {
        title: i18n.t('deduction'),
        path: '/admin/mastertables/deduction',
        icon: <CalculateIcon />
      }
    ]
  },
  {
    title: i18n.t('reports'),
    path: '/admin/reports',
    icon: <EventNoteSharpIcon />,
  }
];

export default navConfig;
