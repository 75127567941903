import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getTenders = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/tenders' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getTender = async (tenderId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/tenders/' + tenderId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addTender = async (formData) => {
    try {
        let headers = getRequestHeaders();
        headers['Content-Type'] = 'multipart/form-data';
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/tenders', formData, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editTender = async (tenderId, formData) => {
    try {

        let headers = getRequestHeaders();
        headers['Content-Type'] = 'multipart/form-data';
        let response;
        try {
            response = await axios.patch( config.SERVER_BASE_URL + '/tenders/' + tenderId, formData, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getTenderFinancialYears = async (query=null) => {
    try {
        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/tenders/dropdowns/financial_year' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getTenderIndentNos = async (query=null) => {
    try {
        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/tenders/dropdowns/indent_no' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getTenders,
    getTender,
    addTender,
    editTender,
    getTenderFinancialYears,
    getTenderIndentNos
}