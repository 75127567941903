/**Read this
 * Need a function for add bulk locations
 */


import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getLocations = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/locations' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getLocation = async (locationId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/locations/' + locationId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

// const getProfile = async () => {
//     try {

//         let headers = getRequestHeaders();

//         let response;
//         try {
//             response = await axios.get( config.SERVER_BASE_URL + '/license/profile', { headers: headers });
//         } catch (error) {
//             return error.response.data;
//         }
//         return response.data;
//     } catch (error) {
//         return { error: error.message };
//     }
// }

const addLocation = async (payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/locations', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editLocation = async (locationId, payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.patch( config.SERVER_BASE_URL + '/locations/' + locationId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}


export {
    getLocations,
    getLocation,
    addLocation,
    editLocation,
}