import { Helmet } from 'react-helmet-async';
import React from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Box,
  Chip,
  InputAdornment,
  Pagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import i18n from 'src/i18n';
import config from 'src/config';
import { getProposals } from 'src/services/proposal.service';
import { StyledSearch } from 'src/components/searchbar/styles';
import Searchbar from 'src/components/searchbar/Searchbar';
import Swal from 'sweetalert2';
import Label from '../components/label';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { convertMessageCodeToMessage } from 'src/utils/messageCodeToMessage';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'indent_no', label: i18n.t('indent_no'), alignRight: false, sortable: true },
  { id: 'proposal_id', label: i18n.t('proposal_id'), alignRight: false, sortable: true },
  { id: 'name_of_work', label: i18n.t('name_of_work'), alignRight: false, sortable: true },
  { id: 'type_of_work', label: i18n.t('type_of_work'), alignRight: false, sortable: true },
  { id: 'state', label: i18n.t('state'), alignRight: false, sortable: true },
  { id: 'zone', label: i18n.t('zone'), alignRight: false, sortable: true },
  { id: 'circle', label: i18n.t('circle'), alignRight: false, sortable: true },
  { id: 'division', label: i18n.t('division'), alignRight: false, sortable: true },
  { id: 'district', label: i18n.t('district'), alignRight: false, sortable: true },
  { id: 'taluk', label: i18n.t('taluk'), alignRight: false, sortable: true },
  { id: 'road_type', label: i18n.t('road_type'), alignRight: false, sortable: true },
  { id: 'road_number', label: i18n.t('road_number'), alignRight: false, sortable: true },
  { id: 'road_length', label: i18n.t('road_length'), alignRight: false, sortable: true },
  { id: 'chainage_tackled', label: i18n.t('chainage_tackled'), alignRight: false, sortable: true },
  { id: 'financial_year', label: i18n.t('financial_year'), alignRight: false, sortable: true },
  { id: 'head_of_account', label: i18n.t('head_of_account'), alignRight: false, sortable: true },
  { id: 'admin_approval', label: i18n.t('admin_approval'), alignRight: false, sortable: true },
  { id: 'approval_no', label: i18n.t('approval_no'), alignRight: false, sortable: true },
  { id: 'technical_sanction', label: i18n.t('technical_sanction'), alignRight: false, sortable: true },
  { id: 'technical_sanction_no', label: i18n.t('technical_sanction_no'), alignRight: false, sortable: true },
  { id: 'estimated_cost', label: i18n.t('estimated_cost'), alignRight: false, sortable: true },
  { id: '' },
];

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

class Proposals extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      proposals: [],
      open: null,
      page: 0,
      selected: [],
      searchValue: '',
      limit: 10,
      skip: 0,
      total_proposals: 0,
      sortOrder: 'asc',
      sortKey: 'full_name'
    };
  }

  async componentDidMount() {
    await this.setProposals();
  }

  async setProposals() {

    let query = {
        limit: this.state.limit,
        skip: this.state.skip,
    };
    
    if (this.state.searchValue) {
        query.searchKey = 'indent_no,proposal_id,name_of_work';
        query.searchValue = this.state.searchValue;
    }

    if (this.state.sortKey) {
        query.sortKey = this.state.sortKey;
        query.sortOrder = this.state.sortOrder ? this.state.sortOrder : 'asc';
    }

    let proposals = await getProposals(query);
    if (proposals.error) {
        Swal.fire({
            icon: 'error',
            title: i18n.t('error'),
            text: proposals.error_code ? convertMessageCodeToMessage(proposals.error_code) : proposals.error,
            confirmButtonText: i18n.t('ok'),
            confirmButtonColor: config.primaryColor
        });
        return;
    }

    this.setState({
        proposals: proposals?.proposals ? proposals.proposals : [],
        total_proposals: proposals?.count ? proposals.count : 0
    });
  }

  handleOpenMenu = (event) => {
    this.setState({open: event.currentTarget});
  };

  handleCloseMenu = () => {
    this.setState({open: null});
  };

  handleRequestSort = async (event, property) => {
    const isAsc = this.state.sortKey === property && this.state.sortOrder === 'asc';
    await this.setState({skip: 0, sortOrder: isAsc ? 'desc' : 'asc', sortKey: property});
    await this.setProposals();
  };

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex), this.state.selected.slice(selectedIndex + 1));
    }
    // setSelected(newSelected);
    this.setState({selected: newSelected});
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  render() {

    return (
      <>
        <Helmet>
          <title> {i18n.t('proposals')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <Container maxWidth="100%">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {i18n.t('proposals')}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => {
                this.props.navigate('add');
              }}
            >
              {i18n.t('add_proposal')}
            </Button>
          </Stack>

          <Card>

            <Searchbar
                placeholder={i18n.t('search_proposals')}
                style={{margin: 20}}
                // sx={{ mb: 2 }}
                onChange={async (event) => {
                    await this.setState({
                        skip: 0,
                        searchValue: event.target.value
                    });
                    await this.setProposals();
                }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? 'right' : 'left'}
                          sortDirection={this.state.sortKey === headCell.id ? this.state.sortOrder : false}
                        >
                          {(headCell.sortable) ? (
                            <TableSortLabel
                              hideSortIcon={headCell.sortable ? false : true}
                              active={this.state.sortKey === headCell.id}
                              direction={this.state.sortKey === headCell.id ? this.state.sortOrder : 'asc'}
                              onClick={this.createSortHandler(headCell.id)}
                            >
                              {headCell.label}
                              {(this.state.sortKey === headCell.id && headCell.sortable) ? (
                                <Box sx={{ ...visuallyHidden }}>{this.state.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <Typography variant="subtitle2">{headCell.label}</Typography>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.proposals?.map((row) => {
                      let { id, indent_no, proposal_id, name_of_work, type_of_work, state, zone, circle, division, district, taluk, road_type, road_number, road_length, chainage, financial_year, head_of_account, admin_approval, approval_no, technical_sanction, technical_sanction_no, estimated_cost } = row;

                      if (chainage) {
                        chainage = chainage.map((chng) => {
                          return chng.chainage_from + ' - ' + chng.chainage_to;
                        }).join(', ');
                      }

                      return (
                        <TableRow hover key={id} tabIndex={-1}>

                          <TableCell align="left">{indent_no}</TableCell>

                          <TableCell align="left">{proposal_id}</TableCell>

                          <TableCell align="left">{name_of_work}</TableCell>

                          <TableCell align="left">{type_of_work}</TableCell>

                          <TableCell align="left">{state}</TableCell>

                          <TableCell align="left">{zone}</TableCell>

                          <TableCell align="left">{circle}</TableCell>

                          <TableCell align="left">{division}</TableCell>

                          <TableCell align="left">{district}</TableCell>

                          <TableCell align="left">{taluk}</TableCell>

                          <TableCell align="left">{road_type}</TableCell>

                          <TableCell align="left">{road_number}</TableCell>

                          <TableCell align="left">{road_length}</TableCell>

                          <TableCell align="left">{chainage}</TableCell>

                          <TableCell align="left">{financial_year}</TableCell>

                          <TableCell align="left">{head_of_account}</TableCell>

                          <TableCell align="left">{admin_approval}</TableCell>

                          <TableCell align="left">{approval_no}</TableCell>

                          <TableCell align="left">{technical_sanction}</TableCell>

                          <TableCell align="left">{technical_sanction_no}</TableCell>

                          <TableCell align="left">{estimated_cost}</TableCell>
                          
                          <TableCell align="right">
                            <IconButton size="large" color="inherit"
                              onClick={(e) => {
                                this.setState({
                                  currentProposal: row
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                </Table>
              </TableContainer>
            </Scrollbar>

            <Stack spacing={2} sx={{alignItems: 'center'}}>
              <Pagination
                  count={Math.ceil(this.state.total_proposals / this.state.limit)}
                  shape="rounded"
                  size="large"
                  onChange={async (event, page) => {
                      await this.setState({
                          skip: (page - 1) * this.state.limit,
                      });
                      await this.setProposals();
                  }}
                  showFirstButton={true}
                  showLastButton={true}
                  boundaryCount={2}
                  style={{margin: 20}}
              />
            </Stack>

          </Card>
        </Container>

        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate( '/' + localStorage.getItem('role') + '/proposals/' + this.state.currentProposal.id);
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {i18n.t('edit')}
          </MenuItem>

          {/* <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}
        </Popover>

      </>
    );
  }
}

// Wrap and export
export default function(props) {
  const navigate = useNavigate();

  return <Proposals {...props} navigate={navigate} />;
}
