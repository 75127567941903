import { Box, Button, Card, CardContent, Container, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import config from 'src/config';
import i18n from 'src/i18n';
import { getProfile as getProfileService, editProfile as editProfileService } from 'src/services/user.service';
import { convertMessageCodeToMessage } from 'src/utils/messageCodeToMessage';
import Swal from 'sweetalert2';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            full_name: '',
            email: '',
            phone_number: '',
            password: '',
            confirm_password: '',
            loading: false,
            error: {}
        }
    }

    async componentDidMount() {
        await this.fetchProfile();
    }

    async fetchProfile() {
        let profile = await getProfileService();
        if (profile.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: profile.error_code ? convertMessageCodeToMessage(profile.error_code) : profile.error
            });
            return;
        }

        profile = profile.profile;

        this.setState({
            username: profile.username,
            full_name: profile.full_name,
            email: profile.email,
            phone_number: profile.phone_number,
        });
    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'password' || field == 'confirm_password') {
            if (this.state.password != this.state.confirm_password) {
                error.confirm_password = i18n.t('passwords_do_not_match');
            } else {
                error.confirm_password = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    async saveProfile() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {
            full_name: this.state.full_name,
            email: this.state.email,
            phone_number: this.state.phone_number
        };

        if (this.state.password && this.state.password != '') {
            payload.password = this.state.password;
            payload.confirmPassword = this.state.confirm_password;
        }

        let response = await editProfileService(payload);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            this.setState({
                loading: false
            })
            return;
        }

        if (response && response.profile) {
            // Swal toast
            Swal.fire({
                toast: true,
                position: 'bottom-end',
                icon: 'success',
                title: i18n.t('success'),
                text: i18n.t('profile_updated_successfully'),
                showConfirmButton: false,
                timer: config.toastDelay,
                timerProgressBar: true
            })
            this.setState({
                loading: false
            })
            this.getUser();
        }

    }

    render() {
        return (
            <>
                <Helmet>
                    <title> {i18n.t('profile')} | {config.APPLICATION_NAME} </title>
                </Helmet>
                <Container maxWidth="100%">
                    <Box sx={{ pb: 5 }}>
                        <Typography variant="h4">{i18n.t('profile')}</Typography>
                    </Box>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={i18n.t('username')}
                                        variant="outlined"
                                        fullWidth
                                        value={this.state.username}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={i18n.t('full_name')}
                                        variant="outlined"
                                        fullWidth
                                        value={this.state.full_name}
                                        onChange={(e) => {
                                            this.setState({ full_name: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={i18n.t('email')}
                                        variant="outlined"
                                        fullWidth
                                        value={this.state.email}
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={i18n.t('phone_number')}
                                        variant="outlined"
                                        fullWidth
                                        value={this.state.phone_number}
                                        onChange={(e) => {
                                            this.setState({ phone_number: e.target.value })
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} sx={{mt: 3, mb: 3}}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6">{i18n.t('change_password') + " ( " + i18n.t('leave_blank_if_you_dont_want_to_change_password') + " )"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={i18n.t('password')}
                                        variant="outlined"
                                        fullWidth
                                        type="password"
                                        onChange={async (e) => {
                                            await this.setState({ password: e.target.value })
                                            this.validateField('password');
                                        }}
                                        error={!!this.state.error?.password}
                                        helperText={this.state.error?.password}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={i18n.t('confirm_password')}
                                        variant="outlined"
                                        fullWidth
                                        type="password"
                                        onChange={async (e) => {
                                            await this.setState({ confirm_password: e.target.value })
                                            this.validateField('confirm_password');
                                        }}
                                        error={!!this.state.error?.confirm_password}
                                        helperText={this.state.error?.confirm_password}
                                    />
                                </Grid>
                            </Grid>

                            <Box sx={{ mt: 3, textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.saveProfile();
                                    }}
                                >
                                    {i18n.t('save')}
                                </Button>
                            </Box>


                        </CardContent>
                    </Card>
                </Container>
            </>
        )
    }
}

export default Profile;