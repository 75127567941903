import axios from 'axios'

const getBankDetailsFromIFSC = async (ifsc) => {
    try {
        let response;
        try {
            response = await axios.get(`https://ifsc.razorpay.com/${ifsc}`);
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getBankDetailsFromIFSC
}
