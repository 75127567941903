/**Read ths
 * Fix - Auth fix if token is stored in localStorage then the user will not be able to go to login page.
 */

import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SuperadminLayout from './layouts/superadmin';
import AdminLayout from './layouts/admin';
import UserLayout from './layouts/user';
// import SimpleLayout from './layouts/simple';
//
// import BlogPage from './pages/BlogPage';
import Page404 from './pages/Page404';
// import ProductsPage from './pages/ProductsPage';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Users from './pages/Users';
import AddEditUser from './pages/AddEditUser';
import TypeOfWorks from './pages/TypeOfWorks';
import Companies from './pages/Companies';
import AddEditCompany from './pages/AddEditCompany';
import Login from './pages/Login';
import Contractors from './pages/Contractors';
import Proposals from './pages/Proposals';
import Works from './pages/Works';
import WorkSchedule from './pages/WorkSchedule';
import PhysicalProgress from './pages/PhysicalProgress';
import FinancialProgress from './pages/FinancialProgress';
import QualityControl from './pages/QualityControl';
import Tenders from './pages/Tenders';
import AddEditContractor from './pages/AddEditContractor';
import AddEditTypeOfWorks from './pages/AddEditTypeOfWorks';
import AddEditProposal from './pages/AddEditProposal';
import AddEditTender from './pages/AddEditTender';
import AddEditWork from './pages/AddEditWork';

import Licenses from './pages/Licenses'
import AddEditLicense from './pages/AddEditLicense';

// import MasterTables from './pages/MasterTables';
import TemplateEstimates from './pages/TemplateEstimates';
import ForgotPassword from './pages/ForgotPassword';
import ScheduleRates from './pages/ScheduleRates';
import ScheduleYear from './pages/ScheduleYear';
import Location from './pages/Location';
import Units from './pages/Units';
import AreaWeightage from './pages/AreaWeightage';
import AddEditAreaWeightage from './pages/AddEditAreaWeightage';
import AddEditUnit from './pages/AddEditUnit';
import AddEditLocation from './pages/AddEditLocation';
import AddBulkLocation from './pages/AddBulkLocation';
import AddEditScheduleRate from './pages/AddEditScheduleRate';
import DownloadSr from './pages/DownloadSr';
import AddScheculeRateItem from './pages/AddScheculeRateItem';
import HeadOfAccounts from './pages/HeadOfAccounts';
import AddEditHeadOfAccounts from './pages/AddEditHeadOfAccounts';
import Royalty from './pages/Royalty';
import AddEditRoyalty from './pages/AddEditRoyalty';
import Deduction from './pages/Deduction';
import AddEditDeduction from './pages/AddEditDeduction';
import Reports from './pages/Reports';
import Roles from './pages/Roles';
import AddEditRoles from './pages/AddEditRoles';

// ----------------------------------------------------------------------

// const token = localStorage.getItem('token');

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      // element: `${token ? <Navigate to="dashboard" /> : <Navigate to="login" />}`
      element: <Navigate to="login" />
    },
    {
      path: '/superadmin',
      element: <SuperadminLayout />,
      children: [
        { element: <Navigate to="dashboard" />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'companies', element: <Companies /> },
        { path: 'companies/add', element: <AddEditCompany /> },
        { path: 'companies/:company_id', element: <AddEditCompany /> },
        { path: 'users', element: <Users /> },
        { path: 'users/add', element: <AddEditUser /> },
        { path: 'users/:user_id', element: <AddEditUser /> },
        { path: 'licenses', element: <Licenses /> },
        { path: 'licenses/add', element: <AddEditLicense /> },
        { path: 'licenses/:license_id', element: <AddEditLicense /> }, //check after backen connection
        {
          path: 'mastertables', children: [
            {
              path: 'scheduleofrates',
              element: <ScheduleRates />
            },
            {
              path: 'scheduleofrates/addscheduleitem',
              element: <AddScheculeRateItem />
            },
            {
              path: 'scheduleofrates/downloadsr',
              element: <DownloadSr />
            },
            {
              path: 'scheduleofrates/add',
              element: <AddEditScheduleRate />
            },
            {
              path: 'scheduleofrates/:scheduleofrate_id',
              element: <AddEditScheduleRate />
            },
            {
              path: 'scheduleyear',
              element: <ScheduleYear />
            },
            {
              path: 'locations',
              element: <Location />
            },
            {
              path: 'locations/addbulklocation',
              element: <AddBulkLocation />
            },
            {
              path: 'locations/add',
              element: <AddEditLocation />
            },
            {
              path: 'locations/:location_id',
              element: <AddEditLocation />
            },
            {
              path: 'units',
              element: <Units />
            },
            {
              path: 'units/add',
              element: <AddEditUnit />
            },
            {
              path: 'units/:unit_id ',
              element: <AddEditUnit />
            },
            {
              path: 'areaweightage',
              element: <AreaWeightage />
            },
            {
              path: 'areaweightage/add',
              element: <AddEditAreaWeightage />
            },
            {
              path: 'areaweightage/:areaweightage_id',
              element: <AddEditAreaWeightage />
            }
          ],
        },
        { path: 'templateestimates', element: <TemplateEstimates /> },
        { path: 'profile', element: <Profile /> },
      ],
    },
    {
      path: '/admin',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="dashboard" />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'users', element: <Users /> },
        { path: 'users/add', element: <AddEditUser /> },
        { path: 'users/:user_id', element: <AddEditUser /> },
        {path: 'roles', element: <Roles />},
        { path: 'roles/add', element: <AddEditRoles /> },
        { path: 'roles/:role_id', element: <AddEditRoles /> },
        {
          path: 'mastertables', children: [
            {
              path: 'contractors',
              element: <Contractors />
            },
            {
              path: 'contractors/add',
              element: <AddEditContractor />
            },
            {
              path: 'contractors/:cont_id',
              element: <AddEditContractor />
            },
            {
              path: 'type_of_works',
              element: <TypeOfWorks />
            },
            {
              path: 'type_of_works/add',
              element: <AddEditTypeOfWorks />
            },
            {
              path: 'type_of_works/:type_of_work_id',     //page not found check!!!
              element: <AddEditTypeOfWorks />
            },
            {
              path: 'head_of_accounts',
              element: <HeadOfAccounts />
            },
            {
              path: 'head_of_accounts/add',
              element: <AddEditHeadOfAccounts />
            },
            {
              path: 'head_of_accounts/:head_of_accounts_id',
              element: <AddEditHeadOfAccounts />
            },
            {
              path: 'royalty',
              element: <Royalty />
            },
            {
              path: 'royalty/add',
              element: <AddEditRoyalty />
            },
            {
              path: 'royalty/:royalty_id',
              element: <AddEditRoyalty/>
            },
            {
              path: 'deduction',
              element: <Deduction />
            },
            {
              path: 'deduction/add',
              element: <AddEditDeduction />
            },
            {
              path: 'deduction/:deduction_id',
              element: <AddEditDeduction />
            }
          ],
        },
        { path: 'reports', element: <Reports /> },
        { path: 'profile', element: <Profile /> },
      ],
    },
    {
      path: '/user',
      element: <UserLayout />,
      children: [
        { element: <Navigate to="pms/dashboard" />, index: true },
        { path: 'pms/dashboard', element: <Dashboard /> },
        // { pathpms/: 'users', element: <Users /> },
        // { pathpms/: 'users/add', element: <AddEditUser /> },
        // { pathpms/: 'users/:user_id', element: <AddEditUser /> },
        // { pathpms/: 'contractors', element: <Contractors /> },
        // { pathpms/: 'contractors/add', element: <AddEditContractor /> },
        // { pathpms/: 'contractors/:cont_id', element: <AddEditContractor /> },
        { path: 'pms/proposals', element: <Proposals /> },
        { path: 'pms/proposals/add', element: <AddEditProposal /> },
        { path: 'pms/proposals/:prop_id', element: <AddEditProposal /> },
        { path: 'pms/tenders', element: <Tenders /> },
        { path: 'pms/tenders/add', element: <AddEditTender /> },
        { path: 'pms/tenders/:tend_id', element: <AddEditTender /> },
        { path: 'pms/works', element: <Works /> },
        { path: 'pms/works/add', element: <AddEditWork /> },
        { path: 'pms/works/:work_id', element: <AddEditWork /> },
        { path: 'pms/work_schedule', element: <WorkSchedule /> },
        { path: 'pms/physical_progress', element: <PhysicalProgress /> },
        { path: 'pms/financial_progress', element: <FinancialProgress /> },
        { path: 'pms/quality_control', element: <QualityControl /> },
        { path: 'pms/profile', element: <Profile /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'forgotpassword',
      element: <ForgotPassword />,
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/app" />, index: true },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
