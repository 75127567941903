/**Read this
 * here sr_no must be auto genrated
 * Backend connection needed for saving the data
 */

import { Autocomplete, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getUnit as getUnitService, addUnit as addUnitService, editUnit as editUnitService } from "src/services/unit.service";
import Swal from "sweetalert2";
// import { getRolesForSelection } from "src/services/common.service";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import moment from "moment";
// mui
import { teal } from '@mui/material/colors';
import { Label } from "@mui/icons-material";

class AddEditUnit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.units_id ? 'edit' : 'add',
            units_id: props.params?.units_id,
            sr_no: '',
            units: '',
            number: '',
            length: '',
            breadth: '',
            depth: '',
            error: {},
            loading: false,
        }
    }

    async componentDidMount() {

        if (this.state.mode == 'edit') {
            await this.getUnit();
        }
    }

    async getUnit() {
        let response = await getUnitService(this.state.units_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.areaweightage) {
            let areaweightage = response.areaweightage;

            await this.setState({
                sr_no: areaweightage?.company ? areaweightage.company : '',
                areaweightage_category: areaweightage?.status ? areaweightage.status : '',
                area_weightage: areaweightage?.module ? areaweightage.module : '',
            })
        }
    }

    /**Function to save areaweightage
     * @returns Saves the areaweightage to the backend
     */
    async saveUnit() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {
            sr_no: this.state.sr_no,
            units: this.state.units,
        }

        if (this.state.mode == 'add') {
            let response = await addUnitService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.company) {
                this.setState({
                    loading: false
                })
                this.props.navigate('/' + localStorage.getItem('role') + '/units');
            }
        } else {
            let response = await editUnitService(this.state.units_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.units) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('units_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getUnit();
            }
        }
    }

    async isFormValid() {
        let error = await this.validateField();
        console.log("Error", error)
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field = null) {

        let error = this.state.error;

        // units validation
        if (field == null || field == 'units') {
            if (!this.state.units || this.state.units == '') {
                error.units = i18n.t('this_field_is_required');
            } else {
                error.units = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_units') : i18n.t('edit_units')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_units') : i18n.t('edit_units')}
                        </Typography>
                    </Stack>

                    {/* <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="sr_no"
                                label={i18n.t('sr_no')}
                                fullWidth
                                value={this.state.sr_no}
                                onChange={async (e) => {
                                    await this.setState({
                                        sr_no: e.target.value
                                    })
                                    this.validateField('sr_no');
                                }}
                                error={!!this.state?.error?.sr_no}
                                helperText={this.state?.error?.sr_no}
                                disabled={(this.state.mode == 'edit') ? true : false}
                            />
                        </Grid>
                    </Grid> */}

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="units"
                                label={i18n.t('units')}
                                fullWidth
                                value={this.state.units}
                                onChange={async (e) => {
                                    await this.setState({
                                        units: e.target.value
                                    })
                                    this.validateField('areaweightage_category');
                                }}
                                error={!!this.state?.error?.units}
                                helperText={this.state?.error?.units}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox sx={
                                            {
                                                color: teal[800],
                                                '&.Mui-checked': {
                                                    color: teal[600],
                                                },
                                            }
                                        } />
                                    }
                                    label="Number" name="number" />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox sx={
                                            {
                                                color: teal[800],
                                                '&.Mui-checked': {
                                                    color: teal[600],
                                                },
                                            }
                                        } />
                                    }
                                    label="Length" name="length" />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox sx={
                                            {
                                                color: teal[800],
                                                '&.Mui-checked': {
                                                    color: teal[600],
                                                },
                                            }
                                        } />
                                    }
                                    label="Breadth" name="breadth" />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox sx={
                                            {
                                                color: teal[800],
                                                '&.Mui-checked': {
                                                    color: teal[600],
                                                },
                                            }
                                        } />
                                    }
                                    label="Depth" name="depth" />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveUnit();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <AddEditUnit {...props} navigate={navigate} params={params} />;
}
