import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Chip, Container, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getWork as getWorkService, addWork as addWorkService, editWork as editWorkService } from 'src/services/work.service';
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import { getDropdownValues } from "src/services/dropdown.service";
import { getProposals as getProposalsService } from "src/services/proposal.service";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getContractors as getContractorsService } from "src/services/contractor.service";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getTenderFinancialYears, getTenderIndentNos } from "src/services/tender.service";
import moment from "moment";

class AddEditWork extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.work_id ? 'edit' : 'add',
            work_id: props.params?.work_id,
            financial_years: [],
            indent_nos: [],
            contractor_classes: config.CONTRACTOR_CLASSES,
            inviting_authorities: [],
            approved_bys: [],
            contractors: [],
            financial_year: '',
            indent_no: '',
            proposal: null,
            work_order_date: '',
            work_order_number: '',
            agreements_date: '',
            site_handing_over_date: '',
            period: '',
            tender_award_amount: '',
            approved_by: '',
            further_security_deposit: '',
            contractor_id: '',
            contractor: null,
            error: {},
            loading: false
        }
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {

        this.setApprovedBys();
        
        if (this.state.mode == 'add') {

            this.setFinancialYears();
            this.setContractors();
        }

        if (this.state.mode == 'edit') {
            await this.getWork();
        }
    }

    async setApprovedBys() {
        let approved_bys = await getDropdownValues('approved_by');
        if (approved_bys && approved_bys.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: approved_bys.error_code ? convertMessageCodeToMessage(approved_bys.error_code) : approved_bys.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (approved_bys && approved_bys.approved_by) {
            await this.setState({
                approved_bys: approved_bys.approved_by
            })
        }
    }

    async setFinancialYears() {
        let financial_years = await getTenderFinancialYears();
        if (financial_years && financial_years.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: financial_years.error_code ? convertMessageCodeToMessage(financial_years.error_code) : financial_years.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (financial_years && financial_years.financial_year) {
            await this.setState({
                financial_years: financial_years.financial_year
            })
        }
    }

    async setIndentNos() {

        let financial_year = this.state.financial_year;
        if (!financial_year || financial_year == '') {
            return;
        }

        let indent_nos = await getTenderIndentNos({financial_year: financial_year});
        if (indent_nos && indent_nos.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: indent_nos.error_code ? convertMessageCodeToMessage(indent_nos.error_code) : indent_nos.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (indent_nos && indent_nos.indent_no) {
            await this.setState({
                indent_nos: indent_nos.indent_no
            })
        }
    }

    async setProposal() {

        if (!this.state.financial_year || !this.state.indent_no) {
            this.setState({
                proposal: null
            })
            return;
        }

        let proposal = await getProposalsService({financial_year: this.state.financial_year, indent_no: this.state.indent_no});

        if (proposal && proposal.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: proposal.error_code ? convertMessageCodeToMessage(proposal.error_code) : proposal.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (proposal && proposal.proposals && proposal.proposals.length > 0) {
            await this.setState({
                proposal: proposal.proposals[0]
            })
        } else {
            await this.setState({
                proposal: null
            })
        }

    }

    async setContractors() {
        let contractors = await getContractorsService({minimal: true});
        if (contractors && contractors.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: contractors.error_code ? convertMessageCodeToMessage(contractors.error_code) : contractors.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (contractors && contractors.contractors) {
            contractors = contractors.contractors.map((contractor) => {
                return contractor.contractor_id
            })
            await this.setState({
                contractors: contractors
            })
        }
    }

    async setContractor() {

        if (!this.state.contractor_id) {
            this.setState({
                contractor: null
            })
            return;
        }

        let contractor = await getContractorsService({contractor_id: this.state.contractor_id});

        if (contractor && contractor.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: contractor.error_code ? convertMessageCodeToMessage(contractor.error_code) : contractor.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (contractor && contractor.contractors && contractor.contractors.length > 0) {
            await this.setState({
                contractor: contractor.contractors[0]
            })
        } else {
            await this.setState({
                contractor: null
            })
        }
    }

    async getWork() {
        let response = await getWorkService(this.state.work_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.work) {
            let work = response.work;

            await this.promisedSetState({
                financial_year: work.financial_year,
                indent_no: work.indent_no,
                work_order_date: moment(work.work_order_date),
                work_order_number: work.work_order_number,
                agreements_date: moment(work.agreements_date),
                site_handing_over_date: moment(work.site_handing_over_date),
                period: work.period,
                tender_award_amount: work.tender_award_amount,
                approved_by: work.approved_by,
                further_security_deposit: work.further_security_deposit,
                contractor_id: work.contractor_id
            })

            this.setProposal();
        }
    }

    async saveWork() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {};


        payload.work_order_date = this.state.work_order_date;
        payload.work_order_number = this.state.work_order_number;
        payload.agreements_date = this.state.agreements_date;
        payload.site_handing_over_date = this.state.site_handing_over_date;
        payload.period = this.state.period;
        payload.tender_award_amount = this.state.tender_award_amount;
        payload.approved_by = this.state.approved_by;
        payload.further_security_deposit = this.state.further_security_deposit;
        payload.contractor_id = this.state.contractor_id;


        if (this.state.mode == 'add') {

            payload.financial_year = this.state.financial_year;
            payload.indent_no = this.state.indent_no;

            let response = await addWorkService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.work) {
                this.setState({
                    loading: false
                })
                this.props.navigate( '/' + localStorage.getItem('role') + '/works');
            }
        } else {

            let response = await editWorkService(this.state.work_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.work) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('work_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getWork();
            }
        }

        this.setState({
            loading: false
        })

    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'financial_year') {
            if (!this.state.financial_year || this.state.financial_year == '') {
                error.financial_year = i18n.t('this_field_is_required');
            } else {
                error.financial_year = '';
            }
        }

        if (field == null || field == 'indent_no') {
            if (!this.state.indent_no || this.state.indent_no == '') {
                error.indent_no = i18n.t('this_field_is_required');
            } else {
                error.indent_no = '';
            }
        }

        if (field == null || field == 'work_order_date') {
            if (!this.state.work_order_date || this.state.work_order_date == '') {
                error.work_order_date = i18n.t('this_field_is_required');
            } else {
                error.work_order_date = '';
            }
        }

        if (field == null || field == 'work_order_number') {
            if (!this.state.work_order_number || this.state.work_order_number == '') {
                error.work_order_number = i18n.t('this_field_is_required');
            } else {
                error.work_order_number = '';
            }
        }

        if (field == null || field == 'agreements_date') {
            if (!this.state.agreements_date || this.state.agreements_date == '') {
                error.agreements_date = i18n.t('this_field_is_required');
            } else {
                error.agreements_date = '';
            }
        }
        
        if (field == null || field == 'site_handing_over_date') {
            if (!this.state.site_handing_over_date || this.state.site_handing_over_date == '') {
                error.site_handing_over_date = i18n.t('this_field_is_required');
            } else {
                error.site_handing_over_date = '';
            }
        }

        if (field == null || field == 'period') {
            if (!this.state.period || this.state.period == '') {
                error.period = i18n.t('this_field_is_required');
            } else {
                error.period = '';
            }
        }

        if (field == null || field == 'tender_award_amount') {
            if (!this.state.tender_award_amount || this.state.tender_award_amount == '') {
                error.tender_award_amount = i18n.t('this_field_is_required');
            } else {
                error.tender_award_amount = '';
            }
        }

        if (field == null || field == 'approved_by') {
            if (!this.state.approved_by || this.state.approved_by == '') {
                error.approved_by = i18n.t('this_field_is_required');
            } else {
                error.approved_by = '';
            }
        }

        if (field == null || field == 'further_security_deposit') {
            if (!this.state.further_security_deposit || this.state.further_security_deposit == '') {
                error.further_security_deposit = i18n.t('this_field_is_required');
            } else {
                error.further_security_deposit = '';
            }
        }

        if (field == null || field == 'contractor_id') {
            if (!this.state.contractor_id || this.state.contractor_id == '') {
                error.contractor_id = i18n.t('this_field_is_required');
            } else {
                error.contractor_id = '';
            }
        }
        
        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_work') : i18n.t('edit_work')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_work') : i18n.t('edit_work')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.financial_year ? this.state.financial_year : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            financial_year: newValue,
                                            indent_no: ''
                                        })
                                        this.validateField('financial_year');
                                        this.setIndentNos();
                                        this.setProposal();
                                    }}
                                    options={this.state.financial_years}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('financial_year')} error={!!this.state?.error?.financial_year} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.financial_year}>{this.state?.error?.financial_year}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.indent_no ? this.state.indent_no : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            indent_no: newValue
                                        })
                                        this.validateField('indent_no');
                                        this.setProposal();
                                    }}
                                    options={this.state.indent_nos}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('indent_no')} error={!!this.state?.error?.indent_no} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : ((this.state.financial_year && this.state.financial_year != '') ? false : true)}
                                />
                                <FormHelperText error={!!this.state?.error?.indent_no}>{this.state?.error?.indent_no}</FormHelperText>
                            </FormControl>
                        </Grid>

                        
                        {(this.state.proposal) ? (
                            <Grid item xs={12} md={12} lg={12} sx={{mt: 2, mb: 2}}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h5">{this.state.proposal?.name_of_work}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('proposal_id')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.proposal_id}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('name_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.name_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('type_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.type_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('state')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.state}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('zone')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.zone}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('circle')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.circle}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('division')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.division}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('district')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.district}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('taluk')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.taluk}</TableCell>
                                                    </TableRow>
                                                    {(this.state.proposal?.type_of_work == 'Road Construction' || this.state.proposal?.type_of_work == 'Road Maintenance') ? (
                                                        <>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_type')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_type}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_number')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_number}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_name')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_length')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_length}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('chainage')}</TableCell>
                                                                <TableCell align="right">
                                                                    {(this.state.proposal?.chainage?.length) ? (
                                                                        this.state.proposal?.chainage.map((chainage, index) => {
                                                                            return (
                                                                                <Chip key={index} label={chainage?.chainage_from + ' - ' + chainage?.chainage_to} />
                                                                            )
                                                                        })
                                                                    ) : null}
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    ) : null}
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('head_of_account')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.head_of_account}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('admin_approval')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.admin_approval}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('technical_sanction')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.technical_sanction}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('estimated_cost')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.estimated_cost}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ) : null}


                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('work_order_date')}
                                    value={this.state.work_order_date ? this.state.work_order_date : null}
                                    onChange={async (newValue) => {
                                        await this.setState({
                                            work_order_date: newValue
                                        })
                                        if (this.state.agreements_date && this.state.agreements_date < newValue) {
                                            await this.setState({
                                                agreements_date: null,
                                                site_handing_over_date: null,
                                                date_of_opening_technical_bid: null
                                            })
                                        }
                                        this.validateField('work_order_date');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.work_order_date,
                                            error: !!this.state?.error?.work_order_date,
                                            fullWidth: true
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="work_order_number"
                                label={i18n.t('work_order_number')}
                                fullWidth
                                value={this.state.work_order_number}
                                onChange={async (e) => {
                                    await this.setState({
                                        work_order_number: e.target.value
                                    })
                                    this.validateField('work_order_number');
                                }}
                                error={!!this.state?.error?.work_order_number}
                                helperText={this.state?.error?.work_order_number}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('agreements_date')}
                                    // It should be greater than work_order_date
                                    minDate={this.state.work_order_date ? this.state.work_order_date : null}
                                    value={this.state.agreements_date ? this.state.agreements_date : null}
                                    onChange={async (newValue) => {
                                        await this.setState({
                                            agreements_date: newValue
                                        })
                                        if (this.state.site_handing_over_date && this.state.site_handing_over_date < newValue) {
                                            await this.setState({
                                                site_handing_over_date: null,
                                                date_of_opening_technical_bid: null
                                            })
                                        }
                                        this.validateField('agreements_date');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.agreements_date,
                                            error: !!this.state?.error?.agreements_date,
                                            fullWidth: true
                                        }
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : (this.state.work_order_date ? false : true)}
                                />
                            </LocalizationProvider>
                        </Grid>


                        <Grid item xs={12} md={6} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={i18n.t('site_handing_over_date')}
                                    // It should be greater than agreements_date
                                    minDate={this.state.agreements_date ? this.state.agreements_date : null}
                                    value={this.state.site_handing_over_date ? this.state.site_handing_over_date : null}
                                    onChange={async (newValue) => {
                                        await this.setState({
                                            site_handing_over_date: newValue
                                        })
                                        if (this.state.date_of_opening_technical_bid && this.state.date_of_opening_technical_bid < newValue) {
                                            await this.setState({
                                                date_of_opening_technical_bid: null
                                            })
                                        }
                                        this.validateField('site_handing_over_date');
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: this.state?.error?.site_handing_over_date,
                                            error: !!this.state?.error?.site_handing_over_date,
                                            fullWidth: true
                                        }
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : (this.state.agreements_date ? false : true)}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="period"
                                label={i18n.t('period') + ' ( ' + i18n.t('in_months') + ' )'}
                                fullWidth
                                value={this.state.period}
                                onChange={async (e) => {
                                    await this.setState({
                                        period: e.target.value
                                    })
                                    this.validateField('period');
                                }}
                                error={!!this.state?.error?.period}
                                helperText={this.state?.error?.period}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="tender_award_amount"
                                label={i18n.t('tender_award_amount') + ' ( ' + i18n.t('in') + ' ' + config.currencySymbol + ' )'}
                                fullWidth
                                value={this.state.tender_award_amount}
                                onChange={async (e) => {
                                    await this.setState({
                                        tender_award_amount: e.target.value
                                    })
                                    this.validateField('tender_award_amount');
                                }}
                                error={!!this.state?.error?.tender_award_amount}
                                helperText={this.state?.error?.tender_award_amount}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.approved_by ? this.state.approved_by : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            approved_by: newValue
                                        })
                                        this.validateField('approved_by');
                                    }}
                                    options={this.state.approved_bys}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('approved_by')} error={!!this.state?.error?.approved_by} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.approved_by}>{this.state?.error?.approved_by}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="further_security_deposit"
                                label={i18n.t('further_security_deposit') + ' ( ' + i18n.t('in') + ' ' + config.currencySymbol + ' )'}
                                fullWidth
                                value={this.state.further_security_deposit}
                                onChange={async (e) => {
                                    await this.setState({
                                        further_security_deposit: e.target.value
                                    })
                                    this.validateField('further_security_deposit');
                                }}
                                error={!!this.state?.error?.further_security_deposit}
                                helperText={this.state?.error?.further_security_deposit}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.contractor_id ? this.state.contractor_id : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            contractor_id: newValue
                                        })
                                        this.validateField('contractor_id');
                                        this.setContractor();
                                    }}
                                    options={this.state.contractors}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('contractor_id')} error={!!this.state?.error?.contractor_id} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.contractor_id}>{this.state?.error?.contractor_id}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {(this.state.contractor) ? (
                            <Grid item xs={12} md={12} lg={12} sx={{mt: 2, mb: 2}}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h5">{this.state.contractor?.contractor_id}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('contractor_reg_no')}</TableCell>
                                                        <TableCell align="right">{this.state.contractor?.contractor_reg_no}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('full_name')}</TableCell>
                                                        <TableCell align="right">{this.state.contractor?.full_name}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('address')}</TableCell>
                                                        <TableCell align="right">{this.state.contractor?.address}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('phone_number')}</TableCell>
                                                        <TableCell align="right">{this.state.contractor?.phone_number}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('pan_number')}</TableCell>
                                                        <TableCell align="right">{this.state.contractor?.pan_number}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('gst_number')}</TableCell>
                                                        <TableCell align="right">{this.state.contractor?.gst_number}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('bank_name')}</TableCell>
                                                        <TableCell align="right">{this.state.contractor?.bank_name}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('bank_address')}</TableCell>
                                                        <TableCell align="right">{this.state.contractor?.bank_address}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('ifsc_code')}</TableCell>
                                                        <TableCell align="right">{this.state.contractor?.ifsc_code}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('account_number')}</TableCell>
                                                        <TableCell align="right">{this.state.contractor?.account_number}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ) : null}

                        
                    </Grid>

                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveWork();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
    const params = useParams();
  
    return <AddEditWork {...props} navigate={navigate} params={params} />;
}
