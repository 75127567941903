import axios from 'axios'
import config from '../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getScheculeRates = async (query=null) => {
    try {

        let headers = getRequestHeaders();

        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/scheduleofrates' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getScheculeRate = async (schedulerateId) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/scheduleofrates/' + schedulerateId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addScheculeRate = async (payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/scheduleofrates', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editScheculeRate = async (schedulerateId, payload) => {
    try {

        let headers = getRequestHeaders();

        let response;
        try {
            response = await axios.patch( config.SERVER_BASE_URL + '/scheduleofrates/' + schedulerateId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getScheculeRates,
    getScheculeRate,
    addScheculeRate,
    editScheculeRate
}