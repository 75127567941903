import { Autocomplete, Button, Container, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getProposal as getProposalService, addProposal as addProposalService, editProposal as editProposalService } from 'src/services/proposal.service';
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import { getDropdownValues } from "src/services/dropdown.service";
import {
    getStates as getStatesService,
    getZones as getZonesService,
    getCircles as getCirclesService,
    getDivisions as getDivisionsService,
    getDistricts as getDistrictsService,
    getTaluks as getTaluksService
} from "src/services/accessInfo.service";
import { getRoadTypes as getRoadTypesService, getRoads as getRoadsService } from "src/services/road.service";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

class AddEditProposal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.prop_id ? 'edit' : 'add',
            prop_id: props.params?.prop_id,
            type_of_works: [],
            states: [],
            zones: [],
            circles: [],
            divisions: [],
            districts: [],
            taluks: [],
            road_types: [],
            roads: [],
            financial_years: [],
            head_of_accounts: [],
            admin_approvals: [],
            technical_sanctions: [],
            indent_no: '',
            proposal_id: '',
            name_of_work: '',
            type_of_work: '',
            state: '',
            zone: '',
            circle: '',
            division: '',
            district: '',
            taluk: '',
            road_type: '',
            road: '',
            chainage: [],
            financial_year: '',
            head_of_account: '',
            admin_approval: '',
            approval_no: '',
            technical_sanction: '',
            technical_sanction_no: '',
            estimated_cost: '',
            error: {},
            loading: false
        }
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {

        this.setHeadOfAccounts();
        this.setAdminApprovals();
        this.setTechnicalSanctions();

        if (this.state.mode == 'add') {
            this.setStates();
            this.setTypeOfWorks();
            this.setFinancialYears();
        }

        if (this.state.mode == 'edit') {
            await this.getProposal();
            this.setTypeOfWorks(false);
            this.setStates(false);
            this.setZones(false);
            this.setCircles(false);
            this.setDivisions(false);
            this.setDistricts(false);
            this.setTaluks(false);
            this.setRoadTypes(false);
            this.setRoads(false);
        }
    }

    async setStates(clear_existing=true) {
        let response = await getStatesService();
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.states) {
            if (clear_existing) {
                await this.promisedSetState({
                    states: response.states,
                    zones: [],
                    circles: [],
                    divisions: [],
                    districts: [],
                    taluks: [],
                    road_types: [],
                    roads: [],
                    state: null,
                    zone: null,
                    circle: null,
                    division: null,
                    district: null,
                    taluk: null,
                    road_type: null,
                    road: null,
                    chainage: []
                })
            } else {
                await this.promisedSetState({
                    states: response.states
                })
            }
        }
    }

    async setZones(clear_existing=true) {

        if (!this.state.state) {
            this.setState({
                zones: [],
                circles: [],
                divisions: [],
                districts: [],
                taluks: [],
                road_types: [],
                roads: [],
                zone: null,
                circle: null,
                division: null,
                district: null,
                taluk: null,
                road_type: null,
                road: null,
                chainage: []
            })
            return;
        }

        let response = await getZonesService({
            state: this.state.state
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.zones) {
            if (clear_existing) {
                await this.promisedSetState({
                    zones: response.zones,
                    circles: [],
                    divisions: [],
                    districts: [],
                    taluks: [],
                    road_types: [],
                    roads: [],
                    zone: null,
                    circle: null,
                    division: null,
                    district: null,
                    taluk: null,
                    road_type: null,
                    road: null,
                    chainage: []
                })
            } else {
                await this.promisedSetState({
                    zones: response.zones
                })
            }
        }
    }

    async setCircles(clear_existing=true) {

        if (!this.state.state || !this.state.zone) {
            this.setState({
                circles: [],
                divisions: [],
                districts: [],
                taluks: [],
                road_types: [],
                roads: [],
                circle: null,
                division: null,
                district: null,
                taluk: null,
                road_type: null,
                road: null,
                chainage: []
            })
            return;
        }

        let response = await getCirclesService({
            state: this.state.state,
            zone: this.state.zone
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.circles) {
            if(clear_existing) {
                await this.promisedSetState({
                    circles: response.circles,
                    divisions: [],
                    districts: [],
                    taluks: [],
                    road_types: [],
                    roads: [],
                    circle: null,
                    division: null,
                    district: null,
                    taluk: null,
                    road_type: null,
                    road: null,
                    chainage: []
                })
            } else {
                await this.promisedSetState({
                    circles: response.circles
                })
            }
        }
    }

    async setDivisions(clear_existing=true) {

        if (!this.state.state || !this.state.zone || !this.state.circle) {
            this.setState({
                divisions: [],
                districts: [],
                taluks: [],
                road_types: [],
                roads: [],
                division: null,
                district: null,
                taluk: null,
                road_type: null,
                road: null,
                chainage: []
            })
            return;
        }

        let response = await getDivisionsService({
            state: this.state.state,
            zone: this.state.zone,
            circle: this.state.circle
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.divisions) {
            if(clear_existing) {
                await this.promisedSetState({
                    divisions: response.divisions,
                    districts: [],
                    taluks: [],
                    road_types: [],
                    roads: [],
                    division: null,
                    district: null,
                    taluk: null,
                    road_type: null,
                    road: null,
                    chainage: []
                })
            } else {
                await this.promisedSetState({
                    divisions: response.divisions
                })
            }
        }
    }

    async setDistricts(clear_existing=true) {

        if (!this.state.state || !this.state.zone || !this.state.circle || !this.state.division) {
            this.setState({
                districts: [],
                taluks: [],
                road_types: [],
                roads: [],
                district: null,
                taluk: null,
                road_type: null,
                road: null,
                chainage: []
            })
            return;
        }

        let response = await getDistrictsService({
            state: this.state.state,
            zone: this.state.zone,
            circle: this.state.circle,
            division: this.state.division
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.districts) {
            if(clear_existing) {
                await this.promisedSetState({
                    districts: response.districts,
                    taluks: [],
                    road_types: [],
                    roads: [],
                    district: null,
                    taluk: null,
                    road_type: null,
                    road: null,
                    chainage: []
                })
            } else {
                await this.promisedSetState({
                    districts: response.districts
                })
            }
        }
    }

    async setTaluks(clear_existing=true) {

        if (!this.state.state || !this.state.zone || !this.state.circle || !this.state.division || !this.state.district) {
            this.setState({
                taluks: [],
                road_types: [],
                roads: [],
                taluk: null,
                road_type: null,
                road: null,
                chainage: []
            })
            return;
        }

        let response = await getTaluksService({
            state: this.state.state,
            zone: this.state.zone,
            circle: this.state.circle,
            division: this.state.division,
            district: this.state.district
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.taluks) {
            if(clear_existing) {
                await this.promisedSetState({
                    taluks: response.taluks,
                    road_types: [],
                    roads: [],
                    taluk: null,
                    road_type: null,
                    road: null,
                    chainage: []
                })
            } else {
                await this.promisedSetState({
                    taluks: response.taluks
                })
            }
        }
    }

    async setRoadTypes(clear_existing=true) {
        if (!this.state.state || !this.state.zone || !this.state.circle || !this.state.division || !this.state.district || !this.state.taluk) {
            this.setState({
                road_types: [],
                roads: [],
                road_type: null,
                road: null,
                chainage: []
            })
            return;
        }

        let response = await getRoadTypesService({
            state: this.state.state,
            zone: this.state.zone,
            circle: this.state.circle,
            division: this.state.division,
            district: this.state.district,
            taluk: this.state.taluk
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.road_types) {
            if(clear_existing) {
                await this.promisedSetState({
                    road_types: response.road_types,
                    roads: [],
                    road_type: null,
                    road: null,
                    chainage: []
                })
            } else {
                await this.promisedSetState({
                    road_types: response.road_types
                })
            }
        }
    }

    async setRoads(clear_existing=true) {
        if (!this.state.state || !this.state.zone || !this.state.circle || !this.state.division || !this.state.district || !this.state.taluk || !this.state.road_type) {
            this.setState({
                roads: [],
                road: null,
                chainage: []
            })
            return;
        }

        let response = await getRoadsService({
            state: this.state.state,
            zone: this.state.zone,
            circle: this.state.circle,
            division: this.state.division,
            district: this.state.district,
            taluk: this.state.taluk,
            road_type: this.state.road_type
        });
        if(response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if(response && response.roads) {

            let roads = response.roads.map((road) => {
                return {
                    id: road.risid,
                    label: '[ ' + road.risid + ' ] ' + road.road_number + ' - ' + road.road_name,
                    info: road
                }
            })

            if(clear_existing) {
                await this.promisedSetState({
                    roads: roads,
                    road: null,
                    chainage: []
                })
            } else {
                await this.promisedSetState({
                    roads: roads
                })
            }
        }
    }

    async setTypeOfWorks(clear_existing=true) {
        let type_of_work = await getDropdownValues('type_of_work');
        if (type_of_work && type_of_work.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: type_of_work.error_code ? convertMessageCodeToMessage(type_of_work.error_code) : type_of_work.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (type_of_work && type_of_work.type_of_work) {
            if (clear_existing) {
                await this.promisedSetState({
                    type_of_works: type_of_work.type_of_work
                })
            } else {
                await this.promisedSetState({
                    type_of_works: type_of_work.type_of_work
                })
            }
        }
    }

    async setFinancialYears() {
        let financial_years = await getDropdownValues('financial_year');
        if (financial_years && financial_years.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: financial_years.error_code ? convertMessageCodeToMessage(financial_years.error_code) : financial_years.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (financial_years && financial_years.financial_year) {
            await this.setState({
                financial_years: financial_years.financial_year
            })
        }
    }

    async setHeadOfAccounts() {
        let head_of_accounts = await getDropdownValues('head_of_account');
        if (head_of_accounts && head_of_accounts.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: head_of_accounts.error_code ? convertMessageCodeToMessage(head_of_accounts.error_code) : head_of_accounts.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (head_of_accounts && head_of_accounts.head_of_account) {
            await this.setState({
                head_of_accounts: head_of_accounts.head_of_account
            })
        }
    }

    async setAdminApprovals() {
        let admin_approvals = await getDropdownValues('admin_approval');
        if (admin_approvals && admin_approvals.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: admin_approvals.error_code ? convertMessageCodeToMessage(admin_approvals.error_code) : admin_approvals.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (admin_approvals && admin_approvals.admin_approval) {
            await this.setState({
                admin_approvals: admin_approvals.admin_approval
            })
        }
    }

    async setTechnicalSanctions() {
        let technical_sanctions = await getDropdownValues('technical_sanction');
        if (technical_sanctions && technical_sanctions.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: technical_sanctions.error_code ? convertMessageCodeToMessage(technical_sanctions.error_code) : technical_sanctions.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (technical_sanctions && technical_sanctions.technical_sanction) {
            await this.setState({
                technical_sanctions: technical_sanctions.technical_sanction
            })
        }
    }

    async getProposal() {
        let response = await getProposalService(this.state.prop_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.proposal) {
            let proposal = response.proposal;

            await this.promisedSetState({
                indent_no: proposal.indent_no,
                proposal_id: proposal.proposal_id,
                name_of_work: proposal.name_of_work,
                type_of_work: proposal.type_of_work,
                state: proposal.state,
                zone: proposal.zone,
                circle: proposal.circle,
                division: proposal.division,
                district: proposal.district,
                taluk: proposal.taluk,
                road_type: proposal.road_type,
                road: {
                    id: proposal.risid,
                    label: '[ ' + proposal.risid + ' ] ' + proposal.road_number + ' - ' + proposal.road_name,
                    info: {
                        road_name: proposal.road_name,
                        road_length: proposal.road_length,
                        risid: proposal.risid,
                        road_number: proposal.road_number
                    }
                },
                chainage: proposal.chainage,
                financial_year: proposal.financial_year,
                head_of_account: proposal.head_of_account,
                admin_approval: proposal.admin_approval,
                approval_no: proposal.approval_no,
                technical_sanction: proposal.technical_sanction,
                technical_sanction_no: proposal.technical_sanction_no,
                estimated_cost: proposal.estimated_cost
            })
        }
    }

    async saveProposal() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {};

        payload.indent_no = this.state.indent_no;
        payload.proposal_id = this.state.proposal_id;
        payload.name_of_work = this.state.name_of_work;
        payload.type_of_work = this.state.type_of_work;
        payload.state = this.state.state;
        payload.zone = this.state.zone;
        payload.circle = this.state.circle;
        payload.division = this.state.division;
        payload.district = this.state.district;
        payload.taluk = this.state.taluk;
        if (this.state.type_of_work == 'Road Construction' || this.state.type_of_work == 'Road Maintenance') {
            payload.road_type = this.state.road_type;
            payload.road_name = this.state.road?.info?.road_name;
            payload.road_length = this.state.road?.info?.road_length;
            payload.risid = this.state.road?.info?.risid;
            payload.road_number = this.state.road?.info?.road_number;
            payload.chainage = this.state.chainage;
        } else {
            payload.road_type = null;
            payload.road_name = null;
            payload.road_length = null;
            payload.risid = null;
            payload.road_number = null;
            payload.chainage = null;
        }
        payload.financial_year = this.state.financial_year;
        payload.head_of_account = this.state.head_of_account;
        payload.admin_approval = this.state.admin_approval;
        payload.approval_no = this.state.approval_no;
        payload.technical_sanction = this.state.technical_sanction;
        payload.technical_sanction_no = this.state.technical_sanction_no;
        payload.estimated_cost = this.state.estimated_cost;


        if (this.state.mode == 'add') {

            let response = await addProposalService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.proposal) {
                this.setState({
                    loading: false
                })
                this.props.navigate( '/' + localStorage.getItem('role') + '/proposals');
            }
        } else {

            let response = await editProposalService(this.state.prop_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.proposal) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('proposal_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getProposal();
            }
        }

    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'indent_no') {
            if (!this.state.indent_no || this.state.indent_no == '') {
                error.indent_no = i18n.t('this_field_is_required');
            } else {
                error.indent_no = '';
            }
        }

        if (field == null || field == 'proposal_id') {
            if (!this.state.proposal_id || this.state.proposal_id == '') {
                error.proposal_id = i18n.t('this_field_is_required');
            } else {
                error.proposal_id = '';
            }
        }

        if (field == null || field == 'name_of_work') {
            if (!this.state.name_of_work || this.state.name_of_work == '') {
                error.name_of_work = i18n.t('this_field_is_required');
            } else {
                error.name_of_work = '';
            }
        }

        if (field == null || field == 'type_of_work') {
            if (!this.state.type_of_work || this.state.type_of_work == '') {
                error.type_of_work = i18n.t('this_field_is_required');
            } else {
                error.type_of_work = '';
            }
        }
        
        if (field == null || field == 'state') {
            if (!this.state.state || this.state.state == '') {
                error.state = i18n.t('this_field_is_required');
            } else {
                error.state = '';
            }
        }
        
        if (field == null || field == 'zone') {
            if (!this.state.zone || this.state.zone == '') {
                error.zone = i18n.t('this_field_is_required');
            } else {
                error.zone = '';
            }
        }
        
        if (field == null || field == 'circle') {
            if (!this.state.circle || this.state.circle == '') {
                error.circle = i18n.t('this_field_is_required');
            } else {
                error.circle = '';
            }
        }
        
        if (field == null || field == 'division') {
            if (!this.state.division || this.state.division == '') {
                error.division = i18n.t('this_field_is_required');
            } else {
                error.division = '';
            }
        }

        if (field == null || field == 'district') {
            if (!this.state.district || this.state.district == '') {
                error.district = i18n.t('this_field_is_required');
            } else {
                error.district = '';
            }
        }

        if (field == null || field == 'taluk') {
            if (!this.state.taluk || this.state.taluk == '') {
                error.taluk = i18n.t('this_field_is_required');
            } else {
                error.taluk = '';
            }
        }
        
        if (field == null || field == 'financial_year') {
            if (!this.state.financial_year || this.state.financial_year == '') {
                error.financial_year = i18n.t('this_field_is_required');
            } else {
                error.financial_year = '';
            }
        }
        
        if (field == null || field == 'head_of_account') {
            if (!this.state.head_of_account || this.state.head_of_account == '') {
                error.head_of_account = i18n.t('this_field_is_required');
            } else {
                error.head_of_account = '';
            }
        }
        
        if (field == null || field == 'admin_approval') {
            if (!this.state.admin_approval || this.state.admin_approval == '') {
                error.admin_approval = i18n.t('this_field_is_required');
            } else {
                error.admin_approval = '';
            }
        }
        
        if (field == null || field == 'approval_no') {
            if (!this.state.approval_no || this.state.approval_no == '') {
                error.approval_no = i18n.t('this_field_is_required');
            } else {
                error.approval_no = '';
            }
        }
        
        if (field == null || field == 'technical_sanction') {
            if (!this.state.technical_sanction || this.state.technical_sanction == '') {
                error.technical_sanction = i18n.t('this_field_is_required');
            } else {
                error.technical_sanction = '';
            }
        }
        
        if (field == null || field == 'technical_sanction_no') {
            if (!this.state.technical_sanction_no || this.state.technical_sanction_no == '') {
                error.technical_sanction_no = i18n.t('this_field_is_required');
            } else {
                error.technical_sanction_no = '';
            }
        }
        
        if (field == null || field == 'estimated_cost') {
            if (!this.state.estimated_cost || this.state.estimated_cost == '') {
                error.estimated_cost = i18n.t('this_field_is_required');
            } else {
                error.estimated_cost = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_proposal') : i18n.t('edit_proposal')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_proposal') : i18n.t('edit_proposal')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                name="indent_no"
                                label={i18n.t('indent_no')}
                                fullWidth
                                value={this.state.indent_no}
                                onChange={async (e) => {
                                    await this.setState({
                                        indent_no: e.target.value
                                    })
                                    this.validateField('indent_no');
                                }}
                                error={!!this.state?.error?.indent_no}
                                helperText={this.state?.error?.indent_no}
                                disabled={(this.state.mode == 'edit') ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                name="proposal_id"
                                label={i18n.t('proposal_id')}
                                fullWidth
                                value={this.state.proposal_id}
                                onChange={async (e) => {
                                    await this.setState({
                                        proposal_id: e.target.value
                                    })
                                    this.validateField('proposal_id');
                                }}
                                error={!!this.state?.error?.proposal_id}
                                helperText={this.state?.error?.proposal_id}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                name="name_of_work"
                                label={i18n.t('name_of_work')}
                                fullWidth
                                value={this.state.name_of_work}
                                onChange={async (e) => {
                                    await this.setState({
                                        name_of_work: e.target.value
                                    })
                                    this.validateField('name_of_work');
                                }}
                                error={!!this.state?.error?.name_of_work}
                                helperText={this.state?.error?.name_of_work}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.type_of_work ? this.state.type_of_work : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            type_of_work: newValue
                                        })
                                        this.validateField('type_of_work');
                                    }}
                                    options={this.state.type_of_works}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('type_of_work')} error={!!this.state?.error?.type_of_work} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.type_of_work}>{this.state?.error?.type_of_work}</FormHelperText>
                            </FormControl>
                        </Grid>
                        
                            <Grid container spacing={1} style={{margin: 1}}>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            value={this.state?.state ? this.state.state : null}
                                            onChange={async (e, newValue) => {
                                                await this.setState({
                                                    state: newValue
                                                })
                                                this.validateField('state');
                                                this.setZones();
                                            }}
                                            options={this.state.states}
                                            renderInput={(params) => <TextField {...params} label={i18n.t('state')} error={!!this.state?.error?.state} />}
                                            getOptionLabel={(option) => {
                                                return option;
                                            }}
                                        />
                                        <FormHelperText error={!!this.state?.error?.state}>{this.state?.error?.state}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                {(this.state?.state?.length) ? (
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={this.state.zone ? this.state.zone : null}
                                                onChange={async (e, newValue) => {
                                                    await this.setState({
                                                        zone: newValue
                                                    })
                                                    this.validateField('zone');
                                                    this.setCircles();
                                                }}
                                                options={this.state.zones}
                                                renderInput={(params) => <TextField {...params} label={i18n.t('zone')} error={!!this.state?.error?.zone} />}
                                                getOptionLabel={(option) => {
                                                    return option;
                                                }}
                                            />
                                            <FormHelperText error={!!this.state?.error?.zone}>{this.state?.error?.zone}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                ) : null}

                                {(this.state?.zone?.length) ? (
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={this.state.circle ? this.state.circle : null}
                                                onChange={async (e, newValue) => {
                                                    await this.setState({
                                                        circle: newValue
                                                    })
                                                    this.validateField('circle');
                                                    this.setDivisions();
                                                }}
                                                options={this.state.circles}
                                                renderInput={(params) => <TextField {...params} label={i18n.t('circle')} error={!!this.state?.error?.circle} />}
                                                getOptionLabel={(option) => {
                                                    return option;
                                                }}
                                            />
                                            <FormHelperText error={!!this.state?.error?.circle}>{this.state?.error?.circle}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                ) : null}

                                {(this.state?.circle?.length) ? (
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={this.state.division ? this.state.division : null}
                                                onChange={async (e, newValue) => {
                                                    await this.setState({
                                                        division: newValue
                                                    })
                                                    this.validateField('division');
                                                    this.setDistricts();
                                                }}
                                                options={this.state.divisions}
                                                renderInput={(params) => <TextField {...params} label={i18n.t('division')} error={!!this.state?.error?.division} />}
                                                getOptionLabel={(option) => {
                                                    return option;
                                                }}
                                            />
                                            <FormHelperText error={!!this.state?.error?.division}>{this.state?.error?.division}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                ) : null}

                                {(this.state?.division?.length) ? (
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={this.state.district ? this.state.district : null}
                                                onChange={async (e, newValue) => {
                                                    await this.setState({
                                                        district: newValue
                                                    })
                                                    this.validateField('district');
                                                    this.setTaluks();
                                                }}
                                                options={this.state.districts}
                                                renderInput={(params) => <TextField {...params} label={i18n.t('district')} error={!!this.state?.error?.district} />}
                                                getOptionLabel={(option) => {
                                                    return option;
                                                }}
                                            />
                                            <FormHelperText error={!!this.state?.error?.district}>{this.state?.error?.district}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                ) : null}

                                {(this.state?.district?.length) ? (
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={this.state.taluk ? this.state.taluk : null}
                                                onChange={async (e, newValue) => {
                                                    await this.setState({
                                                        taluk: newValue
                                                    })
                                                    this.validateField('taluk');
                                                    this.setRoadTypes();
                                                }}
                                                options={this.state.taluks}
                                                renderInput={(params) => <TextField {...params} label={i18n.t('taluk')} error={!!this.state?.error?.taluk} />}
                                                getOptionLabel={(option) => {
                                                    return option;
                                                }}
                                            />
                                            <FormHelperText error={!!this.state?.error?.taluk}>{this.state?.error?.taluk}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                ) : null}

                            </Grid>
                        {(this.state.type_of_work == 'Road Construction' || this.state.type_of_work == 'Road Maintenance') ? (
                            <Grid container spacing={1} style={{margin: 1}}>
                                {(this.state?.taluk?.length) ? (
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={this.state.road_type ? this.state.road_type : null}
                                                onChange={async (e, newValue) => {
                                                    await this.setState({
                                                        road_type: newValue
                                                    })
                                                    this.validateField('road_type');
                                                    this.setRoads();
                                                }}
                                                options={this.state.road_types}
                                                renderInput={(params) => <TextField {...params} label={i18n.t('road_type')} error={!!this.state?.error?.road_type} />}
                                                getOptionLabel={(option) => {
                                                    return option;
                                                }}
                                            />
                                            <FormHelperText error={!!this.state?.error?.road_type}>{this.state?.error?.road_type}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                ) : null}
                                
                                {(this.state?.road_type?.length) ? (
                                    <Grid item xs={12} md={12} lg={12} xl={12}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={this.state.road ? this.state.road : null}
                                                onChange={async (e, newValue) => {
                                                    await this.setState({
                                                        road: newValue
                                                    })
                                                    this.validateField('road');
                                                }}
                                                options={this.state.roads}
                                                renderInput={(params) => <TextField {...params} label={i18n.t('road')} error={!!this.state?.error?.road} />}
                                                getOptionLabel={(option) => {
                                                    return option.label;
                                                }}
                                            />
                                            <FormHelperText error={!!this.state?.error?.road}>{this.state?.error?.road}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                ) : null}

                                {(this.state?.road?.info?.road_length) ? (
                                    <>
                                        <Grid item xs={12} md={6} lg={4} xl={3}>
                                            <TextField
                                                name="road_length"
                                                label={i18n.t('road_length')}
                                                fullWidth
                                                value={this.state?.road?.info?.road_length}
                                                error={!!this.state?.error?.road_length}
                                                helperText={this.state?.error?.road_length}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} sx={{mt: 3}}>
                                            <Typography variant="h6" gutterBottom>
                                                {i18n.t('chainage')}
                                            </Typography>
                                            <Divider />
                                        </Grid>

                                        {this.state.chainage.map((chainage, index) => {
                                            return (
                                                <>
                                                    <Grid item xs={12} md={6} lg={4} xl={4}>
                                                        <TextField
                                                            name="chainage_from"
                                                            label={i18n.t('chainage_from')}
                                                            fullWidth
                                                            value={chainage.chainage_from}
                                                            onChange={async (e) => {
                                                                let chainage = this.state.chainage;
                                                                chainage[index].chainage_from = e.target.value;
                                                                await this.setState({
                                                                    chainage: chainage
                                                                })
                                                                this.validateField('chainage');
                                                            }}
                                                            size="small"
                                                            error={!!this.state?.error?.chainage?.[index]?.chainage_from}
                                                            helperText={this.state?.error?.chainage?.[index]?.chainage_from}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4} xl={4}>
                                                        <TextField
                                                            name="chainage_to"
                                                            label={i18n.t('chainage_to')}
                                                            fullWidth
                                                            value={chainage.chainage_to}
                                                            onChange={async (e) => {
                                                                let chainage = this.state.chainage;
                                                                chainage[index].chainage_to = e.target.value;
                                                                await this.setState({
                                                                    chainage: chainage
                                                                })
                                                                this.validateField('chainage');
                                                            }}
                                                            size="small"
                                                            error={!!this.state?.error?.chainage?.[index]?.chainage_to}
                                                            helperText={this.state?.error?.chainage?.[index]?.chainage_to}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4} xl={4} justifyContent={"center"}>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() => {
                                                                let chainage = this.state.chainage;
                                                                chainage.splice(index, 1);
                                                                this.setState({
                                                                    chainage: chainage
                                                                })
                                                            }}
                                                            style={{ margin: 'auto' }}
                                                        >
                                                            <DeleteForeverIcon />
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )
                                        })}


                                        <Grid item xs={12} md={4} lg={4} xl={4} sx={{mb: 3}}>
                                            <Button variant="contained" color="primary" onClick={() => {
                                                let chainage = this.state.chainage;
                                                chainage.push({
                                                    chainage_from: '',
                                                    chainage_to: ''
                                                })
                                                this.setState({
                                                    chainage: chainage
                                                })
                                            }}>
                                                {i18n.t('add_chainage')}
                                            </Button>
                                        </Grid>
                                    </>
                                ) : null}

                            </Grid>
                        ) : null}
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.financial_year ? this.state.financial_year : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            financial_year: newValue
                                        })
                                        this.validateField('financial_year');
                                    }}
                                    options={this.state.financial_years}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('financial_year')} error={!!this.state?.error?.financial_year} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={(this.state.mode == 'edit') ? true : false}
                                />
                                <FormHelperText error={!!this.state?.error?.financial_year}>{this.state?.error?.financial_year}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.head_of_account ? this.state.head_of_account : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            head_of_account: newValue
                                        })
                                        this.validateField('head_of_account');
                                    }}
                                    options={this.state.head_of_accounts}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('head_of_account')} error={!!this.state?.error?.head_of_account} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.head_of_account}>{this.state?.error?.head_of_account}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.admin_approval ? this.state.admin_approval : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            admin_approval: newValue
                                        })
                                        this.validateField('admin_approval');
                                    }}
                                    options={this.state.admin_approvals}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('admin_approval')} error={!!this.state?.error?.admin_approval} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.admin_approval}>{this.state?.error?.admin_approval}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="approval_no"
                                label={i18n.t('approval_no')}
                                fullWidth
                                value={this.state.approval_no}
                                onChange={async (e) => {
                                    await this.setState({
                                        approval_no: e.target.value
                                    })
                                    this.validateField('approval_no');
                                }}
                                error={!!this.state?.error?.approval_no}
                                helperText={this.state?.error?.approval_no}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.technical_sanction ? this.state.technical_sanction : null}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            technical_sanction: newValue
                                        })
                                        this.validateField('technical_sanction');
                                    }}
                                    options={this.state.technical_sanctions}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('technical_sanction')} error={!!this.state?.error?.technical_sanction} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.technical_sanction}>{this.state?.error?.technical_sanction}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="technical_sanction_no"
                                label={i18n.t('technical_sanction_no')}
                                fullWidth
                                value={this.state.technical_sanction_no}
                                onChange={async (e) => {
                                    await this.setState({
                                        technical_sanction_no: e.target.value
                                    })
                                    this.validateField('technical_sanction_no');
                                }}
                                error={!!this.state?.error?.technical_sanction_no}
                                helperText={this.state?.error?.technical_sanction_no}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="estimated_cost"
                                label={i18n.t('estimated_cost')}
                                fullWidth
                                value={this.state.estimated_cost}
                                onChange={async (e) => {
                                    await this.setState({
                                        estimated_cost: e.target.value
                                    })
                                    this.validateField('estimated_cost');
                                }}
                                error={!!this.state?.error?.estimated_cost}
                                helperText={this.state?.error?.estimated_cost}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveProposal();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
    const params = useParams();
  
    return <AddEditProposal {...props} navigate={navigate} params={params} />;
}
