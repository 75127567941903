/**Read this
 * Backend connection needed
 */

import { Autocomplete, Button, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getScheculeRate as getScheculeRateService, addScheculeRate as addScheculeRateService, editScheculeRate as editScheculeRateService } from 'src/services/schedulerate.service';
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";

class AddEditScheduleRate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.schedulerate_id ? 'edit' : 'add',
            schedulerate_id: props.params?.schedulerate_id,
            state: '',
            department: '',
            sr_code: '',
            unique_code: '',
            category: '',
            sub_category: '',
            company_gst_number: '',
            item_description: '',
            units: '',
            basic_rate: '',
            page_no: '',
            year: '',
            status: 'active',
            error: {},
            loading: false
        }
    }

    async componentDidMount() {

        if (this.state.mode == 'edit') {
            await this.getScheculeRate();
        }
    }

    async getScheculeRate() {
        let response = await getScheculeRateService(this.state.schedulerate_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.schedulerate) {
            let schedulerate = response.schedulerate;

            await this.setState({
                //new fields
                state: schedulerate?.state ? schedulerate.state : '',
                department: schedulerate?.department ? schedulerate.department : '',
                sr_code: schedulerate?.sr_code ? schedulerate.sr_code : '',
                unique_code: schedulerate?.unique_code ? schedulerate.unique_code : '',
                category: schedulerate?.category ? schedulerate.category : '',
                sub_category: schedulerate?.sub_category ? schedulerate.sub_category : '',
                company_gst_number: schedulerate?.company_gst_number ? schedulerate.company_gst_number : '',
                item_description: schedulerate?.item_description ? schedulerate.item_description : '',
                units: schedulerate?.units ? schedulerate.units : '',
                basic_rate: schedulerate?.basic_rate ? schedulerate.basic_rate : '',
                page_no: schedulerate?.page_no ? schedulerate.page_no : '',
                year: schedulerate?.year ? schedulerate.year : '',
            })
        }
    }

    async saveScheduleRate() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {
            name: this.state.name,
            state: this.state.state,
            department: this.state.department,
            sr_code: this.state.sr_code,
            unique_code: this.state.unique_code,
            category: this.state.category,
            sub_category: this.state.sub_category,
            company_gst_number: this.state.company_gst_number,
            item_description: this.state.item_description,
            units: this.state.units,
            basic_rate: this.state.basic_rate,
            page_no: this.state.page_no,
            year: this.state.page_no,
        }

        if (this.state.mode == 'add') {
            let response = await addScheculeRateService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.company) {
                this.setState({
                    loading: false
                })
                this.props.navigate('/' + localStorage.getItem('role') + '/scheduleofrates');
            }
        } else {
            let response = await editScheculeRateService(this.state.schedulerate_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.company) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('schedulerate_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getScheculeRate();
            }
        }

    }

    async isFormValid() {
        let error = await this.validateField();
        console.log("Error", error)
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field = null) {

        let error = this.state.error;

        // state validation
        if (field == null || field == 'state') {
            if (!this.state.state || this.state.state == '') {
                error.state = i18n.t('this_field_is_required');
            } else {
                error.state = '';
            }
        }

        // contact frist name validation
        if (field == null || field == 'department') {
            if (!this.state.department || this.state.department == '') {
                error.department = i18n.t('this_field_is_required');
            } else {
                error.department = '';
            }
        }

        // contact last name validation
        if (field == null || field == 'sr_code') {
            if (!this.state.sr_code || this.state.sr_code == '') {
                error.sr_code = i18n.t('this_field_is_required');
            } else {
                error.sr_code = '';
            }
        }

        // contact email validation
        if (field == null || field == 'unique_code') {
            if (!this.state.unique_code || this.state.unique_code == '') {
                error.unique_code = i18n.t('this_field_is_required');
            } else {
                error.unique_code = '';
            }
        }


        // category validation
        if (field == null || field == 'category') {
            if (!this.state.category || this.state.category == '') {
                error.category = i18n.t('this_field_is_required');
            } else {
                error.category = '';
            }
        }

        // sub_category validation
        if (this.state.mode === 'add' && field == null || field == 'sub_category') {
            if (!this.state.sub_category || this.state.sub_category == '') {
                error.sub_category = i18n.t('this_field_is_required');
            } else {
                error.sub_category = '';
            }
        }

        // item_description validation
        if (field == null || field == 'item_description') {
            if (!this.state.item_description || this.state.item_description == '') {
                error.item_description = i18n.t('this_field_is_required');
            } else {
                error.item_description = '';
            }
        }

        // units validation
        if (field == null || field == 'units') {
            if (!this.state.units || this.state.units == '') {
                error.units = i18n.t('this_field_is_required');
            } else {
                error.units = '';
            }
        }

        // basic_rate validation
        if (field == null || field == 'basic_rate') {
            if (!this.state.basic_rate || this.state.basic_rate == '') {
                error.basic_rate = i18n.t('this_field_is_required');
            } else {
                error.basic_rate = '';
            }
        }

        // page_no validation
        if (field == null || field == 'page_no') {
            if (!this.state.page_no || this.state.page_no == '') {
                error.page_no = i18n.t('this_field_is_required');
            } else {
                error.page_no = '';
            }
        }

        // year validation
        if (field == null || field == 'year') {
            if (!this.state.year || this.state.year == '') {
                error.year = i18n.t('this_field_is_required');
            } else {
                error.year = '';
            }
        }


        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_new_sr') : i18n.t('edit_new_sr')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_new_sr') : i18n.t('edit_new_sr')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Autocomplete
                                value={this.state.state ? this.state.state : null}
                                onChange={async (e, newValue) => {
                                    await this.setState({
                                        state: newValue
                                    })
                                    this.validateField('state');
                                }}
                                options={["State 1", "State 2", "State 3"]}
                                renderInput={(params) => <TextField {...params} label={i18n.t("state")} error={!!this.state?.error?.state} />}
                            // disabled={(this.state.mode == 'edit') ? true : false}
                            />
                            <FormHelperText error={!!this.state?.error?.state}>{this.state?.error?.state}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <Autocomplete
                                value={this.state.department ? this.state.department : null}
                                onChange={async (e, newValue) => {
                                    await this.setState({
                                        department: newValue
                                    })
                                    this.validateField('department');
                                }}
                                options={["department 1", "department 2", "department 3"]}
                                renderInput={(params) => <TextField {...params} label={i18n.t("department")} error={!!this.state?.error?.department} />}
                            // disabled={(this.state.mode == 'edit') ? true : false}
                            />
                            <FormHelperText error={!!this.state?.error?.department}>{this.state?.error?.department}</FormHelperText>
                        </Grid>


                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>

                        <Grid item xs={12} md={6} lg={6}>
                            <Autocomplete
                                value={this.state.sr_code ? this.state.sr_code : null}
                                onChange={async (e, newValue) => {
                                    await this.setState({
                                        sr_code: newValue
                                    })
                                    this.validateField('sr_code');
                                }}
                                options={["sr_code 1", "sr_code 2", "sr_code 3"]}
                                renderInput={(params) => <TextField {...params} label={i18n.t("sr_code")} error={!!this.state?.error?.sr_code} />}
                            // disabled={(this.state.mode == 'edit') ? true : false}
                            />
                            <FormHelperText error={!!this.state?.error?.sr_code}>{this.state?.error?.sr_code}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                name="unique_code"
                                label={i18n.t('unique_code')}
                                fullWidth
                                value={this.state.unique_code}
                                onChange={async (e) => {
                                    await this.setState({
                                        unique_code: e.target.value
                                    })
                                    this.validateField('unique_code');
                                }}
                                error={!!this.state?.error?.unique_code}
                                helperText={this.state?.error?.unique_code}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>

                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                name="category"
                                label={i18n.t('category')}
                                fullWidth
                                value={this.state.category}
                                onChange={async (e) => {
                                    await this.setState({
                                        category: e.target.value
                                    })
                                    this.validateField('category');
                                }}
                                error={!!this.state?.error?.category}
                                helperText={this.state?.error?.category}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                name="sub_category"
                                label={i18n.t('sub_category')}

                                value={this.state.sub_category}
                                onChange={async (e) => {
                                    await this.setState({
                                        sub_category: e.target.value
                                    })
                                    this.validateField('sub_category');
                                }}
                                error={!!this.state?.error?.sub_category}
                                helperText={this.state?.error?.sub_category}
                            >
                            </TextField>
                        </Grid>

                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>

                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                rows={3}
                                multiline
                                name="item_description"
                                label={i18n.t('item_description')}
                                value={this.state.item_description}
                                onChange={async (e) => {
                                    await this.setState({
                                        item_description: e.target.value
                                    })
                                    this.validateField('item_description');
                                }}
                                error={!!this.state?.error?.item_description}
                                helperText={this.state?.error?.item_description}>
                            </TextField>
                        </Grid>

                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>

                        <Grid item xs={12} md={6} lg={4}>
                            <Autocomplete
                                value={this.state.units ? this.state.units : null}
                                onChange={async (e, newValue) => {
                                    await this.setState({
                                        units: newValue
                                    })
                                    this.validateField('units');
                                }}
                                options={["units 1", "units 2", "units 3"]}
                                renderInput={(params) => <TextField {...params} label={i18n.t("units")} error={!!this.state?.error?.units} />}
                            // disabled={(this.state.mode == 'edit') ? true : false}
                            />
                            <FormHelperText error={!!this.state?.error?.units}>{this.state?.error?.units}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                fullWidth
                                name="page_no"
                                label={i18n.t('page_no')}
                                value={this.state.page_no}
                                onChange={async (e) => {
                                    await this.setState({
                                        page_no: e.target.value
                                    })
                                    this.validateField('page_no');
                                }}
                                error={!!this.state?.error?.page_no}
                                helperText={this.state?.error?.page_no}>
                            </TextField>

                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                fullWidth
                                name="year"
                                label={i18n.t('year')}
                                value={this.state.year}
                                onChange={async (e) => {
                                    await this.setState({
                                        year: e.target.value
                                    })
                                    this.validateField('year');
                                }}
                                error={!!this.state?.error?.year}
                                helperText={this.state?.error?.year}>
                            </TextField>

                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveScheduleRate();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <AddEditScheduleRate {...props} navigate={navigate} params={params} />;
}
