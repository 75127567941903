/**Read this
 * Bug:
 * Edit is not funcitioning 400 Status Code Type of work already exists
 */

import { Autocomplete, Button, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import { getTypeOfWork as getTypeOfWorkService, addTypeOfWork as addTypeOfWorkService, editTypeOfWork as editTypeOfWorkService } from 'src/services/typeOfWork.service';
import Swal from "sweetalert2";
import { getRolesForSelection } from "src/services/common.service";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";

class AddEditTypeOfWork extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: props.params?.type_of_work_id ? 'edit' : 'add',
            type_of_work_id: props.params?.type_of_work_id,
            available_events: [],
            type_of_work: '',
            events: [],
            error: {},
            loading: false
        }
    }

    async componentDidMount() {

        if (this.state.mode == 'edit') {
            await this.getTypeOfWork();
        }
    }

    async getTypeOfWork() {
        let response = await getTypeOfWorkService(this.state.type_of_work_id);
        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (response && response.type_of_work) {
            let type_of_work = response.type_of_work;

            await this.setState({
                type_of_work: type_of_work?.type_of_work ? type_of_work.type_of_work : '',
                events: type_of_work?.events ? type_of_work.events : '',
                available_events: type_of_work?.events ? type_of_work.events : ''
            })
        }
    }

    async saveTypeOfWork() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let payload = {
            type_of_work: this.state.type_of_work,
            events: this.state.events
        }

        if (this.state.mode == 'add') {
            let response = await addTypeOfWorkService(payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }
            if (response && response.type_of_work) {
                this.setState({
                    loading: false
                })
                this.props.navigate('/' + localStorage.getItem('role') +  '/mastertables/type_of_works');
            }
        } else {
            let response = await editTypeOfWorkService(this.state.type_of_work_id, payload);
            if (response && response.error) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.t('error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    confirmButtonText: i18n.t('ok'),
                    confirmButtonColor: config.primaryColor
                })
                this.setState({
                    loading: false
                })
                return;
            }

            if (response && response.type_of_work) {
                // Swal toast
                Swal.fire({
                    toast: true,
                    position: 'bottom-end',
                    icon: 'success',
                    title: i18n.t('success'),
                    text: i18n.t('type_of_work_updated_successfully'),
                    showConfirmButton: false,
                    timer: config.toastDelay,
                    timerProgressBar: true
                })
                this.setState({
                    loading: false
                })
                this.getTypeOfWork();
            }
        }

    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'type_of_work') {
            if (!this.state.type_of_work || this.state.type_of_work == '') {
                error.type_of_work = i18n.t('this_field_is_required');
            } else {
                error.type_of_work = '';
            }
        }

        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {this.state.mode == 'add' ? i18n.t('add_type_of_work') : i18n.t('edit_type_of_work')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {this.state.mode == 'add' ? i18n.t('add_type_of_work') : i18n.t('edit_type_of_work')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="type_of_work"
                                label={i18n.t('type_of_work')}
                                fullWidth
                                value={this.state.type_of_work}
                                onChange={async (e) => {
                                    await this.setState({
                                        type_of_work: e.target.value
                                    })
                                    this.validateField('type_of_work');
                                }}
                                error={!!this.state?.error?.type_of_work}
                                helperText={this.state?.error?.type_of_work}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    value={this.state.events ? this.state.events : []}
                                    onChange={async (e, newValue) => {
                                        await this.setState({
                                            events: newValue
                                        })
                                        this.validateField('events');
                                    }}
                                    options={this.state.available_events ? this.state.available_events : []}
                                    freeSolo
                                    renderInput={(params) => <TextField {...params} label={i18n.t('events')} error={!!this.state?.error?.events} />}
                                    getOptionLabel={(option) => {
                                        if (option)
                                            return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.events}>{this.state?.error?.events}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    await this.saveTypeOfWork();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
    const params = useParams();
  
    return <AddEditTypeOfWork {...props} navigate={navigate} params={params} />;
}
