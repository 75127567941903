import { Helmet } from 'react-helmet-async';
import React from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Box,
  Chip,
  InputAdornment,
  Pagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import i18n from 'src/i18n';
import config from 'src/config';
import { getWorks } from 'src/services/work.service';
import { StyledSearch } from 'src/components/searchbar/styles';
import Searchbar from 'src/components/searchbar/Searchbar';
import Swal from 'sweetalert2';
import Label from '../components/label';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { convertMessageCodeToMessage } from 'src/utils/messageCodeToMessage';
import moment from 'moment';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'financial_year', label: i18n.t('financial_year'), alignRight: false, sortable: true },
    { id: 'indent_no', label: i18n.t('indent_no'), alignRight: false, sortable: true },
    { id: 'work_order_date', label: i18n.t('work_order_date'), alignRight: false, sortable: true },
    { id: 'work_order_number', label: i18n.t('work_order_number'), alignRight: false, sortable: true },
    { id: 'agreements_date', label: i18n.t('agreements_date'), alignRight: false, sortable: true },
    { id: 'site_handing_over_date', label: i18n.t('site_handing_over_date'), alignRight: false, sortable: true },
    { id: 'period', label: i18n.t('period'), alignRight: false, sortable: true },
    { id: 'tender_award_amount', label: i18n.t('tender_award_amount'), alignRight: false, sortable: true },
    { id: 'approved_by', label: i18n.t('approved_by'), alignRight: false, sortable: true },
    // { id: 'approval_number', label: i18n.t('approval_number'), alignRight: false, sortable: true },
    { id: 'further_security_deposit', label: i18n.t('further_security_deposit'), alignRight: false, sortable: true },
    { id: 'contractor_id', label: i18n.t('contractor_id'), alignRight: false, sortable: true },
    { id: '' },
];

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

class Works extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      works: [],
      open: null,
      page: 0,
      selected: [],
      searchValue: '',
      limit: 10,
      skip: 0,
      total_works: 0,
      sortOrder: 'asc',
      sortKey: 'full_name'
    };
  }

  async componentDidMount() {
    await this.setWorks();
  }

  async setWorks() {

    let query = {
        limit: this.state.limit,
        skip: this.state.skip,
    };
    
    if (this.state.searchValue) {
        query.searchKey = 'indent_no,work_id,name_of_work';
        query.searchValue = this.state.searchValue;
    }

    if (this.state.sortKey) {
        query.sortKey = this.state.sortKey;
        query.sortOrder = this.state.sortOrder ? this.state.sortOrder : 'asc';
    }

    let works = await getWorks(query);
    if (works.error) {
        Swal.fire({
            icon: 'error',
            title: i18n.t('error'),
            text: works.error_code ? convertMessageCodeToMessage(works.error_code) : works.error,
            confirmButtonText: i18n.t('ok'),
            confirmButtonColor: config.primaryColor
        });
        return;
    }

    this.setState({
        works: works?.works ? works.works : [],
        total_works: works?.count ? works.count : 0
    });
  }

  handleOpenMenu = (event) => {
    this.setState({open: event.currentTarget});
  };

  handleCloseMenu = () => {
    this.setState({open: null});
  };

  handleRequestSort = async (event, property) => {
    const isAsc = this.state.sortKey === property && this.state.sortOrder === 'asc';
    await this.setState({skip: 0, sortOrder: isAsc ? 'desc' : 'asc', sortKey: property});
    await this.setWorks();
  };

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex), this.state.selected.slice(selectedIndex + 1));
    }
    // setSelected(newSelected);
    this.setState({selected: newSelected});
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  render() {

    return (
      <>
        <Helmet>
          <title> {i18n.t('works')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <Container maxWidth="100%">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {i18n.t('works')}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => {
                this.props.navigate('add');
              }}
            >
              {i18n.t('add_work')}
            </Button>
          </Stack>

          <Card>

            <Searchbar
                placeholder={i18n.t('search_works')}
                style={{margin: 20}}
                // sx={{ mb: 2 }}
                onChange={async (event) => {
                    await this.setState({
                        skip: 0,
                        searchValue: event.target.value
                    });
                    await this.setWorks();
                }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? 'right' : 'left'}
                          sortDirection={this.state.sortKey === headCell.id ? this.state.sortOrder : false}
                        >
                          {(headCell.sortable) ? (
                            <TableSortLabel
                              hideSortIcon={headCell.sortable ? false : true}
                              active={this.state.sortKey === headCell.id}
                              direction={this.state.sortKey === headCell.id ? this.state.sortOrder : 'asc'}
                              onClick={this.createSortHandler(headCell.id)}
                            >
                              {headCell.label}
                              {(this.state.sortKey === headCell.id && headCell.sortable) ? (
                                <Box sx={{ ...visuallyHidden }}>{this.state.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <Typography variant="subtitle2">{headCell.label}</Typography>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.works?.map((row) => {
                      let { id, financial_year, indent_no, work_order_date, work_order_number, agreements_date, site_handing_over_date, period, tender_award_amount, approved_by, further_security_deposit, contractor_id } = row;

                      if (work_order_date) work_order_date = moment(work_order_date).format(config.DATE_FORMAT.MEDIUM);
                      if (agreements_date) agreements_date = moment(agreements_date).format(config.DATE_FORMAT.MEDIUM);
                      if (site_handing_over_date) site_handing_over_date = moment(site_handing_over_date).format(config.DATE_FORMAT.MEDIUM);

                      return (
                        <TableRow hover key={id} tabIndex={-1}>

                          <TableCell align="left">{financial_year}</TableCell>
                          
                          <TableCell align="left">{indent_no}</TableCell>

                          <TableCell align="left">{work_order_date}</TableCell>

                          <TableCell align="left">{work_order_number}</TableCell>

                          <TableCell align="left">{agreements_date}</TableCell>

                          <TableCell align="left">{site_handing_over_date}</TableCell>

                          <TableCell align="left">{period}</TableCell>

                          <TableCell align="left">{tender_award_amount}</TableCell>

                          <TableCell align="left">{approved_by}</TableCell>

                          {/* <TableCell align="left">{approval_number}</TableCell> */}

                          <TableCell align="left">{further_security_deposit}</TableCell>

                          <TableCell align="left">{contractor_id}</TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit"
                              onClick={(e) => {
                                this.setState({
                                  currentProposal: row
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                </Table>
              </TableContainer>
            </Scrollbar>

            <Stack spacing={2} sx={{alignItems: 'center'}}>
              <Pagination
                  count={Math.ceil(this.state.total_works / this.state.limit)}
                  shape="rounded"
                  size="large"
                  onChange={async (event, page) => {
                      await this.setState({
                          skip: (page - 1) * this.state.limit,
                      });
                      await this.setWorks();
                  }}
                  showFirstButton={true}
                  showLastButton={true}
                  boundaryCount={2}
                  style={{margin: 20}}
              />
            </Stack>

          </Card>
        </Container>

        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate( '/' + localStorage.getItem('role') + '/works/' + this.state.currentProposal.id);
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {i18n.t('edit')}
          </MenuItem>

          {/* <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}
        </Popover>

      </>
    );
  }
}

// Wrap and export
export default function(props) {
  const navigate = useNavigate();

  return <Works {...props} navigate={navigate} />;
}
