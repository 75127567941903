import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Chip, Container, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getProposals as getProposalsService } from "src/services/proposal.service";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { upsertPhysicalProgress, getPhysicalProgress } from "src/services/physicalProgress.service";
import { getEventsOfWorkSchedule, getWorkScheduleFinancialYears, getWorkScheduleIndentNos } from "src/services/workSchedule.service";


class PhysicalProgress extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            financial_years: [],
            indent_nos: [],
            events: [],
            financial_year: '',
            indent_no: '',
            proposal: null,
            physical_progress_data: [],
            error: {},
            loading: false
        }
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        this.setFinancialYears();
    }

    async setFinancialYears() {
        let financial_years = await getWorkScheduleFinancialYears();
        if (financial_years && financial_years.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: financial_years.error_code ? convertMessageCodeToMessage(financial_years.error_code) : financial_years.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (financial_years && financial_years.financial_year) {
            await this.setState({
                financial_years: financial_years.financial_year
            })
        }
    }

    async setIndentNos() {
        let indent_nos = await getWorkScheduleIndentNos();
        if (indent_nos && indent_nos.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: indent_nos.error_code ? convertMessageCodeToMessage(indent_nos.error_code) : indent_nos.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (indent_nos && indent_nos.indent_no) {
            await this.setState({
                indent_nos: indent_nos.indent_no
            })
        }
    }

    async setProposal() {

        if (!this.state.financial_year || !this.state.indent_no) {
            this.promisedSetState({
                proposal: null
            })
            return;
        }

        let proposal = await getProposalsService({financial_year: this.state.financial_year, indent_no: this.state.indent_no});

        if (proposal && proposal.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: proposal.error_code ? convertMessageCodeToMessage(proposal.error_code) : proposal.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (proposal && proposal.proposals && proposal.proposals.length > 0) {
            await this.promisedSetState({
                proposal: proposal.proposals[0]
            })
        } else {
            await this.promisedSetState({
                proposal: null
            })
        }

    }

    async setEvents() {

        if (!this.state.financial_year || !this.state.indent_no) {
            this.setState({
                events: []
            })
            return;
        }

        let events = await getEventsOfWorkSchedule({financial_year: this.state.financial_year, indent_no: this.state.indent_no});

        if (events && events.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: events.error_code ? convertMessageCodeToMessage(events.error_code) : events.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (events && events.events) {
            await this.promisedSetState({
                events: events.events
            })
        }

    }

    async setPhysicalProgress() {
        if (!this.state.financial_year || !this.state.indent_no) {
            this.promisedSetState({
                physical_progress_data: []
            })
            return;
        }

        let physical_progress = await getPhysicalProgress({financial_year: this.state.financial_year, indent_no: this.state.indent_no});
        if (physical_progress && physical_progress.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: physical_progress.error_code ? convertMessageCodeToMessage(physical_progress.error_code) : physical_progress.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        let physical_progress_data = [];
        if (physical_progress && physical_progress.physical_progress) {
            physical_progress_data = physical_progress.physical_progress?.physical_progress_data ? physical_progress.physical_progress?.physical_progress_data : [];
        }

        await this.promisedSetState({
            physical_progress_data: physical_progress_data
        })
    }




    async savePhysicalProgress() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }


        let payload = {};


        payload.financial_year = this.state.financial_year;
        payload.indent_no = this.state.indent_no;

        payload.physical_progress_data = this.state.physical_progress_data;

        let response = await upsertPhysicalProgress(payload);

        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            this.setState({
                loading: false
            })
            return;
        }

        if (response && response.physical_progress) {
            Swal.fire({
                icon: 'success',
                title: i18n.t('success'),
                text: i18n.t('physical_progress') + ' ' + i18n.t('saved_successfully'),
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            this.setState({
                loading: false
            })
        }
    }

    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'financial_year') {
            if (!this.state.financial_year || this.state.financial_year == '') {
                error.financial_year = i18n.t('this_field_is_required');
            } else {
                error.financial_year = '';
            }
        }

        if (field == null || field == 'indent_no') {
            if (!this.state.indent_no || this.state.indent_no == '') {
                error.indent_no = i18n.t('this_field_is_required');
            } else {
                error.indent_no = '';
            }
        }
        
        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {i18n.t('physical_progress')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {i18n.t('physical_progress')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.financial_year ? this.state.financial_year : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            financial_year: newValue,
                                            indent_no: ''
                                        })
                                        this.validateField('financial_year');
                                        await this.setIndentNos();
                                        await this.setProposal();
                                        await this.setEvents();
                                    }}
                                    options={this.state.financial_years}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('financial_year')} error={!!this.state?.error?.financial_year} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.financial_year}>{this.state?.error?.financial_year}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.indent_no ? this.state.indent_no : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            indent_no: newValue
                                        })
                                        this.validateField('indent_no');
                                        await this.setProposal();
                                        await this.setEvents();
                                        await this.setPhysicalProgress();
                                    }}
                                    options={this.state.indent_nos}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('indent_no')} error={!!this.state?.error?.indent_no} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={((this.state.financial_year && this.state.financial_year != '') ? false : true)}
                                />
                                <FormHelperText error={!!this.state?.error?.indent_no}>{this.state?.error?.indent_no}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {(this.state.proposal) ? (
                            <Grid item xs={12} md={12} lg={12} sx={{mt: 2, mb: 2}}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h5">{this.state.proposal?.name_of_work}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('proposal_id')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.proposal_id}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('name_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.name_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('type_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.type_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('state')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.state}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('zone')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.zone}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('circle')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.circle}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('division')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.division}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('district')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.district}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('taluk')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.taluk}</TableCell>
                                                    </TableRow>
                                                    {(this.state.proposal?.type_of_work == 'Road Construction' || this.state.proposal?.type_of_work == 'Road Maintenance') ? (
                                                        <>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_type')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_type}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_number')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_number}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_name')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_length')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_length}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('chainage')}</TableCell>
                                                                <TableCell align="right">
                                                                    {(this.state.proposal?.chainage?.length) ? (
                                                                        this.state.proposal?.chainage.map((chainage, index) => {
                                                                            return (
                                                                                <Chip key={index} label={chainage?.chainage_from + ' - ' + chainage?.chainage_to} />
                                                                            )
                                                                        })
                                                                    ) : null}
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    ) : null}
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('head_of_account')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.head_of_account}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('admin_approval')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.admin_approval}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('technical_sanction')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.technical_sanction}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('estimated_cost')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.estimated_cost}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ) : null}

                        
                        {(this.state.financial_year && this.state.indent_no) ? (

                            <>

                                <Grid item xs={12} md={12} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="right">{i18n.t('month')}</TableCell>
                                                    <TableCell align="right">{i18n.t('year')}</TableCell>
                                                    {(this.state?.events?.map((eve, index) => {
                                                        return (
                                                            <TableCell key={index} align="right">{eve}</TableCell>
                                                        )
                                                    }))}
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.physical_progress_data.map((phy_progress, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="right">
                                                            <Typography variant="body1">{phy_progress.month}</Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="body1">{phy_progress.year}</Typography>
                                                        </TableCell>
                                                        {(this.state?.events?.map((eve, eve_index) => {
                                                            return (
                                                                <TableCell key={index} align="right">
                                                                    <TextField
                                                                        fullWidth
                                                                        value={phy_progress[eve] ? phy_progress[eve] : ''}
                                                                        onChange={async (e) => {
                                                                            let physical_progress_data = this.state.physical_progress_data;
                                                                            physical_progress_data[index][eve] = e.target.value;
                                                                            await this.promisedSetState({
                                                                                physical_progress_data: physical_progress_data
                                                                            })
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            )
                                                        }))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                {this.state.error.physical_progress_data ? (
                                    <Grid container spacing={1} sx={{mt: 3}}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography variant="h6" color="error">{this.state.error.physical_progress_data}</Typography>
                                        </Grid>
                                    </Grid>
                                ) : null}

                                <Grid item xs={12} md={12} lg={12} sx={{mt: 2}}>
                                    <Button
                                        variant="contained"
                                        onClick={async () => {
                                            let physical_progress_data = this.state.physical_progress_data;
                                            let push_obj = {
                                                month: moment().format('MMMM'),
                                                year: moment().format('YYYY')
                                            }
                                            for(let i=0; i < this.state.events.length; i++) {
                                                push_obj[this.state.events[i]] = '';
                                            }

                                            // If with same month and year already exists, dont push else push
                                            let exists = false;
                                            for(let i=0; i < physical_progress_data.length; i++) {
                                                if (physical_progress_data[i].month == push_obj.month && physical_progress_data[i].year == push_obj.year) {
                                                    exists = true;
                                                    break;
                                                }
                                            }

                                            if (!exists) {
                                                physical_progress_data.push(push_obj)
                                                await this.promisedSetState({
                                                    physical_progress_data: physical_progress_data
                                                })
                                            } else {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: i18n.t('error'),
                                                    text: i18n.t('data_already_exists'),
                                                    confirmButtonText: i18n.t('ok'),
                                                    confirmButtonColor: config.primaryColor
                                                })
                                            }
                                        }}
                                    >
                                        {i18n.t('add_data')}
                                    </Button>
                                </Grid>

                                <Grid container spacing={1} sx={{mt: 3}}>
                                    <Grid item xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                                        <Button
                                            variant="contained"
                                            onClick={async () => {
                                                await this.savePhysicalProgress();
                                            }}
                                            disabled={this.state.loading}
                                        >
                                            {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                                        </Button>
                                    </Grid>
                                </Grid>

                            </>

                        ) : null}
                        
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
    const params = useParams();
  
    return <PhysicalProgress {...props} navigate={navigate} params={params} />;
}
