import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Chip, Container, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "src/config";
import i18n from "src/i18n";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "src/utils/messageCodeToMessage";
import { Helmet } from "react-helmet-async";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getProposals as getProposalsService } from "src/services/proposal.service";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getQualityControl, addQualityControl as addQualityControlService, deleteQualityControl } from "src/services/qualityControl.service";
import { getWorkScheduleFinancialYears, getWorkScheduleIndentNos } from "src/services/workSchedule.service";
import { getDropdownValues } from "src/services/dropdown.service";


class QualityControl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            financial_years: [],
            indent_nos: [],
            inspecting_agencies: [],
            financial_year: '',
            indent_no: '',
            proposal: null,
            quality_control: [],
            error: {},
            loading: false
        }
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        this.setFinancialYears();
        this.setInspectingAgencies();
    }

    async setFinancialYears() {
        let financial_years = await getWorkScheduleFinancialYears();
        if (financial_years && financial_years.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: financial_years.error_code ? convertMessageCodeToMessage(financial_years.error_code) : financial_years.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (financial_years && financial_years.financial_year) {
            await this.setState({
                financial_years: financial_years.financial_year
            })
        }
    }

    async setInspectingAgencies() {
        let inspecting_agencies = await getDropdownValues('inspecting_agency');
        if (inspecting_agencies && inspecting_agencies.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: inspecting_agencies.error_code ? convertMessageCodeToMessage(inspecting_agencies.error_code) : inspecting_agencies.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (inspecting_agencies && inspecting_agencies.inspecting_agency) {
            await this.setState({
                inspecting_agencies: inspecting_agencies.inspecting_agency
            })
        }
    }


    async setIndentNos() {
        let indent_nos = await getWorkScheduleIndentNos();
        if (indent_nos && indent_nos.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: indent_nos.error_code ? convertMessageCodeToMessage(indent_nos.error_code) : indent_nos.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }
        if (indent_nos && indent_nos.indent_no) {
            await this.setState({
                indent_nos: indent_nos.indent_no
            })
        }
    }

    async setProposal() {

        if (!this.state.financial_year || !this.state.indent_no) {
            this.promisedSetState({
                proposal: null
            })
            return;
        }

        let proposal = await getProposalsService({financial_year: this.state.financial_year, indent_no: this.state.indent_no});

        if (proposal && proposal.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: proposal.error_code ? convertMessageCodeToMessage(proposal.error_code) : proposal.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (proposal && proposal.proposals && proposal.proposals.length > 0) {
            await this.promisedSetState({
                proposal: proposal.proposals[0]
            })
        } else {
            await this.promisedSetState({
                proposal: null
            })
        }

    }

    async setQualityControl() {
        if (!this.state.financial_year || !this.state.indent_no) {
            this.promisedSetState({
                quality_control: []
            })
            return;
        }

        let quality_control = await getQualityControl({financial_year: this.state.financial_year, indent_no: this.state.indent_no});
        if (quality_control && quality_control.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: quality_control.error_code ? convertMessageCodeToMessage(quality_control.error_code) : quality_control.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            return;
        }

        if (quality_control && quality_control.quality_control) {
            await this.promisedSetState({
                quality_control: quality_control.quality_control
            })
        } else {
            await this.promisedSetState({
                quality_control: []
            })
        }
    }


    async addQualityControl() {

        this.setState({
            loading: true
        })

        let valid = await this.isFormValid();
        if (!valid) {
            this.setState({
                loading: false
            })
            return;
        }

        let quality_control = this.state.quality_control;
        // filter only new ones
        quality_control = quality_control.find((qt_control) => {
            return qt_control.new;
        })

        // check if any new data
        if (!quality_control) {
            return;
        }

        let formData = new FormData();
        formData.append('financial_year', this.state.financial_year);
        formData.append('indent_no', this.state.indent_no);
        formData.append('date', quality_control.date);
        formData.append('inspecting_agency', quality_control.inspecting_agency);
        formData.append('inspected_by', quality_control.inspected_by);
        formData.append('qc_report', quality_control.qc_report);
        formData.append('qc_image_1', quality_control.qc_image_1);
        formData.append('qc_image_2', quality_control.qc_image_2);


        let response = await addQualityControlService(formData);

        if (response && response.error) {
            Swal.fire({
                icon: 'error',
                title: i18n.t('error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            this.setState({
                loading: false
            })
            return;
        }

        if (response && response.quality_control) {
            Swal.fire({
                icon: 'success',
                title: i18n.t('success'),
                text: i18n.t('quality_control') + ' ' + i18n.t('saved_successfully'),
                confirmButtonText: i18n.t('ok'),
                confirmButtonColor: config.primaryColor
            })
            this.setState({
                loading: false
            })

            await this.setQualityControl();
        }
    }


    async isFormValid() {
        let error = await this.validateField();
        let is_valid = true;
        let keys = Object.keys(error);
        for (let i = 0; i < keys.length; i++) {
            if (error[keys[i]] != '') {
                is_valid = false;
                break;
            }
        }
        return is_valid;
    }

    async validateField(field=null) {

        let error = this.state.error;

        if (field == null || field == 'financial_year') {
            if (!this.state.financial_year || this.state.financial_year == '') {
                error.financial_year = i18n.t('this_field_is_required');
            } else {
                error.financial_year = '';
            }
        }

        if (field == null || field == 'indent_no') {
            if (!this.state.indent_no || this.state.indent_no == '') {
                error.indent_no = i18n.t('this_field_is_required');
            } else {
                error.indent_no = '';
            }
        }
        
        this.setState({
            error: error
        })

        return error;
    }

    render() {
        return (
            <>

                <Helmet>
                    <title> {i18n.t('quality_control')} | {config.APPLICATION_NAME} </title>
                </Helmet>

                <Container maxWidth="100%">

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {i18n.t('quality_control')}
                        </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.financial_year ? this.state.financial_year : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            financial_year: newValue,
                                            indent_no: ''
                                        })
                                        this.validateField('financial_year');
                                        await this.setIndentNos();
                                        await this.setProposal();
                                    }}
                                    options={this.state.financial_years}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('financial_year')} error={!!this.state?.error?.financial_year} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                />
                                <FormHelperText error={!!this.state?.error?.financial_year}>{this.state?.error?.financial_year}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={this.state.indent_no ? this.state.indent_no : null}
                                    onChange={async (e, newValue) => {
                                        await this.promisedSetState({
                                            indent_no: newValue
                                        })
                                        this.validateField('indent_no');
                                        await this.setProposal();
                                        await this.setQualityControl();
                                    }}
                                    options={this.state.indent_nos}
                                    renderInput={(params) => <TextField {...params} label={i18n.t('indent_no')} error={!!this.state?.error?.indent_no} />}
                                    getOptionLabel={(option) => {
                                        return option;
                                    }}
                                    disabled={((this.state.financial_year && this.state.financial_year != '') ? false : true)}
                                />
                                <FormHelperText error={!!this.state?.error?.indent_no}>{this.state?.error?.indent_no}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {(this.state.proposal) ? (
                            <Grid item xs={12} md={12} lg={12} sx={{mt: 2, mb: 2}}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h5">{this.state.proposal?.name_of_work}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('proposal_id')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.proposal_id}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('name_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.name_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('type_of_work')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.type_of_work}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('state')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.state}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('zone')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.zone}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('circle')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.circle}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('division')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.division}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('district')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.district}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('taluk')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.taluk}</TableCell>
                                                    </TableRow>
                                                    {(this.state.proposal?.type_of_work == 'Road Construction' || this.state.proposal?.type_of_work == 'Road Maintenance') ? (
                                                        <>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_type')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_type}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_number')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_number}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_name')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('road_length')}</TableCell>
                                                                <TableCell align="right">{this.state.proposal?.road_length}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">{i18n.t('chainage')}</TableCell>
                                                                <TableCell align="right">
                                                                    {(this.state.proposal?.chainage?.length) ? (
                                                                        this.state.proposal?.chainage.map((chainage, index) => {
                                                                            return (
                                                                                <Chip key={index} label={chainage?.chainage_from + ' - ' + chainage?.chainage_to} />
                                                                            )
                                                                        })
                                                                    ) : null}
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    ) : null}
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('head_of_account')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.head_of_account}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('admin_approval')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.admin_approval}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('technical_sanction')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.technical_sanction}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">{i18n.t('estimated_cost')}</TableCell>
                                                        <TableCell align="right">{this.state.proposal?.estimated_cost}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ) : null}

                        
                        {(this.state.financial_year && this.state.indent_no) ? (

                            <>

                                <Grid item xs={12} md={12} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">{i18n.t('date')}</TableCell>
                                                    <TableCell align="center">{i18n.t('inspecting_agency')}</TableCell>
                                                    <TableCell align="center">{i18n.t('inspected_by')}</TableCell>
                                                    <TableCell align="center">{i18n.t('qc_report')}</TableCell>
                                                    <TableCell align="center">{i18n.t('qc_image_1')}</TableCell>
                                                    <TableCell align="center">{i18n.t('qc_image_2')}</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.quality_control.map((qt_control, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>
                                                            {(qt_control.new) ? (
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DatePicker
                                                                        label={i18n.t('date')}
                                                                        value={qt_control.date ? moment(qt_control.date) : null}
                                                                        onChange={async (newValue) => {
                                                                            let quality_control = this.state.quality_control;
                                                                            quality_control[index].date = newValue;
                                                                            await this.promisedSetState({
                                                                                quality_control: quality_control
                                                                            })
                                                                        }}
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            ) : (
                                                                <Typography variant="body1">{moment(qt_control.date).format(config.DATE_FORMAT.MEDIUM)}</Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {(qt_control.new) ? (
                                                                <FormControl fullWidth>
                                                                    <Autocomplete
                                                                        value={qt_control.inspecting_agency ? qt_control.inspecting_agency : null}
                                                                        onChange={async (e, newValue) => {
                                                                            let quality_control = this.state.quality_control;
                                                                            quality_control[index].inspecting_agency = newValue;
                                                                            await this.promisedSetState({
                                                                                quality_control: quality_control
                                                                            })
                                                                        }}
                                                                        options={this.state.inspecting_agencies}
                                                                        renderInput={(params) => <TextField {...params} label={i18n.t('inspecting_agency')} />}
                                                                        getOptionLabel={(option) => {
                                                                            return option;
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            ) : (
                                                                <Typography variant="body1">{qt_control.inspecting_agency}</Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {(qt_control.new) ? (
                                                                <TextField
                                                                    fullWidth
                                                                    label={i18n.t('inspected_by')}
                                                                    value={qt_control.inspected_by ? qt_control.inspected_by : ''}
                                                                    onChange={async (e) => {
                                                                        let quality_control = this.state.quality_control;
                                                                        quality_control[index].inspected_by = e.target.value;
                                                                        await this.promisedSetState({
                                                                            quality_control: quality_control
                                                                        })
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Typography variant="body1">{qt_control.inspected_by}</Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {(qt_control.new) ? (
                                                                <Input
                                                                    fullWidth
                                                                    type="file"
                                                                    onChange={async (e) => {
                                                                        let quality_control = this.state.quality_control;
                                                                        quality_control[index].qc_report = e.target.files[0];
                                                                        await this.promisedSetState({
                                                                            quality_control: quality_control
                                                                        })
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Typography variant="body1">{qt_control.qc_report}</Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {(qt_control.new) ? (
                                                                <Input
                                                                    fullWidth
                                                                    type="file"
                                                                    onChange={async (e) => {
                                                                        let quality_control = this.state.quality_control;
                                                                        quality_control[index].qc_image_1 = e.target.files[0];
                                                                        await this.promisedSetState({
                                                                            quality_control: quality_control
                                                                        })
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Typography variant="body1">{qt_control.qc_image_1}</Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {(qt_control.new) ? (
                                                                <Input
                                                                    fullWidth
                                                                    type="file"
                                                                    onChange={async (e) => {
                                                                        let quality_control = this.state.quality_control;
                                                                        quality_control[index].qc_image_2 = e.target.files[0];
                                                                        await this.promisedSetState({
                                                                            quality_control: quality_control
                                                                        })
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Typography variant="body1">{qt_control.qc_image_2}</Typography>
                                                            )}
                                                        </TableCell>
                                                        {/* Delete row */}
                                                        <TableCell align="center">
                                                            <Button
                                                                variant="contained"
                                                                onClick={async () => {
                                                                    if (qt_control.new) {
                                                                        let quality_control = this.state.quality_control;
                                                                        quality_control.splice(index, 1);
                                                                        await this.promisedSetState({
                                                                            quality_control: quality_control
                                                                        })
                                                                    } else {
                                                                        this.setState({loading: true})
                                                                        let response = await deleteQualityControl(qt_control.id);
                                                                        this.setState({loading: false})
                                                                        if (response && response.error) {
                                                                            Swal.fire({
                                                                                icon: 'error',
                                                                                title: i18n.t('error'),
                                                                                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                                                                                confirmButtonText: i18n.t('ok'),
                                                                                confirmButtonColor: config.primaryColor
                                                                            })
                                                                            return;
                                                                        }

                                                                        this.setQualityControl();
                                                                    }
                                                                }}
                                                            >
                                                                <DeleteForeverIcon />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={12} md={12} lg={12} sx={{mt: 2}}>
                                    <Button
                                        variant="contained"
                                        onClick={async () => {
                                            let quality_control = this.state.quality_control;

                                            // check if there is anything new, if yes, dont add new row
                                            for (let i = 0; i < quality_control.length; i++) {
                                                if (quality_control[i].new) {
                                                    Swal.fire({
                                                        icon: 'error',
                                                        title: i18n.t('error'),
                                                        text: i18n.t('please_save_the_new_row_before_adding_another'),
                                                        confirmButtonText: i18n.t('ok'),
                                                        confirmButtonColor: config.primaryColor
                                                    })
                                                    return;
                                                }
                                            }

                                            let push_obj = {
                                                financial_year: this.state.financial_year,
                                                indent_no: this.state.indent_no,
                                                date: '',
                                                inspecting_agency: '',
                                                inspected_by: '',
                                                qc_report: null,
                                                qc_image_1: null,
                                                qc_image_2: null,
                                                new: true
                                            };
                                            
                                            if(!quality_control) {
                                                quality_control = []
                                            }
                                            quality_control.push(push_obj)
                                            await this.promisedSetState({
                                                quality_control: quality_control
                                            })
                                        }}
                                    >
                                        {i18n.t('add_data')}
                                    </Button>
                                </Grid>

                                <Grid container spacing={1} sx={{mt: 3}}>
                                    <Grid item xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                                        <Button
                                            variant="contained"
                                            onClick={async () => {
                                                await this.addQualityControl();
                                            }}
                                            disabled={this.state.loading}
                                        >
                                            {this.state.loading ? i18n.t('saving') : i18n.t('save')}
                                        </Button>
                                    </Grid>
                                </Grid>

                            </>

                        ) : null}
                        
                    </Grid>

                </Container>
            </>
        );
    }

}

// Wrap and export
export default function(props) {
    const navigate = useNavigate();
    const params = useParams();
  
    return <QualityControl {...props} navigate={navigate} params={params} />;
}
