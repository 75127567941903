import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { Box, InputAdornment } from '@mui/material';
//
import { StyledSearch } from './styles';
import Iconify from '../iconify/Iconify';

// ----------------------------------------------------------------------

Searchbar.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    sx: PropTypes.object
};

function Searchbar({ placeholder, value, onChange, style, sx }) {
  return (
    <StyledSearch
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        startAdornment={
            <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
        }
        style={style ? style : {}}
        sx={sx ? sx : {}}
    />
  );
}

export default Searchbar
